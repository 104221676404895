import React, { useEffect, useState } from 'react'
import { useCount, useUser } from '../../../utils/utils';
import Pagination from '../../pagination/Pagination';
import loading_gif from '../../../assets/gif/loading_gif.gif'
import customer_service from "../../../assets/png/customer_service.png"
import Swal from 'sweetalert2';
import { callApi } from '../../api/Api';
import { Link } from 'react-router-dom';
import dateUtils from '../../../utils/dateUtils';

const EventDataTable = () => {
    const [eventListData, setEventListData] = useState([])
    const [status, setStatus] = useState("STS_LIVE");
    const { user } = useUser();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { pendingCount, setPendingCount } = useCount()

    const columnData = [
        "S.No",
        "Name",
        "Type",
        "Services",
        "Created Date",
    ];

    useEffect(() => {
        eventList();
    }, [currentPage, status]);

    async function eventList() {
        const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token,
        };
        const payload = {
            current_page: currentPage,
            area_id: user?.area_id,
            filters: {
                conditions: [
                    {
                        field: "event_status",
                        operator: "like",
                        value: status,
                        dataType: "string",
                    },
                ],
            },
        };
        const pending_payload = {
            area_id: user.area_id,
            current_page: currentPage,
            filters: {
                conditions: [
                    {
                        field: "event_status",
                        operator: "like",
                        value: "STS_PENDING",
                        dataType: "string",
                    },
                ],
            },
        };
        setIsLoading(true);
        try {
            const eventList = await callApi(
                `${baseUrl}/modules/events/get-events`,
                "POST",
                headers,
                payload
            );
            const pendingEventList = await callApi(
                `${baseUrl}/modules/events/get-events`,
                "POST",
                headers,
                pending_payload
            );

            setEventListData(eventList.records);
            setPendingCount({ ...pendingCount, Events: pendingEventList?.total_rows })
            const pageTotal = eventList.total_pages;
            setTotalPage(Array.from({ length: pageTotal }, (_, i) => i + 1));
        } catch (error) {
            console.error("There was an error fetching the data!", error);
        } finally {
            setIsLoading(false);
        }
    }

    // SweetAlert Toast For handleServiceDelete
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        },
    });

    //soft delete the particular event
    const handleOfferDelete = async (event_id) => {
        const confirmation = await Swal.fire({
            title: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });

        if (confirmation.isConfirmed) {
            const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

            console.log("your event ID", event_id);
            const headers = {
                "Content-Type": "application/json",
                token: user?.token,
            };
            try {
                const OfferDeleteResponse = await callApi(
                    `${baseUrl}/modules/events/delete/${event_id}`,
                    "POST",
                    headers
                );
                if (OfferDeleteResponse.success) {
                    console.log("success", OfferDeleteResponse);
                    Toast.fire({
                        title: "Deleted",
                        icon: "success",
                    });
                    eventList()
                } else {
                    console.log("failed", OfferDeleteResponse);
                    Toast.fire({
                        title: "Failed to delete event",
                        icon: "error",
                    });
                }
            } catch (error) {
                console.error("Error:", error);
                Toast.fire({
                    title: "An error occurred while deleting the event",
                    icon: "error",
                });
            }
        }
    };
    return (
        <>
            <div className="row d-flex justify-content-between mb-3">
                <div className="col-md-6 col-6">
                    <h3>Events</h3>
                </div>
            </div>
            <div class="page-heading">
                <div class="page-title">
                    <section className="section">
                        <div className="card">
                            <div className="card-body">
                                <div className="dataTable-top mb-3">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a
                                                className="nav-link active"
                                                id="home-tab"
                                                data-bs-toggle="tab"
                                                href="#home"
                                                role="tab"
                                                aria-controls="home"
                                                aria-selected="true"
                                                onClick={() => setStatus("STS_LIVE")}
                                            >
                                                Live
                                            </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a
                                                className="nav-link"
                                                id="profile-tab"
                                                data-bs-toggle="tab"
                                                href="#profile"
                                                role="tab"
                                                aria-controls="profile"
                                                aria-selected="false"
                                                onClick={() => setStatus("STS_PENDING")}
                                            >
                                                Pending {`${pendingCount?.Events > 0 ? '(' + pendingCount?.Events + ')' : ''}`}
                                            </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a
                                                className="nav-link"
                                                id="contact-tab"
                                                data-bs-toggle="tab"
                                                href="#contact"
                                                role="tab"
                                                aria-controls="contact"
                                                aria-selected="false"
                                                onClick={() => setStatus("STS_REJECTED")}
                                            >
                                                Rejected
                                            </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a
                                                className="nav-link"
                                                id="contact-tab"
                                                data-bs-toggle="tab"
                                                href="#contact"
                                                role="tab"
                                                aria-controls="contact"
                                                aria-selected="false"
                                                onClick={() => setStatus("STS_EXPIRED")}
                                            >
                                                Expired
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="dataTable-container">
                                    <table className="table table-striped" id="table1">
                                        <thead>
                                            <tr>
                                                {columnData.map((col, i) => (
                                                    <th className="ta_head" key={i}>
                                                        {col}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {isLoading && (
                                                <img
                                                    src={loading_gif}
                                                    className="loading_gif"
                                                    alt="Loading..."
                                                />
                                            )}
                                            {eventListData && eventListData.length > 0 ? (
                                                eventListData?.map((event, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{event.event_name}</td>
                                                        <td>{event.entity_type}</td>
                                                        <td>
                                                            <div>
                                                                <img
                                                                    src={event.featured_image_path ? event.featured_image_path : customer_service}
                                                                    style={{ width: "50px", height: "50px", objectFit: "cover" }}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {
                                                                console.log(event?.created_date)
                                                            }
                                                            {
                                                                event?.created_date
                                                            }
                                                        </td>
                                                        <td>
                                                            <div className="col-sm-12 d-flex justify-content-end">
                                                                <Link
                                                                    to={{
                                                                        pathname: `/event-edit/${event?.event_id}`,
                                                                        state: event,
                                                                    }}
                                                                >
                                                                    <button className="btn btn-primary me-1 mb-1">
                                                                        <i className="bi bi-pencil-square"></i>
                                                                    </button>
                                                                </Link>
                                                                <button
                                                                    className="btn btn-danger me-1 mb-1"
                                                                    onClick={() =>
                                                                        handleOfferDelete(event.event_id)
                                                                    }
                                                                    title="Delete"
                                                                >
                                                                    <i className="bi bi-trash3"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={columnData.length}>No data found.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="dataTable-bottom d-flex justify-content-end">
                                    {Array.isArray(eventListData) &&
                                        eventListData.length > 0 ? (
                                        <Pagination
                                            totalPage={totalPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default EventDataTable
