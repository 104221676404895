import "./errorMessages.css"

export const errorMessages = {
    "name_reqd": "Title is required",
    "user_not_found": "User not found",
    "first_name_reqd": "First Name is required",
    "last_name_reqd": "Last Name is required",
    "email_reqd": "Email is required",
    "email_invalid": "Please enter the valid email",
    "email_already_exists": "Email already exists",
    "otp_code_reqd": 'Please enter the Otp',
    "email_otp_code_reqd": 'Please enter the Otp',
    "email_otp_code_reqd": "Please enter the Otp",
    "otp_invalid": "Please enter the Valid Otp",
    entity_type_reqd: "Entity type is required",
    latitude_reqd: "Latitude is required",
    longitude_reqd: "Longitude is required",
    line_1_reqd: "Address is required",
    area_reqd: "Area is required",
    "otp_code_reqd": "OTP is required",
    "Invalid_otp_code": "Please enter the Valid Otp",
    city_reqd: "City is required",
    state_reqd: "State is required",
    zip_reqd: "Pincode is required",
    country_reqd: "Country is required",
    posted_by_reqd: "Posted by is requied",
    land_area_reqd: "Land Area is required",
    built_up_area_reqd: "Built Up Area is required",
    direction_facing_reqd: "Direction is required",
    price_reqd: "Price is required",
    number_of_bedrooms_reqd: "Number of bedroom is required",
    phone_reqd: "Phone Number is required",
    "name_of_community_reqd": "Name of the Community is required",
    "carpet_area_reqd": "Carpet Area is required",
    property_type_doesnot_exists: "Property does not exists",
    category_id_doesnot_exists: "Category does not exists",
    area_id_doesnot_exists: 'Area does not exists',
    address_id_doesnot_exists: 'Address does not exists',
    posted_by_doesnot_exists: "Posted does not exists",
    featuredImage_doesnot_exists: "Featured Images does not exists",
    approving_agency_doesnot_exists: "Approving Agency does not exists",
    furnish_level_reqd: "Furnish Level is required",
    maintanance_charges_reqd: "Maintanance Charges is required",
    approving_agency_reqd: "Approving Agency is required",
    total_floors_reqd: "Total Floors is required",
    age_reqd: "Property Age is required",
    pet_allowed_reqd: "Number of Pets is required",
    number_of_bathrooms_reqd: "Number of Bathrooms is required",
    floor_number_reqd: "Number of Floor is required",
    car_parking_reqd: "Car Parking is required",
    suitable_for_business_reqd: "Suitable For Business is required",
    rent_reqd: 'Rent is required',
    available_from_reqd: "Available is required",
    gender_reqd: "Gender is required",
    rent_per_person_reqd: "Rent per person is required",
    food_available_reqd: "Food Available is required",
    person_per_room_reqd: "Person per room is required",
    geo_location_reqd: "Location is mandatory",
    role_code_reqd: "Role code is mandatory",
    event_name_reqd: "Event Name is required",
    venue_reqd: "Venue is required",
    event_organizer_contact_reqd: "Event Organizer Contact is required",
    event_organizer_name_reqd: "Event Organizer Name is required ",
    event_start_date_reqd: "Event Start date is required",
    event_end_date_reqd: "Event end date is required",
    event_registration_end_date_reqd: "Event deadline date is required",
    event_end_time_reqd: "Event end time is required",
    event_start_time_reqd: "Event start time is required",
    event_ticket_price_reqd: "Event Ticket price is required",
    "offer_name_reqd": "Offer name is required",
    "product_name_reqd": "Product Name is required",
    "mrp_reqd": "Actual Price is required",
    "offer_price_reqd": "Offer price is required",
    "hypso_price_reqd": "Hypso price is required",
    "offer_count_reqd": "Offer Count is required",
    "offer_start_date_reqd": "Offer start date is required",
    "offer_end_date_reqd": "Offer end date is required",
    "tag_id_already_exists": "Tag is already exists",
    role_code_does_not_exists: "Email ID does not match the role",
    "client is not authorized to login":"The client account does not have the authorization to log in."


}
