import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../../utils/utils';
import { callApi } from '../../api/Api';
import Swal from 'sweetalert2';

const EditUserReview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const location = useLocation();
  const user_id = new URLSearchParams(location.search).get('user_id');
  const [formValues, setFormValues] = useState([])
  const [editReview, setEditReview] = useState([])
  const [status, setStatus] = useState("");
  const [rejectReason, setRejectReason] = useState({});
  const [errors, setErrors] = useState([]);



  //Toast for EditService
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleRejectChange = (e) => {
    setRejectReason({ ...rejectReason, [e.target.name]: e.target.value });
  };

  // handleStatus approve

  const handleStatusApprove = async () => {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const headers = {
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      review_id: id,
      status: "STS_LIVE",
      action: "APPROVE",
    };

    try {
      const approveStatusResponse = await callApi(
        `${baseUrl}/modules/reviews/update`,
        "POST",
        headers,
        payload
      );

      if (approveStatusResponse.success) {
        console.log("Status update success", approveStatusResponse);
        Toast.fire({
          title: "Approved",
          icon: "success",
        });
        navigate(`/user-review/${user_id}`);
      } else {
        setErrors(approveStatusResponse.errors);
        console.log("Status update failed", approveStatusResponse);
        Toast.fire({
          title: "Failed to Approve",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.fire({
        title: "An error occurred while approving the service",
        icon: "error",
      });
    }
  };

  const handleStatusReject = async () => {
    console.log("inisde api status", status);
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const headers = {
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      review_id: id,
      reject_reason: rejectReason.reject_reason,
      status: "STS_REJECTED",
      action: "REJECT"
    };
    console.log("payload status", payload);

    try {
      const rejectStatusResponse = await callApi(
        `${baseUrl}/modules/reviews/update`,
        "POST",
        headers,
        payload
      );

      if (rejectStatusResponse.success) {
        console.log("Status update success", rejectStatusResponse);
        Toast.fire({
          title: "Rejected",
          icon: "success",
        });
        navigate(`/user-review/${user_id}`);
      } else {
        console.log("Status update failed", rejectStatusResponse);
        Toast.fire({
          title: "Failed to Reject",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.fire({
        title: "An error occurred while rejecting the service",
        icon: "error",
      });
    }
  };

  // handleStatus back to pending page

  const handleBackToPending = async () => {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const headers = {
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      review_id: id,
      status: "STS_PENDING",
      action: "APPROVE",
    };
    console.log("your payload", payload);
    try {
      const backToPendingResponse = await callApi(
        `${baseUrl}/modules/reviews/update`,
        "POST",
        headers,
        payload
      );

      if (backToPendingResponse.success) {
        console.log("backToPendingResponse", backToPendingResponse);
        Toast.fire({
          title: "Move to pending page",
          icon: "success",
        });
        navigate(`/user-review/${user_id}`);
      } else {
        Toast.fire({
          title: "Failed to move pending page",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    async function fetchReviewData() {
      var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

      var headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: user?.token,
      };
      try {
        const reviewData = await callApi(
          `${baseUrl}/modules/reviews/get-review/${id}`,
          "POST",
          headers
        );
        console.log("check service =>", reviewData);
        setStatus(reviewData?.record?.status)
        setFormValues(reviewData?.record)
          ;
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    }

    fetchReviewData();
  }, [id]);



  const updateReview = async (e) => {
    e.preventDefault()
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    var payload = {
      review_id: editReview?.review_id,
      entity_details_id: editReview?.entity_details_id,
      ...formValues,
    }

    try {
      const update = await callApi(
        `${baseUrl}/modules/reviews/update`,
        "POST",
        headers,
        payload
      );
      console.log("user-review-update=>", update);
      if (update.success) {
        console.log("success", update);
        Toast.fire({
          icon: "success",
          title: "Review edited successfully",
        });
        navigate(`/user-review/${user_id}`);
      } else {
        setErrors(update.errors);
        console.error("Error: Response indicates failure", update);
        Toast.fire({
          icon: "error",
          title: update.message || "Failed to edit review",
        });
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }


  return (
    <>
      <div className="page-heading">
        <div className="page-title">
          <div className="row d-flex align-items-center justify-content-between">
            <div className="col-md-6">
              <h3>Edit Review</h3>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end ">
              <div>
                {status === "STS_PENDING" ? (
                  <>
                    <button
                      className="btn btn-success me-1 mb-1"
                      onClick={() => handleStatusApprove()}
                    >
                      Approve
                    </button>

                    <button
                      type="button"
                      className="btn btn-danger me-1 mb-1"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      Reject
                    </button>

                    {/* <!-- Modal for Reject Button --> */}
                    <div
                      className="modal fade"
                      id="staticBackdrop"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Reason for Rejection
                            </h5>
                          </div>
                          <div className="modal-body">
                            <textarea
                              className="form-control"
                              id="reject_reason"
                              rows="3"
                              name="reject_reason"
                              value={rejectReason.reject_reason || ""}
                              onChange={handleRejectChange}
                              required
                            />
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                              onClick={() => handleStatusReject()}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                {/* <!--Button to move rejected reviews to the pending page --> */}

                {status === "STS_REJECTED" && (
                  <>
                    <button
                      className="btn btn-success me-1 mb-1"
                      onClick={() => handleBackToPending()}
                    >
                      Move to pending
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="basic-horizontal-layouts">
        {
          formValues?.reject_reason && formValues?.reject_reason != null ? (
            <div className="row match-height">
              <div className="col-md-10 col-lg-10">
                <div className="card">
                  <div className="card-content">
                    <div className="card-body">
                      <form className="form form-horizontal">
                        <div className="form-body">
                          <div className="row">
                            <>
                              <div className="col-md-4">
                                <label htmlFor="zip">Rejected Reason</label>
                              </div>
                              <div className="col-md-8 form-group">
                                <p className='mb-0'>{formValues?.reject_reason}</p>
                              </div></>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        }
        <div className="row match-height">
          <div className="col-md-10 col-lg-10">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <form className="form form-horizontal">
                    <div className="form-body">
                      <div className="row">

                        <div className="col-md-4">
                          <label htmlFor="zip">Title</label>
                        </div>
                        <div className="col-md-8 form-group">
                          <input
                            type="text"
                            name="title"
                            className="form-control"
                            value={
                              formValues?.title !== undefined
                                ? formValues?.title
                                : editReview?.title
                            }
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="description">Description</label>
                        </div>
                        <div className="col-md-8 form-group">
                          <textarea
                            className="form-control"
                            id="description"
                            rows="6"
                            name="description"
                            value={
                              formValues?.description !== undefined
                                ? formValues?.description
                                : editReview.description
                            }
                            onChange={handleChange}
                            placeholder="Enter a description of your shop"
                            required
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="description">Ratings</label>
                        </div>
                        <div className="col-md-8">
                          <select id="review" value={formValues['ratings'] || editReview?.ratings} name="ratings" onChange={handleChange} className="form-select">
                            <option value="5">★★★★★ (5/5)</option>
                            <option value="4">★★★★☆ (4/5)</option>
                            <option value="3">★★★☆☆ (3/5)</option>
                            <option value="2">★★☆☆☆ (2/5)</option>
                            <option value="1">★☆☆☆☆ (1/5)</option>
                          </select>
                        </div>
                      </div>
                      <div className="row match-height">
                        <div className="col-md-10 col-lg-12 d-flex justify-content-end">
                          <div className="card mb-0">
                            <div className="card-body">
                              <button
                                onClick={updateReview}
                                className="btn btn-primary me-1 mb-1"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default EditUserReview
