import React, { useState } from "react"
import { menuItemsData } from "../../components/sidebar/MenuItemsData"
import MenuItem from "../../components/sidebar/MenuItem"
import "../../components/sidebar/sidebar.css"
import { Link } from "react-router-dom"
import hypso_logo from "../../assets/logo/logo.png"
import "./MainSideBar.css"

const MainSideBar = () => {
  return (
    <div id="sidebar">
      <div className="main_sidebar sidebar-wrapper active ">
        <div className="sidebar-header position-relative">
          <div className="d-flex justify-content-between align-items-center">
            <div className="logo">
              <Link to="/" className="hypso_title">
                <img src={hypso_logo} className="hypso-logo-sidebar"/>
              </Link>
            </div>
          </div>
        </div>
        <div className="sidebar-menu">
          <ul className="menu">
            {menuItemsData.map((menuItem, index) => {
              return (
                <div key={index}>
                  <MenuItem menuItem={menuItem} index={index} />
                </div>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default MainSideBar
