import React, { useState, useEffect } from "react";
import { callApi } from "../api/Api";
import { useUser } from "../../utils/utils";
import Message from "../errors/Message";

function CitySelector({ selectedCity, onChange, errors }) {
  const [cityDetails, setCityDetails] = useState([]);
  const { user } = useUser();

  async function getCity() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    try {
      const cityData = await callApi(
        `${baseUrl}/modules/cities/get-cities`,
        "POST",
        headers
      );
      setCityDetails(cityData.records);
    } catch (error) {
      console.error("There was an error fetching the city!", error);
    }
  }

  useEffect(() => {
    getCity();
  }, []);
  return (
    <div>
      <fieldset className="form-group">
        <select
          name="city"
          className="form-select"
          onChange={onChange}
          value={selectedCity}
        >
          <option value="" disabled selected>
            Select an city
          </option>
          {cityDetails?.map((city, i) => (
            <option key={i} value={city.city_code}>
              {city.name}
            </option>
          ))}
        </select>
        <div>
          <Message type={"error"} errors={errors} fieldName={"city"} />
        </div>
      </fieldset>
    </div>
  );
}

export default CitySelector;
