import React, { useRef, useState, useEffect } from 'react'
import { useUser } from '../../../utils/utils';
import { useNavigate, useParams } from 'react-router-dom';
import MultiImageSelector from '../../multiImageSelector/MultiImageSelector';
import Swal from 'sweetalert2';
import Message from '../../errors/Message';
import CitySelector from '../../selector/CitySelector';
import StateSelector from '../../selector/StateSelector';
import MapComponent from '../../mapSelector/MapComponent';
import AddTags from '../../Tags/AddTags/Addtags';
import AreaSelector from '../../selector/AreaSelector';
import SingleImageSelector from '../../singleImageSelector/SingleImageSelector';
import { defaultLocation } from '../../../utils/locationUtils';
import { callApi } from '../../api/Api';


const EditEvent = () => {
    const { id } = useParams();
    const { user } = useUser()
    const navigate = useNavigate();
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const inputFile = useRef(null);
    const [formValues, setFormValues] = useState([]);
    const [eventRecord, setEventRecord] = useState({})
    const [status, setStatus] = useState("");
    const [featuredImage, setFeaturedImage] = useState("");
    const [addedTags, setAddedTags] = useState([]);
    const [removedTags, setRemovedTags] = useState([]);
    const [errors, setErrors] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState();
    const [rejectReason, setRejectReason] = useState({});
    const [isFeatured, setIsFeatured] = useState();
    const [selectedImages, setSelectedImages] = useState([]);
    const image_id = selectedImages?.map((selectImage) => selectImage.image_id);
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    const [showLatitude, setShowLatitude] = useState(
        defaultLocation[0]?.latitude || 0
    );
    const [showLongitude, setShowLongitude] = useState(
        defaultLocation[0]?.longitude || 0
    );
    const [isEditMode, setIsEditMode] = useState(false);

    // Toast for Shop Add
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        },
    });

    const handleChange = (event) => {
        setFormValues({ ...formValues, [event.target.name]: event.target.value });
    };

    const onChangeFeaturedImage = (newlySelectedImage) => {
        setFeaturedImage(newlySelectedImage);
        setFormValues(prevFormValues => ({
            ...prevFormValues,
            featured_image: newlySelectedImage?.image_id // Also update the formValues
        }));
    };


    const handleAddTag = (tag) => {
        //adds a tag to the tags array in the formValues state.
        setEventRecord((prevFormValues) => ({
            ...prevFormValues,
            tags: [...prevFormValues.tags, tag],
        }));
        //updates the addedTags state
        setAddedTags((prevAddedTags) => [...prevAddedTags, tag]);
    };


    const deleteTag = (deleteTag) => {
        // Find the index of the tag to be removed
        const updatedTagIndex = eventRecord.tags.findIndex(
            (tag) => tag.tag_id === deleteTag.tag_id
        );

        // If the tag is found, remove it
        if (updatedTagIndex !== -1) {
            // Create a new array without the removed tag
            const newTags = [
                ...eventRecord.tags.slice(0, updatedTagIndex),
                ...eventRecord.tags.slice(updatedTagIndex + 1),
            ];
            // Update the formValues state with the modified array
            setEventRecord({ ...eventRecord, tags: newTags });
            // Add the removed tag to the removedTags state
            setRemovedTags((prevRemovedTags) => [...prevRemovedTags, deleteTag]);
        }
    };

    useEffect(() => {
        console.log("id", id);

        async function fetchEventData() {
            var headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: user?.token,
            };
            try {
                const eventData = await callApi(
                    `${baseUrl}/modules/events/get-event/${id}`,
                    "POST",
                    headers
                );
                console.log("response", eventData);

                if (!eventData || !eventData?.record) {
                    navigate('/*')
                    console.log("no");

                }
                setEventRecord(eventData?.record);
                setPhoneNumber(eventData?.record?.phone);
                setFeaturedImage(eventData?.record?.featured_image);
                setSelectedImages(eventData?.record?.images);
                setStatus(eventData?.record?.event_status);
                setIsFeatured(eventData?.record?.is_featured);
                // setShowLatitude(parseFloat(eventData.record.geo_location.latitude));
                // setShowLongitude(parseFloat(eventData.record.geo_location.longitude));
                setShowLatitude(parseFloat(eventData.record.geo_location.latitude) || parseFloat(defaultLocation[0]?.latitude));
                setShowLongitude(parseFloat(eventData.record.geo_location.longitude) || parseFloat(defaultLocation[0]?.longitude));


            } catch (error) {
                console.error("There was an error fetching the data!", error);
            }
        }

        fetchEventData();
    }, [id]);





    const handleCheckboxChange = async (e) => {
        setIsFeatured(e.target.checked);
    };

    const updateEvent = async (e) => {
        e.preventDefault()
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token,
        };
        var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

        var payload = {
            event_id: id,
            ...formValues,
            area_id: user?.area_id,

            images: {
                images:
                    selectedImages && selectedImages.length > 0
                        ? selectedImages.filter((img) => img).map((img) => img.image_id)
                        : null,
            },
            event_status: status,
            is_featured: isFeatured,
            social_platforms: {
                facebook: formValues?.facebook,
                instagram: formValues?.instagram,
                twitter: formValues?.twitter
            },
            address: {
                line_1: formValues.line_1,
                line_2: formValues.line_2,
                city: formValues.city,
                state: formValues.state,
                zip: formValues.zip,
                country: "IND",
                area: formValues.area,
            },
            tags: {
                added_tags: addedTags,
                removed_tags: removedTags,
            },
            geo_location: {
                latitude: latitude,
                longitude: longitude,
            },
        };
        console.log(payload);

        try {
            const eventEditResponse = await callApi(
                `${baseUrl}/modules/events/update`,
                "POST",
                headers,
                payload
            );
            console.log("response", eventEditResponse);

            if (eventEditResponse.success) {
                console.log("success", eventEditResponse);
                Toast.fire({
                    icon: "success",
                    title: "Event edited successfully",
                });
                navigate("/events");
            } else {
                setErrors(eventEditResponse.errors);
                console.error("Error: Response indicates failure", eventEditResponse);
                Toast.fire({
                    icon: "error",
                    title: eventEditResponse.message || "Failed to edit event",
                });
            }
        } catch (error) {
            console.error("Error:", error);
            Toast.fire({
                icon: "error",
                title: "An error occurred while editing the event",
            });
        }
    }

    // handleStatus approve

    const handleStatusApprove = async () => {
        const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
        const headers = {
            "Content-Type": "application/json",
            token: user?.token,
        };
        const payload = {
            event_id: id,
            event_status: "STS_LIVE",
            action: "APPROVE",
            
        };

        console.log("payload =>", payload);

        try {
            const approveStatusResponse = await callApi(
                `${baseUrl}/modules/events/update`,
                "POST",
                headers,
                payload
            );

            if (approveStatusResponse.success) {
                console.log("Status update success", approveStatusResponse);
                Toast.fire({
                    title: "Approved",
                    icon: "success",
                });
                navigate("/events");
            } else {
                console.log("Status update failed", approveStatusResponse);
                Toast.fire({
                    title: "Failed to Approve",
                    icon: "error",
                });
            }
        } catch (error) {
            console.error("Error:", error);
            Toast.fire({
                title: "An error occurred while approving the shop",
                icon: "error",
            });
        }
    };

    // handleStatus reject

    const handleRejectChange = (e) => {
        setRejectReason({ ...rejectReason, [e.target.name]: e.target.value });
    };

    const handleStatusReject = async () => {
        const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
        const headers = {
            "Content-Type": "application/json",
            token: user?.token,
        };
        const payload = {
            event_id: id,
            reject_reason: rejectReason.reject_reason,
            event_status: "STS_REJECTED",
            action: "REJECT",
           
        };
        console.log("payload status", payload);

        try {
            const rejectStatusResponse = await callApi(
                `${baseUrl}/modules/events/update`,
                "POST",
                headers,
                payload
            );

            if (rejectStatusResponse.success) {
                console.log("Status update success", rejectStatusResponse);
                Toast.fire({
                    title: "Rejected",
                    icon: "success",
                });
                navigate("/events");
            } else {
                console.log("Status update failed", rejectStatusResponse);
                Toast.fire({
                    title: "Failed to Reject",
                    icon: "error",
                });
            }
        } catch (error) {
            console.error("Error:", error);
            Toast.fire({
                title: "An error occurred while rejecting the offer",
                icon: "error",
            });
        }
    };

    // handleStatus back to pending page

    const handleBackToPending = async () => {
        const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
        const headers = {
            "Content-Type": "application/json",
            token: user?.token,
        };
        const payload = {
            event_id: id,
            event_status: "STS_PENDING",
            action: "APPROVE",
         
        };
        console.log("your payload", payload);
        try {
            const backToPendingResponse = await callApi(
                `${baseUrl}/modules/events/update`,
                "POST",
                headers,
                payload
            );

            if (backToPendingResponse.success) {
                console.log("backToPendingResponse", backToPendingResponse);
                Toast.fire({
                    title: "Move to pending page",
                    icon: "success",
                });
                navigate("/events");
            } else {
                Toast.fire({
                    title: "Failed to move pending page",
                    icon: "error",
                });
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <>
            <div className="page-heading">
                <div className="page-title">
                    <div className="row">
                        <div className="col-12 col-md-6 order-md-1 order-last pb-4">
                            <h3>Edit Event</h3>
                        </div>

                        <div className="col-12 col-md-6 order-md-2 order-first">
                            <nav
                                aria-label="breadcrumb"
                                className="breadcrumb-header float-start float-lg-end"
                            ></nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-flex align-items-center justify-content-end">
                            {status === "STS_LIVE" ? (
                                <div>
                                    <div class="form-check">
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                class="form-check-input form-check-danger"
                                                name="customCheck"
                                                id="customColorCheck1"
                                                onChange={handleCheckboxChange}
                                                checked={isFeatured}
                                            ></input>
                                            <label class="form-check-label" for="customColorCheck1">
                                                Is Featured
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <div>
                                {status === "STS_PENDING" ? (
                                    <>
                                        <button
                                            className="btn btn-success me-1 mb-1"
                                            onClick={() => handleStatusApprove()}
                                        >
                                            Approve
                                        </button>

                                        <button
                                            type="button"
                                            className="btn btn-danger me-1 mb-1"
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop"
                                        >
                                            Reject
                                        </button>

                                        {/* <!-- Modal for Reject Button --> */}
                                        <div
                                            className="modal fade"
                                            id="staticBackdrop"
                                            data-bs-backdrop="static"
                                            data-bs-keyboard="false"
                                            aria-labelledby="staticBackdropLabel"
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5
                                                            className="modal-title"
                                                            id="staticBackdropLabel"
                                                        >
                                                            Reason for Rejection
                                                        </h5>
                                                    </div>
                                                    <div className="modal-body">
                                                        <textarea
                                                            className="form-control"
                                                            id="reject_reason"
                                                            rows="3"
                                                            name="reject_reason"
                                                            value={rejectReason.reject_reason || ""}
                                                            onChange={handleRejectChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            data-bs-dismiss="modal"
                                                        >
                                                            Close
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            data-bs-dismiss="modal"
                                                            onClick={() => handleStatusReject()}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : null}

                                {/* <!--Button to move rejected services to the pending page --> */}

                                {status === "STS_REJECTED" && (
                                    <>
                                        <button
                                            className="btn btn-success me-1 mb-1"
                                            onClick={() => handleBackToPending()}
                                        >
                                            Move to pending
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="section">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <ul class="nav nav-tabs event-nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab"
                                            aria-controls="home" aria-selected="true">Basic Information</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="location-tab" data-bs-toggle="tab" href="#location" role="tab"
                                            aria-controls="location" aria-selected="false">Location</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="contact-tab" data-bs-toggle="tab" href="#contact" role="tab"
                                            aria-controls="contact" aria-selected="false">Contact</a>
                                    </li>

                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="timing-tab" data-bs-toggle="tab" href="#timing" role="tab"
                                            aria-controls="timing" aria-selected="false">Timing</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="pricing-tab" data-bs-toggle="tab" href="#pricing" role="tab"
                                            aria-controls="pricing" aria-selected="false">Pricing</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="guide-tab" data-bs-toggle="tab" href="#guide" role="tab"
                                            aria-controls="guide" aria-selected="false">Event Guide</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="social-tab" data-bs-toggle="tab" href="#social" role="tab"
                                            aria-controls="social" aria-selected="false">Social Platform</a>
                                    </li>
                                </ul>

                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        {/* Basic Information Section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Basic Information</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Title</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_name"
                                                                                    value={formValues?.event_name != undefined ? formValues?.event_name : eventRecord?.event_name}
                                                                                    placeholder="Enter the title of your event"
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_name"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Add Tags
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <AddTags
                                                                                    onAddTag={handleAddTag}
                                                                                    addedTags={addedTags}
                                                                                    setAddedTags={setAddedTags}
                                                                                    removedTags={removedTags}
                                                                                    setRemovedTags={setRemovedTags}
                                                                                />
                                                                                <div className="input-tag">
                                                                                    <ul className="input-tag__tags">
                                                                                        {eventRecord?.tags && eventRecord?.tags.length > 0
                                                                                            ? eventRecord?.tags.map((tag, index) => (
                                                                                                <li key={index}>
                                                                                                    {tag.name}
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        onClick={() => deleteTag(tag)}
                                                                                                    >
                                                                                                        <i className="bi bi-x"></i>
                                                                                                    </button>
                                                                                                </li>
                                                                                            ))
                                                                                            : null}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Description
                                                                                </label>
                                                                            </div>

                                                                            <div className="col-md-8 form-group">
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    id="exampleFormControlTextarea1"
                                                                                    rows="3"
                                                                                    name="description"
                                                                                    value={formValues?.description != undefined ? formValues?.description : eventRecord?.description}
                                                                                    placeholder="Enter a description of your offer"
                                                                                    required
                                                                                    onChange={handleChange}
                                                                                ></textarea>
                                                                            </div>



                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Email</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="email"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="email"
                                                                                    value={formValues?.email != undefined ? formValues?.email : eventRecord?.email}
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div >
                                        </section >
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Upload Images</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Upload Featured Image
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 col-lg-8 col-xs-8 form-group">
                                                                                <fieldset>
                                                                                    <SingleImageSelector
                                                                                        imageToShow={featuredImage}
                                                                                        onChangeImage={onChangeFeaturedImage}
                                                                                    />
                                                                                </fieldset>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Upload Cover Image
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 col-lg-8 col-xs-8 form-group">
                                                                                <fieldset>
                                                                                    <MultiImageSelector
                                                                                        selectedImages={selectedImages}
                                                                                        setSelectedImages={setSelectedImages}
                                                                                    />
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10 d-flex justify-content-end">
                                                    <div className="card mb-0">
                                                        <div className="card-body p-0">
                                                            <button
                                                                onClick={updateEvent}
                                                                className="btn btn-primary me-1 mb-1"
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div class="tab-pane fade" id="location" role="tabpanel" aria-labelledby="location-tab">
                                        {/* Location Section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Listing Location</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            {/* <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Country / Region
                            </label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              id="first-name-horizontal"
                              className="form-control"
                              name="country"
                              value={formValues["country"] || ""}
                              onChange={handleChange}
                              placeholder="Select Country"
                              required
                            ></input>
                            <div>
                              <Message
                                type={"error"}
                                errors={errors}
                                fieldName={"country"}
                              />
                            </div>
                          </div> */}<div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Venue
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="venue"
                                                                                    value={formValues?.venue != undefined ? formValues["venue"] : eventRecord?.venue}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter the venue of your event"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"venue"}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">State</label>
                                                                            </div>
                                                                            <div className="col-md-8 ">
                                                                                <StateSelector
                                                                                    selectedState={formValues.state || eventRecord?.state}
                                                                                    onChange={handleChange}
                                                                                    errors={errors}
                                                                                />
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">City</label>
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                <CitySelector
                                                                                    selectedCity={formValues.city || eventRecord?.city}
                                                                                    onChange={handleChange}
                                                                                    errors={errors}
                                                                                />
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    PIN Code
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="zip"
                                                                                    value={formValues?.zip != undefined ? formValues["zip"] : eventRecord?.zip}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Select PIN code"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"zip"}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Street
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="line_1"
                                                                                    value={formValues["line_1"] || eventRecord?.line_1}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter the address of your event"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"line_1"}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Nearby Landmark
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="line_2"
                                                                                    value={formValues["line_2"] || eventRecord?.line_2}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter landmarks near your your event"
                                                                                    required
                                                                                ></input>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Area</label>
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                <AreaSelector
                                                                                    selectedArea={formValues.area || eventRecord?.area}
                                                                                    onChange={handleChange}
                                                                                    errors={errors}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section class="section" id="basic-horizontal-layouts">
                                            <div class="row">
                                                <div class="col-md-10 col-lg-10">
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h5 class="card-title">Your Location</h5>
                                                        </div>
                                                        <MapComponent
                                                            setLatitude={setLatitude}
                                                            setLongitude={setLongitude}
                                                            showLatitude={showLatitude}
                                                            showLongitude={showLongitude}
                                                            setShowLatitude={setShowLatitude}
                                                            setShowLongitude={setShowLongitude}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10 d-flex justify-content-end">
                                                    <div className="card mb-0">
                                                        <div className="card-body p-0">
                                                            <button
                                                                onClick={updateEvent}
                                                                className="btn btn-primary me-1 mb-1"
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                        {/* Contact Section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Organizer Contact</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Organizer Name</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_organizer_name"
                                                                                    onChange={handleChange}
                                                                                    placeholder=""
                                                                                    value={formValues?.event_organizer_name != undefined ? formValues?.event_organizer_name : eventRecord?.event_organizer_name}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_organizer_name"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Organizer Contact</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_organizer_contact"
                                                                                    value={formValues?.event_organizer_contact != undefined ? formValues?.event_organizer_contact : eventRecord?.event_organizer_contact}
                                                                                    placeholder=""
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_organizer_contact"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div >
                                            <div className="col-md-10 col-lg-10 px-4 py-0">
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        onClick={updateEvent}
                                                        className="btn btn-primary me-1 mb-1"
                                                    >
                                                        Save
                                                    </button>
                                                </div>

                                            </div>
                                        </section >

                                    </div>
                                    <div class="tab-pane fade" id="timing" role="tabpanel" aria-labelledby="timing-tab">
                                        {/* Duration and Timing Section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Duration and Timing</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Event Start Date</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="date"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_start_date"
                                                                                    value={formValues?.event_start_date || eventRecord?.event_start_date}
                                                                                    placeholder=""
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_start_date"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Event Start Time</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="time"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_start_time"
                                                                                    value={formValues?.event_start_time || eventRecord?.event_start_time}
                                                                                    placeholder=""
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_start_time"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Event End Date</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="date"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_end_date"
                                                                                    value={formValues?.event_end_date || eventRecord?.event_end_date}
                                                                                    placeholder=""
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_end_date"}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Event End Time</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="time"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_end_time"
                                                                                    onChange={handleChange}
                                                                                    placeholder=""
                                                                                    value={formValues?.event_end_time || eventRecord?.event_end_time}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_end_time"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Registration Deadline</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="date"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_registration_end_date"
                                                                                    value={formValues?.event_registration_end_date || eventRecord?.event_registration_end_date}
                                                                                    placeholder=""
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_registration_end_date"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div >
                                            <div className="col-md-10 col-lg-10 px-4 py-0">
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        onClick={updateEvent}
                                                        className="btn btn-primary me-1 mb-1"
                                                    >
                                                        Save
                                                    </button>
                                                </div>

                                            </div>
                                        </section >
                                    </div>
                                    <div class="tab-pane fade" id="pricing" role="tabpanel" aria-labelledby="pricing-tab">
                                        {/* Pricing Section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Pricing and Capacity</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Event Ticket Price</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_ticket_price"
                                                                                    value={formValues?.event_ticket_price != undefined ? formValues?.event_ticket_price : eventRecord?.event_ticket_price}
                                                                                    placeholder="Enter the ticket price"
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_ticket_price"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Discounted Price</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="discounted_price"
                                                                                    value={formValues?.discounted_price != undefined ? formValues?.discounted_price : eventRecord?.discounted_price}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter the discount price"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    {/* <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"name"}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>



                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Total Capacity
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="total_capacity"
                                                                                    value={formValues?.total_capacity != undefined ? formValues?.total_capacity : eventRecord?.total_capacity}
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    {/* <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"phone"}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Terms & Conditions</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    id="exampleFormControlTextarea1"
                                                                                    rows="3"
                                                                                    name="terms_and_conditions"
                                                                                    value={formValues?.terms_and_conditions != undefined ? formValues?.terms_and_conditions : eventRecord?.terms_and_conditions}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter the terms and conditions of your event"
                                                                                    required
                                                                                ></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div >
                                            <div className="col-md-10 col-lg-10 px-4 py-0">
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        onClick={updateEvent}
                                                        className="btn btn-primary me-1 mb-1"
                                                    >
                                                        Save
                                                    </button>
                                                </div>

                                            </div>
                                        </section >
                                    </div>
                                    <div class="tab-pane fade" id="guide" role="tabpanel" aria-labelledby="guide-tab">
                                        {/* Event Guide Section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Event Guide</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Choose Language</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <fieldset className="form-group">
                                                                                    <select
                                                                                        name="language"
                                                                                        onChange={handleChange}
                                                                                        value={formValues?.language || eventRecord?.language}
                                                                                        className="form-select"
                                                                                    >
                                                                                        <option value="" disabled selected>
                                                                                            Select language
                                                                                        </option>
                                                                                        <option value="">Tamil</option>
                                                                                        <option value="">English</option>
                                                                                        <option value="">Telugu</option>
                                                                                        <option value="">Malayalam</option>
                                                                                    </select>
                                                                                    <div>
                                                                                        {/* <Message type={"error"} errors={errors} fieldName={"area"} /> */}
                                                                                    </div>
                                                                                </fieldset>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Event Mode</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <fieldset className="form-group">
                                                                                    <select
                                                                                        name="event_mode"
                                                                                        onChange={handleChange}
                                                                                        value={formValues?.event_mode || eventRecord?.event_mode}
                                                                                        className="form-select"
                                                                                    >
                                                                                        <option value="" disabled selected>
                                                                                            Select mode
                                                                                        </option>
                                                                                        <option value="">Live Performance</option>
                                                                                        <option value="">Online Streaming</option>
                                                                                        <option value="">Hybrid</option>
                                                                                        <option value="">Tv Telecast</option>
                                                                                    </select>
                                                                                    <div>
                                                                                        {/* <Message type={"error"} errors={errors} fieldName={"area"} /> */}
                                                                                    </div>
                                                                                </fieldset>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Additional Information
                                                                                </label>
                                                                            </div>

                                                                            <div className="col-md-8 form-group">
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    id="exampleFormControlTextarea1"
                                                                                    rows="3"
                                                                                    name="additional_info"
                                                                                    value={formValues?.additional_info != undefined ? formValues?.additional_info : eventRecord?.additional_info}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter the additonal information of your event"
                                                                                    required
                                                                                ></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-10 col-lg-10 px-4 py-0">
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        onClick={updateEvent}
                                                        className="btn btn-primary me-1 mb-1"
                                                    >
                                                        Save
                                                    </button>
                                                </div>

                                            </div>
                                        </section >
                                    </div>
                                    <div class="tab-pane fade" id="social" role="tabpanel" aria-labelledby="social-tab">
                                        {/* Social Platform section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Social Platform</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Facebook</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="facebook"
                                                                                    value={formValues?.facebook != undefined ? formValues?.facebook : eventRecord?.social_platforms?.facebook}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter your facebook event url"
                                                                                    required
                                                                                ></input>
                                                                                <div>

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Instagram</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="instagram"
                                                                                    onChange={handleChange}
                                                                                    value={formValues?.instagram != undefined ? formValues?.instagram : eventRecord?.social_platforms?.instagram}
                                                                                    placeholder="Enter your Instagram event url"
                                                                                    required
                                                                                ></input>
                                                                                <div>

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Twitter</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="twitter"
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter your Twitter event url"
                                                                                    value={formValues?.twitter != undefined ? formValues?.twitter : eventRecord?.social_platforms?.twitter}
                                                                                ></input>
                                                                                <div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-10 col-lg-10 px-4 py-0">
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        onClick={updateEvent}
                                                        className="btn btn-primary me-1 mb-1"
                                                    >
                                                        Save
                                                    </button>
                                                </div>

                                            </div>
                                        </section >

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >

                </div >
            </section >
        </>
    )
}

export default EditEvent
