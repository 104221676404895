import React from 'react'

function HypsoFooter() {
    return (
        <>
            <footer>
                <div className="footer clearfix mb-0 text-muted">
                    <div className="float-start">
                        <p>Hypso </p>
                    </div>
                    
                </div>
            </footer>
        </>
    )
}

export default HypsoFooter