import React, { useState } from "react";
import "./SingleImageSelector.css";
import loading_gif from "../../assets/gif/loading_gif.gif";
import { useUser } from "../../utils/utils";

function SingleImageSelector({setFormValues, imageToShow, onChangeImage, label, className }) {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser();

  // console.log("image path chaecking =>", imageToShow);

  const handleImageSelect = async (event) => {
    const imageToUpload = event.target.files[0];
    //upload image using api and receive image object
    if (imageToUpload) {
      await uploadImage(imageToUpload);
    }

    //on receiving image object, call onChangeImage() and pass image object
  };

  const uploadImage = async (imageToUpload) => {
    //turn on loader
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", imageToUpload);
      formData.append('area_id', user?.area_id);

      const headers = {
        token: user?.token,
      };

      var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

      const featureImageResponse = await fetch(
        `${baseUrl}/modules/images/upload`,
        {
          method: "POST",
          headers: headers,
          body: formData,
        }
      );

      if (featureImageResponse.ok) {
        const uploadedImage = await featureImageResponse.json();
        console.log("Upload Successful:" + JSON.stringify(uploadedImage));

        //call onChangeImage and pass uploaded image object
        onChangeImage(uploadedImage);
      } else {
        console.error("Upload Failed:", featureImageResponse.statusText);
        // Handle error
      }
    } catch (error) {
      console.error("Upload Failed:", error);
      // Handle error
    } finally {
      //turn on loader
      setIsLoading(false);
    }
  };

  const onRemoveImage = () => {
    onChangeImage(null);
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      featured_image: null // Also update the formValues
  }));
  };

  return (
    <div>
      <div className={className ? className : "input-group"}>
        <label htmlFor="first-name-horizontal" className="form-label">
          {label}
        </label>

        <input
          type="file"
          className="form-control"
          name="file"
          onChange={handleImageSelect}
        ></input>
      </div>
      {isLoading && <img src={loading_gif} className="loading_gif"></img>}
      <div className="selectedImagesWrapper">
        <div className="selecedImage_group">
          {imageToShow && imageToShow.path && (
            <>
              <img className="image" src={imageToShow.path} alt="selected" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"20px"}
                className="selectedImage_icon"
                viewBox="0 0 512 512"
                onClick={onRemoveImage}
              >
                <path
                  fill="#EA3855"
                  d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
                />
              </svg>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SingleImageSelector;
