export const TypeList = [
  {
    list: "ENT_SHOP",
    label:"Shop"
  },
  {
    list: "ENT_SERVICE",
    label:"Service"
  },
  {
    list: "ENT_PROPERTY",
    label:"Property"
  },
  {
    list: "ENT_EVENT",
    label:"Event"
  },
]
