import React, { useState, useEffect } from "react";
import { callApi } from "../../api/Api";
import Swal from "sweetalert2";
import CitySelector from "../../selector/CitySelector";
import StateSelector from "../../selector/StateSelector";
import ShopCategorySelector from "../../selector/ShopCategorySelector";
import ListSelector from "../../selector/ListTypeSelector";
import WorkingHours from "../../workingHours/WorkingHours";
import SingleImageSelector from "../../singleImageSelector/SingleImageSelector";
import MultiImageSelector from "../../multiImageSelector/MultiImageSelector";
import { useUser } from "../../../utils/utils";
import AreaSelector from "../../selector/AreaSelector";
import Message from "../../errors/Message";
import { useNavigate } from "react-router-dom";
import MobileNumberVerified from "../../mobileNumberVerified/MobileNumberVerified";
import MapComponent from "../../mapSelector/MapComponent";
import { defaultLocation } from "../../../utils/locationUtils";
import AddTags from "../../Tags/AddTags/Addtags";

function AddShop() {
  const [formValues, setFormValues] = useState([]);
  const [addedTags, setAddedTags] = useState([]);
  const [removedTags, setRemovedTags] = useState([]);
  const { user, setUser } = useUser();
  const [errors, setErrors] = useState([]);
  const [featuredImage, setFeaturedImage] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [phoneNumber, setPhoneNumber] = useState(formValues?.phone || "");
  const [phoneVerifyModal, setPhoneVerifyModal] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState("");
  const [showLatitude, setShowLatitude] = useState(
    defaultLocation[0]?.latitude || 0
  );
  const [showLongitude, setShowLongitude] = useState(
    defaultLocation[0]?.longitude || 0
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const navigate = useNavigate();
  const image_id = selectedImages?.map((selectImage) => selectImage.image_id);

  console.log("phoneNumber", phoneNumber);

  //WorkingHours Data
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [workingHours, setWorkingHours] = useState(
    days.map((day) => ({
      day,
      start: {
        time: "",
        session: "",
      },
      end: {
        time: "",
        session: "",
      },
      status: false, //Initially, none of the states of active
    }))
  );

  // Update phoneNumber whenever formValues.phone changes
  useEffect(() => {
    setPhoneNumber(formValues.phone || ""); // Keep phoneNumber in sync with formValues.phone
  }, [formValues.phone]);

  // useEffect for userDataFetch
  useEffect(() => {
    userDataFetch();
  }, []);

  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  // handleChange for featuredImages
  const onChangeFeaturedImage = (newlySelectedImage) => {
    // setFeaturedImage(newlySelectedImage.image_id)
    setFeaturedImage(newlySelectedImage);
  };

  const handleMobileNumberVerify = async (e) => {
    e.preventDefault();

    if (!userInfo?.phone_verified || userInfo?.phone !== formValues.phone) {
      setPhoneVerifyModal(true);
    }
  };

  // Toast for Shop Add
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

  const userDataFetch = async () => {
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    try {
      const userData = await callApi(
        `${baseUrl}/modules/users/get-user/${user?.user_id}`,
        "POST",
        headers
      );
      console.log("user=>", userData);
      setUserInfo(userData.record);
    } catch (error) {
      console.log("errors", error);
    }
  };

  const renderButton = () => {
    // Check if the user's phone is not verified
    if (
      userInfo?.phone_verified === false ||
      formValues?.phone !== userInfo?.phone
    ) {
      return (
        <div className="col-sm-12 d-flex justify-content-end">
          <button
            className="btn btn-primary me-1 mb-1"
            onClick={handleMobileNumberVerify}
          >
            Save
          </button>
        </div>
      );
    }

    // Return Save button if phone is verified or form phone is not set
    return (
      <div className="col-sm-12 d-flex justify-content-end">
        <button
          className="btn btn-primary me-1 mb-1"
          onClick={handleShopSubmit}
        >
          Save
        </button>
      </div>
    );
  };

  const handleShopSubmit = async (e) => {
    e.preventDefault();

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    var payload = {
      name: formValues.name,
      category_id: formValues.category_id,
      description: formValues.description,
      phone: formValues.phone,
      email: formValues.email,
      area_id: user?.area_id,
      address: {
        line_1: formValues.line_1,
        line_2: formValues.line_2,
        city: formValues.city,
        state: formValues.state,
        zip: formValues.zip,
        country: "IND",
        area: formValues.area,
      },
      shop_timings: {
        working_hours: workingHours,
      },
      entity_type: "ENT_SHOP",
      tags: {
        added_tags: addedTags,
      },
      featured_image: featuredImage.image_id,
      images: { images: image_id },
      geo_location: {
        latitude: latitude,
        longitude: longitude,
      },
    };

    console.log(payload);

    try {
      const ShopResponse = await callApi(
        `${baseUrl}/modules/shops/create`,
        "POST",
        headers,
        payload
      );

      if (ShopResponse.success) {
        console.log("success", ShopResponse);
        Toast.fire({
          icon: "success",
          title: "Data saved successfully",
        });
        navigate("/shops");
      } else {
        setErrors(ShopResponse.errors);
        console.error("Error: Response indicates failure", ShopResponse);
        Toast.fire({
          icon: "error",
          title: ShopResponse.message || "Failed to save data",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.fire({
        icon: "error",
        title: "An error occurred while adding the shop",
      });
    }
  };

  return (
    <div>
      <div className="page-heading">
        <div className="page-title">
          <div className="row">
            <div className="col-12 col-md-6 order-md-1 order-last pb-4">
              <h3>Add Shop</h3>
            </div>
            <div className="col-12 col-md-6 order-md-2 order-first">
              <nav
                aria-label="breadcrumb"
                className="breadcrumb-header float-start float-lg-end"
              ></nav>
            </div>
          </div>
        </div>
      </div>
      <section className="section">
        <section id="basic-horizontal-layouts">
          <div className="row match-height">
            <div className="col-md-10 col-lg-10">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Basic Information</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form form-horizontal">
                      <div className="form-body">
                        <div className="row">
                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">Title</label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              id="first-name-horizontal"
                              className="form-control"
                              name="name"
                              value={formValues["name"] || ""}
                              onChange={handleChange}
                              placeholder="Enter the title / name of your shop"
                              required
                            ></input>
                            <div>
                              <Message
                                type={"error"}
                                errors={errors}
                                fieldName={"name"}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Choose Shop Category
                            </label>
                          </div>
                          <div className="col-md-8 ">
                            <ShopCategorySelector
                              selectedCategory={formValues.category_id}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Add Tags
                            </label>
                          </div>
                          <div className="col-md-8 form-group">
                            <AddTags
                              addedTags={addedTags}
                              setAddedTags={setAddedTags}
                              removedTags={removedTags}
                              setRemovedTags={setRemovedTags}
                            />
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Description
                            </label>
                          </div>

                          <div className="col-md-8 form-group">
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="description"
                              value={formValues["description"] || ""}
                              onChange={handleChange}
                              placeholder="Enter a description of your shop"
                              required
                            ></textarea>
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Phone Number
                            </label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="tel"
                              id="first-name-horizontal"
                              className="form-control"
                              name="phone"
                              value={formValues["phone"] || ""}
                              onChange={handleChange}
                              required
                            ></input>
                            <div>
                              <Message
                                type={"error"}
                                errors={errors}
                                fieldName={"phone"}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">Email</label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="email"
                              id="first-name-horizontal"
                              className="form-control"
                              name="email"
                              value={formValues["email"] || ""}
                              onChange={handleChange}
                              required
                            ></input>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="basic-horizontal-layouts">
          <div className="row match-height">
            <div className="col-md-10 col-lg-10">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Working Hours</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form form-horizontal">
                      <div className="form-body">
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-xs-12 form-group">
                            <WorkingHours
                              workingHours={workingHours}
                              setWorkingHours={setWorkingHours}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="basic-horizontal-layouts">
          <div className="row match-height">
            <div className="col-md-10 col-lg-10">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Listing Location</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form form-horizontal">
                      <div className="form-body">
                        <div className="row">
                          {/* <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Country / Region
                            </label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              id="first-name-horizontal"
                              className="form-control"
                              name="country"
                              value={formValues["country"] || ""}
                              onChange={handleChange}
                              placeholder="Select Country"
                              required
                            ></input>
                            <div>
                              <Message
                                type={"error"}
                                errors={errors}
                                fieldName={"country"}
                              />
                            </div>
                          </div> */}

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">State</label>
                          </div>
                          <div className="col-md-8 ">
                            <StateSelector
                              selectedState={formValues.state}
                              onChange={handleChange}
                              errors={errors}
                            />
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">City</label>
                          </div>
                          <div className="col-md-8">
                            <CitySelector
                              selectedCity={formValues.city}
                              onChange={handleChange}
                              errors={errors}
                            />
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              PIN Code
                            </label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              id="first-name-horizontal"
                              className="form-control"
                              name="zip"
                              value={formValues["zip"] || ""}
                              onChange={handleChange}
                              placeholder="Select PIN code"
                              required
                            ></input>
                            <div>
                              <Message
                                type={"error"}
                                errors={errors}
                                fieldName={"zip"}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Street
                            </label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              id="first-name-horizontal"
                              className="form-control"
                              name="line_1"
                              value={formValues["line_1"] || ""}
                              onChange={handleChange}
                              placeholder="Enter the address of your shop"
                              required
                            ></input>
                            <div>
                              <Message
                                type={"error"}
                                errors={errors}
                                fieldName={"line_1"}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Nearby Landmark
                            </label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              id="first-name-horizontal"
                              className="form-control"
                              name="line_2"
                              value={formValues["line_2"] || ""}
                              onChange={handleChange}
                              placeholder="Enter landmarks near your your shop"
                              required
                            ></input>
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">Area</label>
                          </div>
                          <div className="col-md-8">
                            <AreaSelector
                              selectedArea={formValues.area}
                              onChange={handleChange}
                              errors={errors}
                            />
                          </div>

                          {/* <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Listing type
                            </label>
                          </div>
                          <div className="col-md-8 form-group">
                            <ListSelector
                              selectedList={formValues.entity_type}
                              onChange={handleChange}
                              errors={errors}
                            />
                          </div> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section">
          <div class="row">
            <div class="col-md-10 col-lg-10">
              <div class="card">
                <div class="card-header">
                  <h5 class="card-title">Your Location</h5>
                </div>
                <MapComponent
                  setLatitude={setLatitude}
                  setLongitude={setLongitude}
                  showLatitude={showLatitude}
                  showLongitude={showLongitude}
                  setShowLatitude={setShowLatitude}
                  setShowLongitude={setShowLongitude}
                />
              </div>
            </div>
          </div>
        </section>

        <section id="basic-horizontal-layouts">
          <div className="row match-height">
            <div className="col-md-10 col-lg-10">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Upload Images</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form form-horizontal">
                      <div className="form-body">
                        <div className="row">
                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Upload Cover Image
                            </label>
                          </div>
                          <div className="col-md-8 col-lg-8 col-xs-8 form-group">
                            <fieldset>
                              <SingleImageSelector
                                imageToShow={featuredImage}
                                onChangeImage={onChangeFeaturedImage}
                              />
                            </fieldset>
                          </div>

                          <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Upload Other Images
                            </label>
                          </div>
                          <div className="col-md-8 col-lg-8 col-xs-8 form-group">
                            <fieldset>
                              <MultiImageSelector
                                selectedImages={selectedImages}
                                setSelectedImages={setSelectedImages}
                              />
                            </fieldset>
                          </div>

                          {/* Button For Submit */}
                          {renderButton()}

                          {/* for phoneNumberVerify */}
                          <MobileNumberVerified
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                            isEditMode={isEditMode}
                            phoneVerifyModal={phoneVerifyModal}
                            setPhoneVerifyModal={setPhoneVerifyModal}
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            handleMobileNumberVerify={handleMobileNumberVerify}
                            newPhoneNumber={formValues?.phone}
                            setFormValues={setFormValues}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

export default AddShop;
