import React, { useState, useEffect } from "react";
import SingleImageSelector from "../../singleImageSelector/SingleImageSelector";
import { useUser } from "../../../utils/utils";
import { callApi } from "../../api/Api";
import Message from "../../errors/Message";
import ListSelector from "../../selector/ListTypeSelector";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";


const AddCategory = () => {
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState([]);
    const { user, setUser } = useUser();
    const [featuredImage, setFeaturedImage] = useState("");
    const [errors, setErrors] = useState([]);
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;



    const handleChange = (event) => {
        setFormValues({ ...formValues, [event.target.name]: event.target.value });
    };
    const onChangeFeaturedImage = (newlySelectedImage) => {
        // setFeaturedImage(newlySelectedImage.image_id)
        setFeaturedImage(newlySelectedImage);
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        },
    });

    const handleCategorySubmit = async (e) => {
        e.preventDefault();

        var headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token,
        };

        var payload = {
            ...formValues,
            featured_image: featuredImage.image_id,

        };

        console.log(payload);

        try {
            const response = await callApi(
                `${baseUrl}/modules/categories/create`,
                "POST",
                headers,
                payload
            );
            console.log('response', response);


            if (response.success) {
                console.log("success", response);
                Toast.fire({
                    icon: "success",
                    title: "Data saved successfully",
                });
                navigate("/categories");
            } else {
                setErrors(response.errors);
                console.error("Error: Response indicates failure", response);
                Toast.fire({
                    icon: "error",
                    title: response.message || "Failed to save data",
                });
            }
        } catch (error) {
            console.error("Error:", error);
            Toast.fire({
                icon: "error",
                title: "An error occurred while adding the shop",
            });
        }
    };


    return (
        <>
            <div className="">
                <div className="page-heading">
                    <div className="page-title">
                        <div className="row">
                            <div className="col-12 col-md-6 order-md-1 order-last pb-4">
                                <h3>Add Category</h3>
                            </div>
                            <div className="col-12 col-md-6 order-md-2 order-first">
                                <nav
                                    aria-label="breadcrumb"
                                    className="breadcrumb-header float-start float-lg-end"
                                ></nav>
                            </div>
                        </div>
                    </div>
                </div>

                <section id="basic-horizontal-layouts">
                    <div className="row match-height">
                        <div className="col-md-10 col-lg-10">
                            <div className="card">

                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form form-horizontal">
                                            <div className="form-body">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label htmlFor="first-name-horizontal">Title</label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <input
                                                            type="text"
                                                            id="first-name-horizontal"
                                                            className="form-control"
                                                            name="name"
                                                            value={formValues["name"] || ""}
                                                            onChange={handleChange}
                                                            placeholder="Enter the title / name of your category"
                                                            required
                                                        ></input>
                                                        <div>
                                                            <Message
                                                                type={"error"}
                                                                errors={errors}
                                                                fieldName={"name"}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="first-name-horizontal">
                                                            Description
                                                        </label>
                                                    </div>

                                                    <div className="col-md-8 form-group">
                                                        <textarea
                                                            className="form-control"
                                                            id="exampleFormControlTextarea1"
                                                            rows="5"
                                                            name="description"
                                                            value={formValues["description"] || ""}
                                                            onChange={handleChange}
                                                            placeholder="Enter a description of your category"
                                                            required
                                                        ></textarea>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="first-name-horizontal">Entity Type</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <ListSelector
                                                            selectedList={formValues.entity_type}
                                                            onChange={handleChange}
                                                            errors={errors}
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="first-name-horizontal">Status</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <fieldset className="form-group">
                                                            <select name="is_active" onChange={handleChange} className="form-select" id="">
                                                                <option value="" disabled selected>
                                                                    Select status
                                                                </option>
                                                                <option value="true">Active</option>
                                                                <option value="false">Inactive</option>
                                                            </select>
                                                        </fieldset>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="first-name-horizontal">
                                                            Upload Cover Image
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 col-lg-8 col-xs-8 form-group">
                                                        <fieldset>
                                                            <SingleImageSelector
                                                                imageToShow={featuredImage}
                                                                onChangeImage={onChangeFeaturedImage}
                                                            />
                                                        </fieldset>
                                                    </div>
                                                    <div className="col-sm-12 d-flex justify-content-end">
                                                        <button
                                                            className="btn btn-primary me-1 mb-1"
                                                            onClick={handleCategorySubmit}
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default AddCategory
