import { React, useState, useEffect } from "react";
import "./Dashboard.css";
import { useUser } from "../../utils/utils";
import { callApi } from "../api/Api";
import { Link } from "react-router-dom";

function Dashboard() {
  const { user, setUser } = useUser();
  const [userList, setUserList] = useState([]);
  const [shopList, setShopList] = useState();
  const [serviceList, setServiceList] = useState();
  const [propertyList, setPropertyList] = useState();
  const [offerList, setOfferList] = useState()
  const [eventList, setEventList] = useState()
  const [filteredShopList, setFilteredShopList] = useState([]);
  const [filteredServiceList, setFilteredServiceList] = useState([]);
  const [filteredPropertyList, setFilteredPropertyList] = useState([]);
  const [filteredOffersList, setFilteredOffersList] = useState([])
  const [filteredEventsList, setFilteredEventsList] = useState([])

  // console.log("userList =>", userList.total_rows)

  useEffect(() => {
    UserDataFetch();
  }, []);

  useEffect(() => {
    ShopList();
    ServiceList();
    OfferList();
    PropertyList();
    EventList()
  }, []);

  // useEffects for filter

  useEffect(() => {
    FilteredShopList();
    FilteredServiceList()
    FilteredServiceList();
    FilteredPropertyList();
    FilteredOfferList()
    FilteredEventsList()
  }, []);

  async function UserDataFetch() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    try {
      const userData = await callApi(
        `${baseUrl}/modules/users/get-users`,
        "POST",
        headers
      );
      setUserList(userData);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }

  async function ShopList() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      area_id: user.area_id,
      page_size: 1,
    };

    // setIsLoading(true);
    try {
      const shopListData = await callApi(
        `${baseUrl}/modules/shops/get-Shops`,
        "POST",
        headers,
        payload
      );

      setShopList(shopListData);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
    // finally {
    //   setIsLoading(false);
    // }
  }

  async function ServiceList() {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    const payload = {
      area_id: user?.area_id,
    };
    try {
      const serviceListData = await callApi(
        `${baseUrl}/modules/services/get-services`,
        "POST",
        headers,
        payload
      );
      setServiceList(serviceListData);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }

  async function PropertyList() {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      area_id: user.area_id,
    };
    // setIsLoading(true);
    try {
      const propertyListData = await callApi(
        `${baseUrl}/modules/properties/get-properties`,
        "POST",
        headers,
        payload
      );
      setPropertyList(propertyListData);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
    // finally {
    //   //turn on loader
    //   setIsLoading(false);
    // }
  }

  async function OfferList() {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    const payload = {
      area_id: user?.area_id,
    };
    try {
      const offerListData = await callApi(
        `${baseUrl}/modules/offers/get-offers`,
        "POST",
        headers,
        payload
      );
      setOfferList(offerListData);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }

  async function EventList() {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    const payload = {
      area_id: user?.area_id,
    };
    try {
      const eventListData = await callApi(
        `${baseUrl}/modules/events/get-events`,
        "POST",
        headers,
        payload
      );
      setEventList(eventListData);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }

  async function FilteredShopList() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      area_id: user.area_id,
      filters: {
        conditions: [
          {
            field: "ed.status",
            operator: "like",
            value: "STS_LIVE",
            dataType: "string",
          },
        ],
      },
    };
    // setIsLoading(true);
    try {
      const filteredShopList = await callApi(
        `${baseUrl}/modules/shops/get-Shops`,
        "POST",
        headers,
        payload
      );

      setFilteredShopList(filteredShopList.records);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
    // finally {
    //   setIsLoading(false);
    // }
  }

  async function FilteredServiceList() {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    const payload = {
      area_id: user?.area_id,
      filters: {
        conditions: [
          {
            field: "ed.status",
            operator: "like",
            value: "STS_LIVE",
            dataType: "string",
          },
        ],
      },
    };
    try {
      const filterdServiceList = await callApi(
        `${baseUrl}/modules/services/get-services`,
        "POST",
        headers,
        payload
      );
      setFilteredServiceList(filterdServiceList.records);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }

  async function FilteredPropertyList() {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      area_id: user.area_id,

      filters: {
        conditions: [
          {
            field: "ed.status",
            operator: "like",
            value: "STS_LIVE",
            dataType: "string",
          },
        ],
      },
    };
    // setIsLoading(true);
    try {
      const filterdPropertyList = await callApi(
        `${baseUrl}/modules/properties/get-properties`,
        "POST",
        headers,
        payload
      );
      setFilteredPropertyList(filterdPropertyList.records);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
    // finally {
    //   //turn on loader
    //   setIsLoading(false);
    // }
  }

  async function FilteredOfferList() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      area_id: user.area_id,
      filters: {
        conditions: [
          {
            field: "ed.status",
            operator: "like",
            value: "STS_LIVE",
            dataType: "string",
          },
        ],
      },
    };
    // setIsLoading(true);
    try {
      const filteredOfferList = await callApi(
        `${baseUrl}/modules/offers/get-offers`,
        "POST",
        headers,
        payload
      );

      setFilteredOffersList(filteredOfferList.records);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
    // finally {
    //   setIsLoading(false);
    // }
  }

  async function FilteredEventsList() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      area_id: user.area_id,
      filters: {
        conditions: [
          {
            field: "event_status",
            operator: "like",
            value: "STS_LIVE",
            dataType: "string",
          },
        ],
      },
    };
    // setIsLoading(true);
    try {
      const EventList = await callApi(
        `${baseUrl}/modules/events/get-events`,
        "POST",
        headers,
        payload
      );

      console.log(EventList);

      setFilteredEventsList(EventList?.records);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
    // finally {
    //   setIsLoading(false);
    // }
  }

  return (
    <div>
      <div className="page-content">
        <div className="row">
          <div className="col-6 col-md-6 col-lg-4 ">
            <div className="card">
              <Link to={'/users'}>
                <div className="card-body">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-md-5 col-lg-5 col-xl-4 col-sm-12 d-flex justify-content-center">
                      <div className="icon-card text-center p-3">
                        <i className="bi bi-person-circle display-5 product-icon"></i>
                      </div>
                    </div>
                    <div className="col-md-7 col-lg-7 col-xl-8 col-sm-12 card-content">
                      <h4 className="text-muted font-weight-normal d-flex align-items-center">
                        Users
                      </h4>
                      <h6 className="font-extrabold mb-0">
                        {userList?.total_rows}
                      </h6>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-4 ">
            <div className="card">
              <Link to={'shops'}>
                <div className="card-body">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-md-5 col-lg-5 col-xl-4 col-sm-12 d-flex justify-content-center">
                      <div className="icon-card text-center p-3">
                        <i className="bi bi-bag-fill display-5 product-icon"></i>
                      </div>
                    </div>
                    <div className="col-md-7 col-lg-7 col-xl-8 col-sm-12 card-content">
                      <h4 className="text-muted font-weight-normal d-flex align-items-center">
                        Shops
                      </h4>
                      <h6 className="font-extrabold mb-0">
                        {shopList?.total_rows}
                      </h6>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-4 ">
            <div className="card">
              <Link to={'services'}>
                <div className="card-body">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-md-5 col-lg-5 col-xl-4 d-flex justify-content-center">
                      <div className="icon-card text-center p-3">
                        <i className="bi bi-tools display-5  product-icon"></i>
                      </div>
                    </div>
                    <div className="col-md-7 col-lg-7 col-xl-8 card-content">
                      <h4 className="text-muted font-weight-normal">Services</h4>

                      <h6 className="font-extrabold mb-0">
                        {serviceList?.total_rows}
                      </h6>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-4 ">
            <div className="card">
              <Link to={'/properties'}>
                <div className="card-body">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-md-5 col-lg-5 col-xl-4 d-flex justify-content-center">
                      <div className="icon-card text-center p-3">
                        <i className="bi bi-houses-fill display-5  product-icon"></i>
                      </div>
                    </div>
                    <div className="col-md-7 col-lg-7 col-xl-8 card-content">
                      <h4 className="text-muted font-weight-normal">
                        Properties
                      </h4>
                      <h6 className="font-extrabold mb-0">
                        {propertyList?.total_rows}
                      </h6>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-4 ">
            <div className="card">
              <Link to={'offers'}>
                <div className="card-body">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-md-5 col-lg-5 col-xl-4 d-flex justify-content-center">
                      <div className="icon-card text-center p-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none"><path d="m21.529 10.869-1.52-1.52c-.26-.26-.47-.77-.47-1.13v-2.16c0-.88-.72-1.6-1.6-1.6h-2.15c-.36 0-.87-.21-1.13-.47l-1.52-1.52c-.62-.62-1.64-.62-2.26 0l-1.54 1.52c-.25.26-.76.47-1.13.47h-2.15c-.88 0-1.6.72-1.6 1.6v2.15c0 .36-.21.87-.47 1.13l-1.52 1.52c-.62.62-.62 1.64 0 2.26l1.52 1.52c.26.26.47.77.47 1.13v2.15c0 .88.72 1.6 1.6 1.6h2.15c.36 0 .87.21 1.13.47l1.52 1.52c.62.62 1.64.62 2.26 0l1.52-1.52c.26-.26.77-.47 1.13-.47h2.15c.88 0 1.6-.72 1.6-1.6v-2.15c0-.36.21-.87.47-1.13l1.52-1.52c.65-.61.65-1.63.02-2.25Zm-13.53-1.87c0-.55.45-1 1-1s1 .45 1 1-.44 1-1 1c-.55 0-1-.45-1-1Zm1.53 6.53c-.15.15-.34.22-.53.22s-.38-.07-.53-.22a.754.754 0 0 1 0-1.06l6-6c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-6 6Zm5.47.47c-.56 0-1.01-.45-1.01-1s.45-1 1-1 1 .45 1 1-.44 1-.99 1Z" fill="#9694ff"></path></svg>
                      </div>
                    </div>
                    <div className="col-md-7 col-lg-7 col-xl-8 card-content">
                      <h4 className="text-muted font-weight-normal">
                        Offers
                      </h4>
                      <h6 className="font-extrabold mb-0">
                        {offerList?.total_rows}
                      </h6>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="col-6 col-md-6 col-lg-4 ">
            <div className="card">
              <Link to={'events'}>
                <div className="card-body">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-md-5 col-lg-5 col-xl-4 d-flex justify-content-center">
                      <div className="icon-card text-center p-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"><path d="M22 3.75H2c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h20c.41 0 .75.34.75.75s-.34.75-.75.75ZM11 9.75H2c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h9c.41 0 .75.34.75.75s-.34.75-.75.75ZM8 15.75H2c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h6c.41 0 .75.34.75.75s-.34.75-.75.75ZM6 21.75H2c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4c.41 0 .75.34.75.75s-.34.75-.75.75ZM21.86 7.678c-.59-.45-1.4-.54-2.35-.28l-4.35 1.18c-1.17.32-1.89 1.27-1.89 2.47v6.23c-.42-.24-.91-.39-1.43-.39-1.61 0-2.93 1.31-2.93 2.93 0 1.61 1.31 2.93 2.93 2.93s2.93-1.31 2.93-2.93v-5.65l6.48-1.77v3.43c-.42-.24-.91-.39-1.43-.39-1.61 0-2.93 1.31-2.93 2.93 0 1.61 1.31 2.93 2.93 2.93s2.93-1.31 2.93-2.93v-8.5c0-1.01-.3-1.75-.89-2.19Zm-10.02 13.57a1.43 1.43 0 1 1 0-2.86 1.43 1.43 0 0 1 0 2.86Zm7.98-1.45a1.43 1.43 0 1 1 0-2.86 1.43 1.43 0 0 1 0 2.86Z" fill="#9694ff"></path></svg>
                      </div>
                    </div>
                    <div className="col-md-7 col-lg-7 col-xl-8 card-content">
                      <h4 className="text-muted font-weight-normal">
                        Events
                      </h4>
                      <h6 className="font-extrabold mb-0">
                        {eventList?.total_rows}
                      </h6>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="row">
          {/* shops List */}
          <div className="col-12 col-sm-12 col-md-6">
            {
              filteredShopList.length > 0 ? (
                <section className="shop-section">
                  <h4>Active Shops</h4>
                  <div class="page-heading">
                    <div class="page-title">
                      <section className="section">
                        <div className="card">
                          <div className="card-body">
                            <div className="dataTable-container">
                              <table className="table table-striped" id="table1">
                                <thead>
                                  <tr>
                                    <th>name</th>
                                    <th>Landmark</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {filteredShopList &&
                                    filteredShopList.length > 0 &&
                                    filteredShopList
                                      .slice(0, 10)
                                      .map((data, index) => (
                                        <tr key={index}>
                                          <td>{data.name}</td>
                                          <td>{data.line_1}</td>
                                        </tr>
                                      ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              ) : null
            }

            {/* offers List */}
            {
              filteredOffersList.length > 0 ? (
                <section className="offer-section">
                  <h4>Active Offers</h4>
                  <div class="page-heading">
                    <div class="page-title">
                      <section className="section">
                        <div className="card">
                          <div className="card-body">
                            <div className="dataTable-container">
                              <table className="table table-striped" id="table1">
                                <thead>
                                  <tr>
                                    <th>name</th>
                                    <th>Landmark</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {filteredOffersList &&
                                    filteredOffersList.length > 0 &&
                                    filteredOffersList
                                      .slice(0, 10)
                                      .map((data, index) => (
                                        <tr key={index}>
                                          <td>{data.offer_name}</td>
                                          <td>{data.line_1 ? data?.line_1 : '-'}</td>
                                        </tr>
                                      ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              ) : null
            }

            {/* properties List */}
            {
              filteredPropertyList.length > 0 ? (
                <section className="property-section">

                  <h4>Active Properties</h4>
                  <div class="page-heading">
                    <div class="page-title">
                      <section className="section">
                        <div className="card">
                          <div className="card-body">
                            <div className="dataTable-container">
                              <table className="table table-striped" id="table1">
                                <thead>
                                  <tr>
                                    <th>Email</th>
                                    <th>Landmark</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {filteredPropertyList &&
                                    filteredPropertyList.length > 0 &&
                                    filteredPropertyList
                                      .slice(0, 10)
                                      .map((data, index) => (
                                        <tr key={index}>
                                          <td>{data.email}</td>
                                          <td>{data.line_1}</td>
                                        </tr>
                                      ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>

                </section>
              ) : null
            }
          </div>

          {/* services List */}
          <div className="col-12 col-sm-12 col-md-6">
            {
              filteredServiceList.length > 0 ? (
                <section className="service-section">
                  <h4>Active Services</h4>
                  <div class="page-heading">
                    <div class="page-title">
                      <section className="section">
                        <div className="card">
                          <div className="card-body">
                            <div className="dataTable-container">
                              <table className="table table-striped" id="table1">
                                <thead>
                                  <tr>
                                    <th>name</th>
                                    <th>Landmark</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {filteredServiceList &&
                                    filteredServiceList.length > 0 &&
                                    filteredServiceList
                                      .slice(0, 10)
                                      .map((data, index) => (
                                        <tr key={index}>
                                          <td>{data.name}</td>
                                          <td>{data.line_1}</td>
                                        </tr>
                                      ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              ) : null
            }
            {/* evnets List */}
            {
              filteredEventsList.length > 0 ? (
                <section className="event-section">
                  <h4>Active Events</h4>
                  <div class="page-heading">
                    <div class="page-title">
                      <section className="section">
                        <div className="card">
                          <div className="card-body">
                            <div className="dataTable-container">
                              <table className="table table-striped" id="table1">
                                <thead>
                                  <tr>
                                    <th>name</th>
                                    <th>Landmark</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {filteredEventsList &&
                                    filteredEventsList.length > 0 &&
                                    filteredEventsList
                                      .slice(0, 10)
                                      .map((data, index) => (
                                        <tr key={index}>
                                          <td>{data.event_name}</td>
                                          <td>{data.line_1}</td>
                                        </tr>
                                      ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              ) : null
            }
          </div>



          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
