import React, { useState, useEffect } from "react";
import { TypeList } from "../const/ConstList";
import Message from "../errors/Message";

const ListSelector = ({ selectedList, onChange, errors }) => {
  const [typeList, setTypeList] = useState(TypeList);

  return (
    <>
      <fieldset className="form-group">
        <select
          name="entity_type"
          onChange={onChange}
          value={selectedList}
          className="form-select"
        >
          <option value="" disabled selected>
            Select a type
          </option>
          {typeList.map((item, i) => (
            <option key={i} value={item.list}>
              {item?.label}
            </option>
          ))}
        </select>
        <div>
          <Message type={"error"} errors={errors} fieldName={"entity_type"} />
        </div>
      </fieldset>
    </>
  );
};

export default ListSelector;
