import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUser } from "../utils/utils";

const PrivateRoute = () => {
  const user = useUser();

  if (!user?.user?.token) return <Navigate to="/login" />;
    return <Outlet />;
  
};

export default PrivateRoute;