import React, { useState, useEffect } from "react";
import logo from "../../../assets/logo/logo.png";
import "./Login.css";
import { callApi } from "../../api/Api";
import { useNavigate } from "react-router-dom";
import Message from "../../errors/Message";

function Login() {
  const [userDetails, setUserDetails] = useState({});
  const [userList, setUserList] = useState([]);
  const [errors, setErrors] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    UserDataFetch();
  }, []);

  async function UserDataFetch() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjozMTcsImlhdCI6MTcyNTQyNDYzMywiZXhwIjoxNzI4MDE2NjMzfQ.Aa37MpsRYDlLfQX1OheJbVSgI07uLsLA_2xvnSesQfU",
    };

    try {
      const userData = await callApi(
        `${baseUrl}/modules/users/get-users`,
        "POST",
        headers
      );
      setUserList(userData?.records);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }

  const handleChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(userDetails);

    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    var payload = {
     ...userDetails,
     "client":"ADMIN_CONSOLE"
    };

    try {
      const response = await callApi(
        `${baseUrl}/modules/users/login`,
        "POST",
        headers,
        payload
      );

      console.log("login response - " + JSON.stringify(response));
      console.log("OTP from login request - " + response.OTP);

      // Check if the role_code is "S-ADM"
      if (response.success) {
        navigate(`/otp-capture/${userDetails.email}`);
      } else {
        setErrors(response.errors);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrors([{ message: "An error occurred during login." }]);
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-md-5 mb-5 mt-5 login_col">
        <div id="auth-left" className="mt-4">
          <div className="auth-logo">
            <a href="index.html">
              <img src={logo} alt="Logo" className="hypso_logo"></img>
            </a>
          </div>

          <h1 className="auth-title mt-3 mb-3">Log in.</h1>

          <form action="#" onSubmit={handleSubmit}>
           
            <div className="form-group position-relative has-icon-left mb-4">
              <input
                type="text"
                name="email"
                className="form-control form-control-xl"
                placeholder="Enter your email"
                onChange={handleChange}
                value={userDetails["email"] || ""}
              ></input>

              <div>
                <Message type={"error"} errors={errors} fieldName={"email"} />
                <Message type={"error"} errors={errors} fieldName={"user"} />
                <Message type={"error"} errors={errors} fieldName={"user_id"} />
              </div>

              <div className="form-control-icon">
                <i className="bi bi-person"></i>
              </div>
            </div>
            <button
              className="btn btn-primary btn-block btn-lg shadow-lg "
              type="submit"
            >
              Log in
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
