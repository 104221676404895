export const propertyConfig = {
  Buy: {
    PROPERTY_TYPE_HOUSE: [
      [
        { fieldName: "posted_by", width: "50%" },
        { fieldName: "land_area", width: "50%" },
      ],
      [
        { fieldName: "built_up_area", width: "50%" },
        { fieldName: "direction_facing", width: "50%" },
      ],
      [
        {
          fieldName: "description",
          width: "100%",
        },
      ],
      [
        { fieldName: "price", width: "50%" },
        { fieldName: "total_floors", width: "50%" },
      ],
      [
        { fieldName: "furnish_level", width: "50%" },
        { fieldName: "number_of_bedrooms", width: "50%" },
      ],
      [
        { fieldName: "number_of_bathrooms", width: "50%" },
        { fieldName: "email", width: "50%" },
      ],
      [
        { fieldName: "phone", width: "50%" },
        { fieldName: "property_age", width: "50%" },
      ],
      [
        { fieldName: "approving_agency", width: "50%" },
        { fieldName: "country", width: "50%" },
      ],
      [
        { fieldName: "state", width: "50%" },
        { fieldName: "city", width: "50%" },
      ],
      [
        { fieldName: "line_1", width: "50%" },
        { fieldName: "line_2", width: "50%" },
      ],
      [
        { fieldName: "area", width: "50%" },
        { fieldName: "zip", width: "50%" },
      ],
      [{ fieldName: "google_map", width: "100%" }],
      [
        { fieldName: "image", width: "50%" },
        { fieldName: "gallery_image", width: "50%" },
      ],
    ],
    PROPERTY_TYPE_VILLA: [
      [
        { fieldName: "posted_by", width: "50%" },
        { fieldName: "name_of_community", width: "50%" },
      ],
      [
        { fieldName: "land_area", width: "50%" },
        { fieldName: "built_up_area", width: "50%" },
      ],
      [
        { fieldName: "price", width: "50%" },
        { fieldName: "direction_facing", width: "50%" },
      ],
      [
        {
          fieldName: "description",
          width: "100%",
        },
      ],
      [{ fieldName: "amenity", width: "100%" }],
      [
        { fieldName: "total_floors", width: "50%" },
        { fieldName: "email", width: "50%" },
      ],
      [
        { fieldName: "phone", width: "50%" },
        { fieldName: "number_of_bedrooms", width: "50%" },
      ],
      [
        { fieldName: "number_of_bathrooms", width: "50%" },
        { fieldName: "property_age", width: "50%" },
      ],
      [
        { fieldName: "furnish_level", width: "50%" },
        { fieldName: "maintenance_charges", width: "50%" },
      ],
      [
        { fieldName: "approving_agency", width: "50%" },
        { fieldName: "country", width: "50%" },
      ],
      [
        { fieldName: "state", width: "50%" },
        { fieldName: "city", width: "50%" },
      ],
      [
        { fieldName: "line_1", width: "50%" },
        { fieldName: "line_2", width: "50%" },
      ],
      [
        { fieldName: "area", width: "50%" },
        { fieldName: "zip", width: "50%" },
      ],
      [{ fieldName: "google_map", width: "100%" }],
      [
        { fieldName: "image", width: "50%" },
        { fieldName: "gallery_image", width: "50%" },
      ],
    ],
    PROPERTY_TYPE_APARTMENT: [
      [
        { fieldName: "posted_by", width: "50%" },
        { fieldName: "name_of_community", width: "50%" },
      ],
      [
        { fieldName: "carpet_area", width: "50%" },
        { fieldName: "direction_facing", width: "50%" },
      ],
      [
        {
          fieldName: "description",
          width: "100%",
        },
      ],

      [
        { fieldName: "price", width: "50%" },
        { fieldName: "floor_number", width: "50%" },
      ],
      [
        { fieldName: "total_floors", width: "50%" },
        { fieldName: "email", width: "50%" },
      ],
      [
        { fieldName: "phone", width: "50%" },
        { fieldName: "number_of_bedrooms", width: "50%" },
      ],
      [
        { fieldName: "number_of_bathrooms", width: "50%" },
        { fieldName: "property_age", width: "50%" },
      ],
      [
        { fieldName: "furnish_level", width: "50%" },
        { fieldName: "maintenance_charges", width: "50%" },
      ],
      [
        {
          fieldName: "pet_allowed",
          width: "50%",
        },
        { fieldName: "country", width: "50%" },
      ],
      [
        { fieldName: "state", width: "50%" },
        { fieldName: "city", width: "50%" },
      ],
      [
        { fieldName: "line_1", width: "50%" },
        { fieldName: "line_2", width: "50%" },
      ],
      [
        { fieldName: "area", width: "50%" },
        { fieldName: "zip", width: "50%" },
      ],
      [{ fieldName: "google_map", width: "100%" }],
      [
        { fieldName: "image", width: "50%" },
        { fieldName: "gallery_image", width: "50%" },
      ],
    ],
    PROPERTY_TYPE_LAND: [
      [
        { fieldName: "posted_by", width: "50%" },
        { fieldName: "land_area", width: "50%" },
      ],
      [
        { fieldName: "price", width: "50%" },
        { fieldName: "direction_facing", width: "50%" },
      ],
      [
        { fieldName: "email", width: "50%" },
        { fieldName: "phone", width: "50%" },
      ],
      [
        {
          fieldName: "description",
          width: "100%",
        },
      ],
      [
        { fieldName: "maintenance_charges", width: "50%" },
        { fieldName: "suitable_for_business", width: "50%" },
      ],
      [
        { fieldName: "approving_agency", width: "50%" },
        { fieldName: "country", width: "50%" },
      ],
      [
        { fieldName: "state", width: "50%" },
        { fieldName: "city", width: "50%" },
      ],
      [
        { fieldName: "line_1", width: "50%" },
        { fieldName: "line_2", width: "50%" },
      ],
      [
        { fieldName: "area", width: "50%" },
        { fieldName: "zip", width: "50%" },
      ],
      [{ fieldName: "google_map", width: "100%" }],
      [
        { fieldName: "image", width: "50%" },
        { fieldName: "gallery_image", width: "50%" },
      ],
    ],
    PROPERTY_TYPE_SHOP: [
      [
        { fieldName: "posted_by", width: "50%" },
        { fieldName: "carpet_area", width: "50%" },
      ],
      [
        { fieldName: "price", width: "50%" },
        { fieldName: "floor_number", width: "50%" },
      ],
      [
        { fieldName: "property_age", width: "50%" },
        { fieldName: "email", width: "50%" },
      ],
      [
        {
          fieldName: "description",
          width: "100%",
        },
      ],
      [
        { fieldName: "phone", width: "50%" },
        { fieldName: "car_parking", width: "50%" },
      ],
      [
        { fieldName: "suitable_for_business", width: "50%" },
        { fieldName: "country", width: "50%" },
      ],
      [
        { fieldName: "state", width: "50%" },
        { fieldName: "city", width: "50%" },
      ],
      [
        { fieldName: "line_1", width: "50%" },
        { fieldName: "line_2", width: "50%" },
      ],
      [
        { fieldName: "area", width: "50%" },
        { fieldName: "zip", width: "50%" },
      ],
      [{ fieldName: "google_map", width: "100%" }],
      [
        { fieldName: "image", width: "50%" },
        { fieldName: "gallery_image", width: "50%" },
      ],
    ],
  },
  Rent: {
    PROPERTY_TYPE_HOUSE: [
      [
        { fieldName: "posted_by", width: "50%" },
        { fieldName: "land_area", width: "50%" },
      ],
      [
        { fieldName: "built_up_area", width: "50%" },
        { fieldName: "direction_facing", width: "50%" },
      ],
      [
        {
          fieldName: "description",
          width: "100%",
        },
      ],
      [
        { fieldName: "rent", width: "50%" },
        { fieldName: "available_from", width: "50%" },
      ],
      [
        { fieldName: "number_of_bedrooms", width: "50%" },
        { fieldName: "number_of_bathrooms", width: "50%" },
      ],
      [
        { fieldName: "email", width: "50%" },
        { fieldName: "phone", width: "50%" },
      ],
      [
        { fieldName: "furnish_level", width: "50%" },
        { fieldName: "total_floors", width: "50%" },
      ],
      [
        { fieldName: "country", width: "50%" },
        { fieldName: "state", width: "50%" },
      ],
      [
        { fieldName: "city", width: "50%" },
        { fieldName: "line_1", width: "50%" },
      ],
      [
        { fieldName: "line_2", width: "50%" },
        { fieldName: "area", width: "50%" },
      ],
      [
        { fieldName: "zip", width: "50%" },
        { fieldName: "image", width: "50%" },
      ],

      [{ fieldName: "gallery_image", width: "100%" }],
      [{ fieldName: "google_map", width: "100%" }],
    ],
    PROPERTY_TYPE_VILLA: [
      [
        { fieldName: "posted_by", width: "50%" },
        { fieldName: "name_of_community", width: "50%" },
      ],
      [
        { fieldName: "land_area", width: "50%" },
        { fieldName: "built_up_area", width: "50%" },
      ],
      [
        {
          fieldName: "description",
          width: "100%",
        },
      ],
      [{ fieldName: "amenity", width: "100%" }],
      [
        { fieldName: "rent", width: "50%" },
        { fieldName: "available_from", width: "50%" },
      ],
      [
        { fieldName: "total_floors", width: "50%" },
        { fieldName: "email", width: "50%" },
      ],
      [
        { fieldName: "phone", width: "50%" },
        { fieldName: "number_of_bedrooms", width: "50%" },
      ],
      [
        { fieldName: "number_of_bathrooms", width: "50%" },
        { fieldName: "direction_facing", width: "50%" },
      ],
      [
        { fieldName: "furnish_level", width: "50%" },
        { fieldName: "maintenance_charges", width: "50%" },
      ],
      [
        { fieldName: "approving_agency", width: "50%" },
        { fieldName: "country", width: "50%" },
      ],
      [
        { fieldName: "state", width: "50%" },
        { fieldName: "city", width: "50%" },
      ],
      [
        { fieldName: "line_1", width: "50%" },
        { fieldName: "line_2", width: "50%" },
      ],
      [
        { fieldName: "area", width: "50%" },
        { fieldName: "zip", width: "50%" },
      ],
      [{ fieldName: "google_map", width: "100%" }],
      [
        { fieldName: "image", width: "50%" },
        { fieldName: "gallery_image", width: "50%" },
      ],
    ],
    PROPERTY_TYPE_APARTMENT: [
      [
        { fieldName: "posted_by", width: "50%" },
        { fieldName: "name_of_community", width: "50%" },
      ],
      [
        { fieldName: "carpet_area", width: "50%" },
        { fieldName: "direction_facing", width: "50%" },
      ],
      [
        {
          fieldName: "description",
          width: "100%",
        },
      ],
      [
        { fieldName: "rent", width: "50%" },
        { fieldName: "available_from", width: "50%" },
      ],
      [
        { fieldName: "floor_number", width: "50%" },
        { fieldName: "total_floors", width: "50%" },
      ],
      [
        { fieldName: "email", width: "50%" },
        { fieldName: "phone", width: "50%" },
      ],
      [
        { fieldName: "number_of_bedrooms", width: "50%" },
        { fieldName: "number_of_bathrooms", width: "50%" },
      ],
      [
        { fieldName: "car_parking", width: "50%" },
        { fieldName: "furnish_level", width: "50%" },
      ],
      [
        { fieldName: "maintenance_charges", width: "50%" },
        {
          fieldName: "pet_allowed",
          width: "50%",
        },
      ],
      [
        { fieldName: "country", width: "50%" },
        { fieldName: "state", width: "50%" },
      ],
      [
        { fieldName: "city", width: "50%" },
        { fieldName: "line_1", width: "50%" },
      ],
      [
        { fieldName: "line_2", width: "50%" },
        { fieldName: "area", width: "50%" },
      ],
      [
        { fieldName: "zip", width: "50%" },
        { fieldName: "image", width: "50%" },
      ],

      [{ fieldName: "gallery_image", width: "100%" }],
      [{ fieldName: "google_map", width: "100%" }],
    ],
    PROPERTY_TYPE_LAND: [
      [
        { fieldName: "posted_by", width: "50%" },
        { fieldName: "land_area", width: "50%" },
      ],
      [
        { fieldName: "rent", width: "50%" },
        { fieldName: "available_from", width: "50%" },
      ],
      [
        { fieldName: "email", width: "50%" },
        { fieldName: "phone", width: "50%" },
      ],
      [
        {
          fieldName: "description",
          width: "100%",
        },
      ],
      [
        { fieldName: "direction_facing", width: "50%" },
        { fieldName: "maintenance_charges", width: "50%" },
      ],
      [
        { fieldName: "suitable_for_business", width: "50%" },
        { fieldName: "country", width: "50%" },
      ],
      [
        { fieldName: "state", width: "50%" },
        { fieldName: "city", width: "50%" },
      ],
      [
        { fieldName: "line_1", width: "50%" },
        { fieldName: "line_2", width: "50%" },
      ],
      [
        { fieldName: "area", width: "50%" },
        { fieldName: "zip", width: "50%" },
      ],
      [{ fieldName: "google_map", width: "100%" }],
      [
        { fieldName: "image", width: "50%" },
        { fieldName: "gallery_image", width: "50%" },
      ],
    ],
    PROPERTY_TYPE_SHOP: [
      [
        { fieldName: "posted_by", width: "50%" },
        { fieldName: "carpet_area", width: "50%" },
      ],
      [
        { fieldName: "rent", width: "50%" },
        { fieldName: "available_from", width: "50%" },
      ],
      [
        {
          fieldName: "description",
          width: "100%",
        },
      ],
      [
        { fieldName: "floor_number", width: "50%" },
        { fieldName: "email", width: "50%" },
      ],
      [
        { fieldName: "phone", width: "50%" },
        { fieldName: "car_parking", width: "50%" },
      ],
      [
        { fieldName: "suitable_for_business", width: "50%" },
        { fieldName: "country", width: "50%" },
      ],
      [
        { fieldName: "state", width: "50%" },
        { fieldName: "city", width: "50%" },
      ],
      [
        { fieldName: "line_1", width: "50%" },
        { fieldName: "line_2", width: "50%" },
      ],
      [
        { fieldName: "area", width: "50%" },
        { fieldName: "zip", width: "50%" },
      ],
      [{ fieldName: "google_map", width: "100%" }],
      [
        { fieldName: "image", width: "50%" },
        { fieldName: "gallery_image", width: "50%" },
      ],
    ],
  },
  Pg: [
    [
      { fieldName: "name_of_pg", width: "50%" },
      { fieldName: "for_gender", width: "50%" },
    ],
    [
      { fieldName: "persons_per_room", width: "50%" },
      { fieldName: "rent", width: "50%" },
    ],
    [
      {
        fieldName: "description",
        width: "100%",
      },
    ],
    [{ fieldName: "amenity", width: "100%" }],
    [
      { fieldName: "available_from", width: "50%" },
      { fieldName: "email", width: "50%" },
    ],
    [
      { fieldName: "phone", width: "50%" },
      { fieldName: "food_available", width: "50%" },
    ],
    [
      { fieldName: "self_cooking", width: "50%" },
      { fieldName: "car_parking", width: "50%" },
    ],

    [
      { fieldName: "country", width: "50%" },
      { fieldName: "state", width: "50%" },
    ],
    [
      { fieldName: "city", width: "50%" },
      { fieldName: "line_1", width: "50%" },
    ],
    [
      { fieldName: "line_2", width: "50%" },
      { fieldName: "area", width: "50%" },
    ],
    [
      { fieldName: "zip", width: "50%" },
      { fieldName: "image", width: "50%" },
    ],

    [{ fieldName: "gallery_image", width: "100%" }],
    [{ fieldName: "google_map", width: "100%" }],
  ],
  Room_sharing: [
    [
      { fieldName: "for_gender", width: "50%" },
      { fieldName: "persons_per_room", width: "50%" },
    ],

    [
      { fieldName: "rent", label: "Rent Per Person ", width: "50%" },
      { fieldName: "available_from", width: "50%" },
    ],
    [
      {
        fieldName: "description",
        width: "100%",
      },
    ],
    [
      { fieldName: "number_of_bedrooms", width: "50%" },
      { fieldName: "number_of_bathrooms", width: "50%" },
    ],
    [
      { fieldName: "phone", width: "50%" },
      { fieldName: "country", width: "50%" },
    ],
    [
      { fieldName: "state", width: "50%" },
      { fieldName: "city", width: "50%" },
    ],
    [
      { fieldName: "line_1", width: "50%" },
      { fieldName: "line_2", width: "50%" },
    ],
    [
      { fieldName: "area", width: "50%" },
      { fieldName: "zip", width: "50%" },
    ],

    [
      { fieldName: "image", width: "50%" },
      { fieldName: "gallery_image", width: "50%" },
    ],

    [{ fieldName: "google_map", width: "100%" }],
  ],
};
