import React, { useState, useEffect, useContext } from "react"
import { callApi } from "../api/Api"
import { useUser } from "../../utils/utils"

function ServiceCategorySelector({ selectedCategory, onChange }) {
  
  const [serviceCategory, setServiceCategory] = useState([])
  const { user, setUser } = useUser()

  async function getServiceCategory() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    }

    // var payload = {
    //   entity_type: "ENT_SHOP",
    // }
    try {
      const serviceCategoryData = await callApi(
        ` ${baseUrl}/modules/categories/get-global-categories`,
        "POST",
        headers
      )
      setServiceCategory(serviceCategoryData.category_tree)
    } catch (error) {
      console.error("There was an error fetching the categories!", error)
    }
  }

  useEffect(() => {
    getServiceCategory()
  }, [])

  return (
    <div>
      <fieldset className="form-group">
        <select
          name="category_id"
          className="form-select"
          onChange={onChange}
          value={selectedCategory}
        >
          <option value="" disabled selected>
            Select an service category
          </option>
          {serviceCategory?.map((serviceType, i) => (
            <option key={i} value={serviceType.category_id}>
              {serviceType.name}
            </option>
          ))}
        </select>
      </fieldset>
    </div>
  )
}

export default ServiceCategorySelector
