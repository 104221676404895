
import React from "react"

const SidebarDropdown = ({ submenus }) => {
  return (
    <ul className="dropdown">
      {submenus?.map((subItem, index) => {
        return (
          <li key={index} className="dropdown-item ">
            <a href={subItem.url} className="dropdown-link">
              {subItem.title}
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export default SidebarDropdown
