import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import "./assets/app.css";
import AddShop from "./components/shop/AddShop/AddShop";
import EditShop from "./components/shop/EditShop/EditShop";
import Login from "./components/auth/login/Login";
import ServiceList from "./components/Service/serviceList/ServiceListings";
import AddService from "./components/Service/AddService/AddService";
import OTPCapture from "./components/auth/otpCapture/OTPCapture";
import { PendingCountContext, UserContext } from "./contexts/UserContext";
import EditService from "./components/Service/EditService/EditService";
import Logout from "./components/auth/logout/Logout";
import PageNotFound from "./components/pageNotFound/PageNotFound";
import AddBanner from "./components/banner/addBanner/AddBanner";
import BannerList from "./components/banner/bannerList/BannerList";
import EditBanner from "./components/banner/bannerEdit/EditBanner";
import PropertyList from "./components/realEstate/propertyList/PropertyListings";
import PropertyForm from "./components/realEstate/propertyForm/PropertyForm";
import ShopListings from "./components/shop/shopList/ShopListings";
import Dashboard from "./components/dashboard/Dashboard";
import UserList from "./components/user/userList/UserList";
import EditUser from "./components/user/editUser/EditUser";
import AddUser from "./components/user/addUser/AddUser";
import Layout from "./components/layout/Layout";
import AddOffers from "./components/offers/AddOffers.jsx";
import AddEvents from "./components/events/AddEvents.jsx";
import OffersListings from "./components/offers/offers-list/OffersListings.jsx";
import EditOffer from "./components/offers/offers-list/editOffer/EditOffer.jsx";
import EventListings from "./components/events/event-detail/EventListings.jsx";
import EditEvent from "./components/events/edit-event/EditEvent.jsx";
import ReviewListings from "./components/review/reviewList/ReviewListings.jsx";
import EditReview from "./components/review/editReview/EditReview.jsx";
import UserReviewListings from "./components/review/userReviewList/UserReviewListings.jsx";
import EditUserReview from "./components/review/editReview/EditUserReview.jsx";
import AddCategory from "./components/category/addCategory/AddCategory.jsx";
import CategoryListings from "./components/category/categoryList/CategoryListings.jsx";
import EditCategory from "./components/category/editCategory/EditCategory.jsx";
import PrivateRoute from "./router/route.js";

function App() {
  const [user, setUser] = useState();
  const [pendingCount, setPendingCount] = useState()

  return (
    <BrowserRouter>
      <div id="main">
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
          <PendingCountContext.Provider value={{ pendingCount: pendingCount, setPendingCount: setPendingCount }}>
            <Layout>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<PageNotFound />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/otp-capture/:email" element={<OTPCapture />} />
                <Route element={<PrivateRoute />}>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/shops" element={<ShopListings />} />
                  <Route path="/shop-new" element={<AddShop />} />
                  <Route path="/shop-edit/:id" element={<EditShop />} />
                  <Route path="/services" element={<ServiceList />} />
                  <Route path="/service-new" element={<AddService />} />
                  <Route path="/service-edit/:id" element={<EditService />} />
                  <Route path="/properties" element={<PropertyList />} />
                  <Route path="/property-new" element={<PropertyForm />} />
                  <Route path="/property-edit/:id" element={<PropertyForm />} />
                  <Route path="/offers" element={<OffersListings />} />
                  <Route path="/offer-edit/:id" element={<EditOffer />} />
                  <Route path="/banner-new" element={<AddBanner />} />
                  <Route path="/banner-list" element={<BannerList />} />
                  <Route path="/banner-edit" element={<EditBanner />} />
                  <Route path="/users" element={<UserList />} />
                  <Route path="/user-edit/:id" element={<EditUser />} />
                  <Route path="/user-new" element={<AddUser />} />
                  <Route path="/offer-new" element={<AddOffers />} />
                  <Route path="/event-new" element={<AddEvents />} />
                  <Route path="/events" element={<EventListings />} />
                  <Route path="/event-edit/:id" element={<EditEvent />} />
                  <Route path="/reviews" element={<ReviewListings />} />
                  <Route path="/user-review/:id" element={<UserReviewListings />} />
                  <Route path="/review-edit/:id" element={<EditReview />} />
                  <Route path="/review-edit-user/:id" element={<EditUserReview />} />
                  <Route path="/category-new" element={<AddCategory />} />
                  <Route path="/categories" element={<CategoryListings />} />
                  <Route path="/category-edit/:id" element={<EditCategory />} />
                </Route>
              </Routes>
            </Layout>
          </PendingCountContext.Provider>

        </UserContext.Provider>
      </div>
    </BrowserRouter>
  );
}

export default App;
