import React, { useRef, useState } from 'react'
import Message from '../errors/Message';
import StateSelector from '../selector/StateSelector';
import CitySelector from '../selector/CitySelector';
import AreaSelector from '../selector/AreaSelector';
import MapComponent from '../mapSelector/MapComponent';
import { defaultLocation } from '../../utils/locationUtils';
import SingleImageSelector from '../singleImageSelector/SingleImageSelector';
import AddTags from '../Tags/AddTags/Addtags';
import './AddEvents.css'
import { callApi } from '../api/Api';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../utils/utils';
import MultiImageSelector from '../multiImageSelector/MultiImageSelector';
import Swal from 'sweetalert2';



const AddEvents = () => {

    const { user } = useUser()
    const navigate = useNavigate();
    const inputFile = useRef(null);
    const [formValues, setFormValues] = useState([]);
    const [featuredImage, setFeaturedImage] = useState("");
    const [addedTags, setAddedTags] = useState([]);
    const [removedTags, setRemovedTags] = useState([]);
    const [errors, setErrors] = useState([]);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState("");
    const [showLatitude, setShowLatitude] = useState(
        defaultLocation[0]?.latitude || 0
    );
    const [showLongitude, setShowLongitude] = useState(
        defaultLocation[0]?.longitude || 0
    );
    const [selectedImages, setSelectedImages] = useState([]);
    const image_id = selectedImages?.map((selectImage) => selectImage.image_id);

    // Toast for Shop Add
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        },
    });

    // handleChange for featuredImages
    const onChangeFeaturedImage = (newlySelectedImage) => {
        // setFeaturedImage(newlySelectedImage.image_id)
        setFeaturedImage(newlySelectedImage);
    };

    const handleChange = (event) => {
        setFormValues({ ...formValues, [event.target.name]: event.target.value });
    };

    const handleSubmitEvent = async (e) => {
        e.preventDefault()
        var headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token,
        };
        var payload = {
            ...formValues,
           
            area_id: user?.area_id,
            tags: {
                added_tags: addedTags,
            },
            address: {
                line_1: formValues.line_1,
                line_2: formValues.line_2,
                city: formValues.city,
                state: formValues.state,
                zip: formValues.zip,
                country: "IND",
                area: formValues.area,
            },
            featured_image: featuredImage.image_id,
            images: { images: image_id },
            geo_location: {
                latitude: latitude,
                longitude: longitude,
            },
            "entity_type": "ENT_EVENT",
            social_platforms: {
                facebook: formValues?.facebook,
                instagram: formValues?.instagram,
                twitter: formValues?.twitter
            },
        }
        console.log("payload", payload);

        var baseUrl;
        var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
        try {
            const response = await callApi(`${baseUrl}/modules/events/create`, "POST", headers, payload)
            console.log("response", response);
            if (response.success) {

                Toast.fire({
                    icon: "success",
                    title: "Data saved successfully",
                });
                navigate("/events");
            }
            else {
                setErrors(response.errors);
                console.error("Error: Response indicates failure", response);
                Toast.fire({
                    icon: "error",
                    title: response.message || "Failed to save data",
                });
            }
        } catch (error) {
            console.log(error);

        }
    }


    return (
        <>
            <div className="page-heading">
                <div className="page-title">
                    <div className="row">
                        <div className="col-12 col-md-6 order-md-1 order-last pb-4">
                            <h3>Add Event</h3>
                        </div>
                        <div className="col-12 col-md-6 order-md-2 order-first">
                            <nav
                                aria-label="breadcrumb"
                                className="breadcrumb-header float-start float-lg-end"
                            ></nav>
                        </div>
                    </div>
                </div>
            </div>

            <section class="section">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <ul class="nav nav-tabs event-nav-tabs mb-3" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab"
                                            aria-controls="home" aria-selected="true">Basic Information</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="location-tab" data-bs-toggle="tab" href="#location" role="tab"
                                            aria-controls="location" aria-selected="false">Location</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="contact-tab" data-bs-toggle="tab" href="#contact" role="tab"
                                            aria-controls="contact" aria-selected="false">Contact</a>
                                    </li>

                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="timing-tab" data-bs-toggle="tab" href="#timing" role="tab"
                                            aria-controls="timing" aria-selected="false">Timing</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="pricing-tab" data-bs-toggle="tab" href="#pricing" role="tab"
                                            aria-controls="pricing" aria-selected="false">Pricing</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="guide-tab" data-bs-toggle="tab" href="#guide" role="tab"
                                            aria-controls="guide" aria-selected="false">Event Guide</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="social-tab" data-bs-toggle="tab" href="#social" role="tab"
                                            aria-controls="social" aria-selected="false">Social Platform</a>
                                    </li>
                                </ul>

                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        {/* Basic Information Section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Basic Information</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Title</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_name"
                                                                                    value={formValues?.event_name || ''}
                                                                                    placeholder="Enter the title of your event"
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_name"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Add Tags
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <AddTags
                                                                                    addedTags={addedTags}
                                                                                    setAddedTags={setAddedTags}
                                                                                    removedTags={removedTags}
                                                                                    setRemovedTags={setRemovedTags}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Description
                                                                                </label>
                                                                            </div>

                                                                            <div className="col-md-8 form-group">
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    id="exampleFormControlTextarea1"
                                                                                    rows="3"
                                                                                    name="description"
                                                                                    value={formValues?.description || ''}
                                                                                    placeholder="Enter a description of your offer"
                                                                                    required
                                                                                    onChange={handleChange}
                                                                                ></textarea>
                                                                            </div>



                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Email</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="email"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="email"
                                                                                    value={formValues?.email || ''}
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div >
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card">
                                                        <div className="card-header py-0">
                                                            <h4 className="card-title">Upload Images</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Upload Featured Image
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 col-lg-8 col-xs-8 form-group">
                                                                                <fieldset>
                                                                                    <SingleImageSelector
                                                                                        imageToShow={featuredImage}
                                                                                        onChangeImage={onChangeFeaturedImage}
                                                                                    />
                                                                                </fieldset>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Upload Cover Image
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 col-lg-8 col-xs-8 form-group">
                                                                                <fieldset>
                                                                                    <MultiImageSelector
                                                                                        selectedImages={selectedImages}
                                                                                        setSelectedImages={setSelectedImages}
                                                                                    />
                                                                                </fieldset>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-10 col-lg-10 px-4 py-0">
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        onClick={handleSubmitEvent}
                                                        className="btn btn-primary me-1 mb-1"
                                                    >
                                                        Save
                                                    </button>
                                                </div>

                                            </div>
                                        </section >
                                        
                                    </div>
                                    <div class="tab-pane fade" id="location" role="tabpanel" aria-labelledby="location-tab">
                                        {/* Location Section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Listing Location</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            {/* <div className="col-md-4">
                            <label htmlFor="first-name-horizontal">
                              Country / Region
                            </label>
                          </div>
                          <div className="col-md-8 form-group">
                            <input
                              type="text"
                              id="first-name-horizontal"
                              className="form-control"
                              name="country"
                              value={formValues["country"] || ""}
                              onChange={handleChange}
                              placeholder="Select Country"
                              required
                            ></input>
                            <div>
                              <Message
                                type={"error"}
                                errors={errors}
                                fieldName={"country"}
                              />
                            </div>
                          </div> */}<div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Venue
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="venue"
                                                                                    value={formValues["venue"] || ""}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter the venue of your event"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"venue"}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">State</label>
                                                                            </div>
                                                                            <div className="col-md-8 ">
                                                                                <StateSelector
                                                                                    selectedState={formValues.state}
                                                                                    onChange={handleChange}
                                                                                    errors={errors}
                                                                                />
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">City</label>
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                <CitySelector
                                                                                    selectedCity={formValues.city}
                                                                                    onChange={handleChange}
                                                                                    errors={errors}
                                                                                />
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    PIN Code
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="zip"
                                                                                    value={formValues["zip"] || ""}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Select PIN code"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"zip"}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Street
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="line_1"
                                                                                    value={formValues["line_1"] || ""}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter the address of your event"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"line_1"}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Nearby Landmark
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="line_2"
                                                                                    value={formValues["line_2"] || ""}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter landmarks near your your event"
                                                                                    required
                                                                                ></input>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Area</label>
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                <AreaSelector
                                                                                    selectedArea={formValues.area}
                                                                                    onChange={handleChange}
                                                                                    errors={errors}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section class="section">
                                            <div class="row">
                                                <div class="col-md-10 col-lg-10">
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h5 class="card-title">Location</h5>
                                                        </div>
                                                        <MapComponent
                                                            setLatitude={setLatitude}
                                                            setLongitude={setLongitude}
                                                            showLatitude={showLatitude}
                                                            showLongitude={showLongitude}
                                                            setShowLatitude={setShowLatitude}
                                                            setShowLongitude={setShowLongitude}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10 d-flex justify-content-end">
                                                    <div className="card mb-0">
                                                        <div className="card-body p-0">
                                                            <button
                                                                onClick={handleSubmitEvent}
                                                                className="btn btn-primary me-1 mb-1"
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                        {/* Contact Section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Organizer Contact</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Organizer Name</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_organizer_name"
                                                                                    onChange={handleChange}
                                                                                    placeholder=""
                                                                                    value={formValues?.event_organizer_name || ''}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_organizer_name"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Organizer Contact</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_organizer_contact"
                                                                                    value={formValues?.event_organizer_contact || ''}
                                                                                    placeholder=""
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_organizer_contact"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div >
                                            <div className="col-md-10 col-lg-10 px-4 py-0">
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        onClick={handleSubmitEvent}
                                                        className="btn btn-primary me-1 mb-1"
                                                    >
                                                        Save
                                                    </button>
                                                </div>

                                            </div>
                                        </section >
                                    </div>
                                    <div class="tab-pane fade" id="timing" role="tabpanel" aria-labelledby="timing-tab">
                                        {/* Duration and Timing Section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Duration and Timing</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Event Start Date</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="date"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_start_date"
                                                                                    value={formValues?.event_start_date || ''}
                                                                                    placeholder=""
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_start_date"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Event Start Time</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="time"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_start_time"
                                                                                    value={formValues?.event_start_time || ''}
                                                                                    placeholder=""
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_start_time"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Event End Date</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="date"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_end_date"
                                                                                    value={formValues?.event_end_date || ''}
                                                                                    placeholder=""
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_end_date"}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Event End Time</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="time"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_end_time"
                                                                                    onChange={handleChange}
                                                                                    placeholder=""
                                                                                    value={formValues?.event_end_time || ''}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_end_time"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Registration Deadline</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="date"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_registration_end_date"
                                                                                    value={formValues?.event_registration_end_date || ''}
                                                                                    placeholder=""
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_registration_end_date"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div >
                                            <div className="col-md-10 col-lg-10 px-4 py-0">
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        onClick={handleSubmitEvent}
                                                        className="btn btn-primary me-1 mb-1"
                                                    >
                                                        Save
                                                    </button>
                                                </div>

                                            </div>
                                        </section >
                                    </div>
                                    <div class="tab-pane fade" id="pricing" role="tabpanel" aria-labelledby="pricing-tab">
                                        {/* Pricing Section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Pricing and Capacity</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Event Ticket Price</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="event_ticket_price"
                                                                                    value={formValues?.event_ticket_price || ""}
                                                                                    placeholder="Enter the ticket price"
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"event_ticket_price"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Discounted Price</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="discounted_price"
                                                                                    value={formValues?.discounted_price || ''}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter the discount price"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    {/* <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"name"}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>



                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Total Capacity
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="total_capacity"
                                                                                    value={formValues?.total_capacity || ""}
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                   
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Terms & Conditions</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    id="exampleFormControlTextarea1"
                                                                                    rows="3"
                                                                                    name="terms_and_conditions"
                                                                                    value={formValues?.terms_and_conditions || ''}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter the terms and conditions of your event"
                                                                                    required
                                                                                ></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div >
                                            <div className="col-md-10 col-lg-10 px-4 py-0">
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        onClick={handleSubmitEvent}
                                                        className="btn btn-primary me-1 mb-1"
                                                    >
                                                        Save
                                                    </button>
                                                </div>

                                            </div>
                                        </section >
                                    </div>
                                    <div class="tab-pane fade" id="guide" role="tabpanel" aria-labelledby="guide-tab">
                                        {/* Event Guide Section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Event Guide</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Choose Language</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <fieldset className="form-group">
                                                                                    <select
                                                                                        name="language"
                                                                                        onChange={handleChange}
                                                                                        value={formValues?.language || ""}
                                                                                        className="form-select"
                                                                                    >
                                                                                        <option value="" disabled selected>
                                                                                            Select language
                                                                                        </option>
                                                                                        <option value="LAN_TAM">Tamil</option>
                                                                                        <option value="LAN_ENG">English</option>
                                                                                        <option value="LAN_TELU">Telugu</option>
                                                                                        <option value="LAN_MAL">Malayalam</option>
                                                                                        <option value="LAN_HIN">Hindi</option>
                                                                                    </select>
                                                                                    <div>

                                                                                    </div>
                                                                                </fieldset>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Age restriction
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="number"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="age"
                                                                                    value={formValues?.age || ""}
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                   
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Event Mode</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <fieldset className="form-group">
                                                                                    <select
                                                                                        name="event_mode"
                                                                                        onChange={handleChange}
                                                                                        value={formValues?.event_mode || ""}
                                                                                        className="form-select"
                                                                                    >
                                                                                        <option value="" disabled selected>
                                                                                            Select mode
                                                                                        </option>
                                                                                        <option value="EVT_MOD_LIVE">Live Performance</option>
                                                                                        <option value="EVT_MOD_ONLINE">Online Streaming</option>
                                                                                        <option value="EVT_MOD_HYBRID">Hybrid</option>
                                                                                        <option value="EVT_MOD_TV_TELECAST">Tv Telecast</option>
                                                                                    </select>
                                                                                    <div>

                                                                                    </div>
                                                                                </fieldset>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Additional Information
                                                                                </label>
                                                                            </div>

                                                                            <div className="col-md-8 form-group">
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    id="exampleFormControlTextarea1"
                                                                                    rows="3"
                                                                                    name="additional_info"
                                                                                    value={formValues?.additional_info || ""}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter the additonal information of your event"
                                                                                    required
                                                                                ></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-10 col-lg-10 px-4 py-0">
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        onClick={handleSubmitEvent}
                                                        className="btn btn-primary me-1 mb-1"
                                                    >
                                                        Save
                                                    </button>
                                                </div>

                                            </div>
                                        </section >
                                    </div>
                                    <div class="tab-pane fade" id="social" role="tabpanel" aria-labelledby="social-tab">
                                        {/* Social Platform section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Social Platform</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Facebook</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="facebook"
                                                                                    value={formValues?.facebook || ""}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter your facebook event url"
                                                                                    required
                                                                                ></input>
                                                                                <div>

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Instagram</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="instagram"
                                                                                    onChange={handleChange}
                                                                                    value={formValues?.instagram || ""}
                                                                                    placeholder="Enter your Instagram event url"
                                                                                    required
                                                                                ></input>
                                                                                <div>

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Twitter</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="twitter"
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter your Twitter event url"
                                                                                    value={formValues?.twitter || ""}
                                                                                ></input>
                                                                                <div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </section >
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10 d-flex justify-content-end">
                                                    <div className="card mb-0">
                                                        <div className="card-body">
                                                            <button
                                                                onClick={handleSubmitEvent}
                                                                className="btn btn-primary me-1 mb-1"
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default AddEvents
