import React, { useState, useEffect } from "react";
import dateUtils from "../../../utils/dateUtils";
import { Link } from "react-router-dom";
import { callApi } from "../../api/Api";
import Swal from "sweetalert2";
import { useCount, useUser } from "../../../utils/utils";
import Pagination from "../../pagination/Pagination";
import loading_gif from "../../../assets/gif/loading_gif.gif";
import profile_img from '../../../assets/jpg/profile_icon.jpg'

const ReviewDataTable = () => {

    const [status, setStatus] = useState("STS_LIVE");
    const [reviewListData, setReviewListData] = useState([])
    const [userListData, setUserListData] = useState([])
    const [showDataTable, setShowDataTable] = useState(false)
    const { user } = useUser();
    const [reviewStatus, setReviewStatus] = useState("reviews/get-reviews")
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(null);
    const { pendingCount, setPendingCount } = useCount()
    const [isLoading, setIsLoading] = useState(false);

    const columnData = [
        "S.No",
        "Title",
        "Ratings",
        "Created Date",
        "Actions"
    ];

    const userColumnData = [
        "S.No",
        "Profile",
        "Firstname",
        "Lastname",
        "Email",
        "Phone",
        "Action"
    ]

    useEffect(() => {
        ReviewList();
    }, [currentPage, reviewStatus, status]);

    async function ReviewList() {
        const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token,
        };

        setIsLoading(true);
        if (reviewStatus == "reviews/get-reviews") {
            const payload = {
                current_page: currentPage,
                area_id: user?.area_id,
                "filters": {
                    "conditions": [
                        {
                            "field": "status",
                            "operator": "like",
                            "value": status,
                            "dataType": "string"
                        }
                    ]
                }
            };
            const pending_payload = {
                current_page: currentPage,
                area_id: user?.area_id,
                "filters": {
                    "conditions": [
                        {
                            "field": "status",
                            "operator": "like",
                            "value": 'STS_PENDING',
                            "dataType": "string"
                        }
                    ]
                }
            };
            try {
                const reviewList = await callApi(
                    `${baseUrl}/modules/${reviewStatus}`,
                    "POST",
                    headers,
                    payload
                );
                const pendingReviewList = await callApi(
                    `${baseUrl}/modules/${reviewStatus}`,
                    "POST",
                    headers,
                    pending_payload
                );
                setPendingCount({...pendingCount,Reviews:pendingReviewList?.total_rows})
                setReviewListData(reviewList.records);
                setUserListData([]);
                setShowDataTable(true)
                const pageTotal = reviewList.total_pages;
                setTotalPage(Array.from({ length: pageTotal }, (_, i) => i + 1));
            } catch (error) {
                console.error("There was an error fetching the data!", error);
            } finally {
                setIsLoading(false);
            }
        } else if (reviewStatus == "users/get-users") {
            const payload = {
                current_page: currentPage,
                area_id: user?.area_id,
            };
            try {
                const userList = await callApi(
                    `${baseUrl}/modules/${reviewStatus}`,
                    "POST",
                    headers,
                    payload
                );
                console.log("userlist", userList);
                setShowDataTable(false)
                setUserListData(userList.records);
                setReviewListData([]);
                const pageTotal = userList.total_pages;
                setTotalPage(Array.from({ length: pageTotal }, (_, i) => i + 1));
            } catch (error) {
                console.error("There was an error fetching the data!", error);
            } finally {
                setIsLoading(false);
            }
        }
    }

    // SweetAlert Toast 
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        },
    });

    const handleDeleteReview = async (id) => {
        const confirmation = await Swal.fire({
            title: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });

        if (confirmation.isConfirmed) {
            const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

            const headers = {
                "Content-Type": "application/json",
                token: user?.token,
            };
            const payload =
            {
                "review_id": id
            }
            try {
                const ReviewDeleteResponse = await callApi(
                    `${baseUrl}/modules/reviews/delete`,
                    "POST",
                    headers,
                    payload
                );
                if (ReviewDeleteResponse.success) {
                    console.log("success", ReviewDeleteResponse);
                    Toast.fire({
                        title: "Deleted",
                        icon: "success",
                    });
                    ReviewList()
                } else {
                    console.log("failed", ReviewDeleteResponse);
                    Toast.fire({
                        title: "Failed to delete service",
                        icon: "error",
                    });
                }
            } catch (error) {
                console.error("Error:", error);
                Toast.fire({
                    title: "An error occurred while deleting the service",
                    icon: "error",
                });
            }
        }
    }

    return (
        <>
            <div className="row d-flex justify-content-between mb-3">
                <div className="col-md-6 col-6">
                    <h3>Reviews</h3>
                </div>

            </div>
            <div class="page-heading">
                <div class="page-title">
                    <section className="section">
                        <div className="card">
                            <div className="card-body">
                                <div className="dataTable-top mb-3">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a
                                                className="nav-link active"
                                                id="home-tab"
                                                data-bs-toggle="tab"
                                                href="#home"
                                                role="tab"
                                                aria-controls="home"
                                                aria-selected="true"
                                                onClick={() => setReviewStatus("reviews/get-reviews")}
                                            >
                                                All
                                            </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a
                                                className="nav-link"
                                                id="profile-tab"
                                                data-bs-toggle="tab"
                                                href="#profile"
                                                role="tab"
                                                aria-controls="profile"
                                                aria-selected="false"
                                                onClick={() => setReviewStatus("users/get-users")}
                                            >
                                                Users
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        {
                            reviewListData && showDataTable && (
                                <div className="card">
                                    <div className="card-body">
                                        <div className="dataTable-top mb-3">
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link active"
                                                        id="home-tab"
                                                        data-bs-toggle="tab"
                                                        href="#home"
                                                        role="tab"
                                                        aria-controls="home"
                                                        aria-selected="true"
                                                        onClick={() => setStatus("STS_LIVE")}
                                                    >
                                                        Live
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        id="profile-tab"
                                                        data-bs-toggle="tab"
                                                        href="#profile"
                                                        role="tab"
                                                        aria-controls="profile"
                                                        aria-selected="false"
                                                        onClick={() => setStatus("STS_PENDING")}
                                                    >
                                                         Pending {`${pendingCount?.Reviews ? '(' + pendingCount?.Reviews + ')' : ''}`}
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        id="contact-tab"
                                                        data-bs-toggle="tab"
                                                        href="#contact"
                                                        role="tab"
                                                        aria-controls="contact"
                                                        aria-selected="false"
                                                        onClick={() => setStatus("STS_REJECTED")}
                                                    >
                                                        Rejected
                                                    </a>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <a
                                                        className="nav-link"
                                                        id="contact-tab"
                                                        data-bs-toggle="tab"
                                                        href="#contact"
                                                        role="tab"
                                                        aria-controls="contact"
                                                        aria-selected="false"
                                                        onClick={() => setStatus("STS_EXPIRED")}
                                                    >
                                                        Expired
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dataTable-container">
                                            <table className="table table-striped" id="table1">
                                                <thead>
                                                    <tr>
                                                        {columnData.map((col, i) => (
                                                            <th className="ta_head" key={i}>
                                                                {col}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {isLoading && (
                                                        <img
                                                            src={loading_gif}
                                                            className="loading_gif"
                                                            alt="Loading..."
                                                        />
                                                    )}
                                                    {reviewListData && reviewListData.length > 0 ? (
                                                        reviewListData?.map((review, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{review?.title}</td>
                                                                <td>{review?.ratings}</td>

                                                                <td>
                                                                    {dateUtils.convertDateToString(
                                                                        review?.created_at
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <div className="col-sm-12">
                                                                        <Link
                                                                            to={{
                                                                                pathname: `/review-edit/${review?.review_id}`,
                                                                                state: review,
                                                                            }}
                                                                        >
                                                                            <button className="btn btn-primary me-1 mb-1">
                                                                                <i className="bi bi-pencil-square"></i>
                                                                            </button>
                                                                        </Link>
                                                                        <button
                                                                            className="btn btn-danger me-1 mb-1"
                                                                            onClick={() => handleDeleteReview(review?.review_id)}
                                                                            title="Delete"
                                                                        >
                                                                            <i className="bi bi-trash3"></i>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={columnData.length}>No data found.</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="dataTable-bottom d-flex justify-content-end">
                                            {Array.isArray(reviewListData) &&
                                                reviewListData.length > 0 ? (
                                                <Pagination
                                                    totalPage={totalPage}
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {
                            userListData && userListData.length > 0 && (
                                <>
                                    <div className="dataTable-container">
                                        <table className="table table-striped" id="table1">
                                            <thead>
                                                <tr>
                                                    {userColumnData.map((col, i) => (
                                                        <th className="ta_head" key={i}>
                                                            {col}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {isLoading && (
                                                    <img
                                                        src={loading_gif}
                                                        className="loading_gif"
                                                        alt="Loading..."
                                                    />
                                                )}
                                                {userListData && userListData.length > 0 ? (
                                                    userListData?.map((user, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <div>
                                                                    <img
                                                                        src={user.path ? user.path : profile_img}
                                                                        style={{ width: "50px", height: "50px", objectFit: "cover" }}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>{user.first_name}</td>
                                                            <td>{user.last_name ? user?.last_name : "-"}</td>
                                                            <td>{user.email}</td>
                                                            <td>{user.phone}</td>

                                                            <td>
                                                                <div className="col-sm-12">
                                                                    <Link
                                                                        to={{
                                                                            pathname: `/user-review/${user.user_id}`,
                                                                            state: user,
                                                                        }}
                                                                    >
                                                                        <button className="btn btn-primary me-1 mb-1">
                                                                            View
                                                                        </button>
                                                                    </Link>

                                                                </div>
                                                            </td>


                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={userColumnData.length}>No data found.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="dataTable-bottom d-flex justify-content-end">
                                        {Array.isArray(reviewListData) &&
                                            reviewListData.length > 0 ? (
                                            <Pagination
                                                totalPage={totalPage}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        ) : null}
                                    </div>
                                </>
                            )
                        }
                    </section>
                </div>
            </div>
        </>
    );
};

export default ReviewDataTable;
