import React from 'react'
import ReviewDataTable from '../../table/reviewDataTable/ReviewDataTable'

const ReviewListings = () => {
    return (
        <div>
            <ReviewDataTable />
        </div>
    )
}

export default ReviewListings
