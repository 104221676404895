import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import loading_gif from "../../../assets/gif/loading_gif.gif";
import dateUtils from "../../../utils/dateUtils";
import { callApi } from "../../api/Api";
import { useUser } from "../../../utils/utils";
import "../../user/editUser/EditUser.css";
import Swal from "sweetalert2";
import shop_image from "../../../assets/jpg/shop_image.jpg";
import customer_service from "../../../assets/png/customer_service.png";
import property_icon from "../../../assets/png/property_icon.png";
import SingleImageSelector from "../../singleImageSelector/SingleImageSelector";

function EditUser() {
  const [isLoading, setIsLoading] = useState(false);
  const [userShops, setUserShops] = useState([]);
  const [userServices, setUserServices] = useState([]);
  const [userProperties, setUserProperties] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [featuredImage, setFeaturedImage] = useState();
  const [userInfo, setUserInfo] = useState();
  const [activeTab, setActiveTab] = useState("shops");
  const { user } = useUser();
  const { id } = useParams();
  const navigate = useNavigate();

  console.log("check it => ", userInfo);

  const columnData = {
    shops: ["S.No", "Name", "Landmark", "Shops", "Status"],
    services: ["S.No", "Name", "Landmark", "Services", "Status"],
    properties: ["S.No", "Posted_by", "Email", "Properties", "Status"],
  };

  useEffect(() => {
    fetchData();
  }, [activeTab]);

  useEffect(() => {
    fetchUserData();
  }, []);

  var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

  const fetchUserData = async () => {
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    try {
      const userDataDetails = await callApi(
        `${baseUrl}/modules/users/get-user/${id}`,
        "POST",
        headers
      );
      console.log("userData", userDataDetails);
      setUserInfo(userDataDetails.record);
      setFeaturedImage(userDataDetails.record);
    } catch (error) {
      console.log("errors", error);
    }
  };

  async function fetchData() {
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    var payload = {
      user_id: id,
    };

    setIsLoading(true);

    try {
      let apiEndpoint = "";
      if (activeTab === "shops") {
        apiEndpoint = `${baseUrl}/modules/shops/shops-by-user`;
      } else if (activeTab === "services") {
        apiEndpoint = `${baseUrl}/modules/services/services-by-user`;
      } else if (activeTab === "properties") {
        apiEndpoint = `${baseUrl}/modules/properties/properties-by-user`;
      }

      const responseData = await callApi(apiEndpoint, "POST", headers, payload);

      if (activeTab === "shops") {
        setUserShops(responseData.records);
      } else if (activeTab === "services") {
        setUserServices(responseData.records);
      } else if (activeTab === "properties") {
        setUserProperties(responseData.records);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  //Toast for EditUser
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  //soft delete the particular shop
  const handleShopDelete = async (shop_id) => {
    const confirmation = await Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmation.isConfirmed) {
      var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
      const headers = {
        "Content-Type": "application/json",
        token: user?.token,
      };
      try {
        const ShopDeleteResponse = await callApi(
          `${baseUrl}/modules/shops/delete/${shop_id}`,
          "POST",
          headers
        );
        if (ShopDeleteResponse.success) {
          console.log("success", ShopDeleteResponse);
          Toast.fire({
            title: "Deleted",
            icon: "success",
          });
          window.location.reload();
        } else {
          console.log("failed", ShopDeleteResponse);
          Toast.fire({
            title: "Failed to delete shop",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        Toast.fire({
          title: "An error occurred while delete the shop",
          icon: "error",
        });
      }
    }
  };

  //soft delete the particular service
  const handleServiceDelete = async (service_id) => {
    const confirmation = await Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmation.isConfirmed) {
      var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
      const headers = {
        "Content-Type": "application/json",
        token: user?.token,
      };
      try {
        const ServiceDeleteResponse = await callApi(
          `${baseUrl}/modules/services/delete/${service_id}`,
          "POST",
          headers
        );
        if (ServiceDeleteResponse.success) {
          console.log("success", ServiceDeleteResponse);
          Toast.fire({
            title: "Deleted",
            icon: "success",
          });
          fetchData();
        } else {
          console.log("failed", ServiceDeleteResponse);
          Toast.fire({
            title: "Failed to delete service",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        Toast.fire({
          title: "An error occurred while delete the service",
          icon: "error",
        });
      }
    }
  };

  //soft delete the particular property
  const handlePropertyDelete = async (property_id) => {
    const confirmation = await Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmation.isConfirmed) {
      var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
      const headers = {
        "Content-Type": "application/json",
        token: user?.token,
      };
      try {
        const PropertyDeleteResponse = await callApi(
          `${baseUrl}/modules/properties/delete/${property_id}`,
          "POST",
          headers
        );
        if (PropertyDeleteResponse.success) {
          console.log("success", PropertyDeleteResponse);
          Toast.fire({
            title: "Deleted",
            icon: "success",
          });
          fetchData();
        } else {
          console.log("failed", PropertyDeleteResponse);
          Toast.fire({
            title: "Failed to delete property",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        Toast.fire({
          title: "An error occurred while delete the property",
          icon: "error",
        });
      }
    }
  };

  const renderTableRows = (data) => {
    if (activeTab === "shops") {
      return data && data.length > 0 ? (
        data.map((shop, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{shop.name}</td>
            <td>{shop.line_1}</td>
            <td>
              <img
                src={
                  shop.featured_image_path
                    ? shop.featured_image_path
                    : shop_image
                }
                style={{ width: "50px", height: "50px" }}
              />
            </td>
            <td>{shop.is_active === true ? "Active" : "Inactive"}</td>
            <td>
              <div className="col-sm-12 d-flex justify-content-end">
                <Link
                  to={{
                    pathname: `/shop-edit/${shop.shop_id}`,
                    state: shop,
                  }}
                >
                  <button className="btn btn-success me-1 mb-1">
                    <i className="bi bi-pencil-square"></i>
                  </button>
                </Link>
                <button
                  className="btn btn-danger me-1 mb-1"
                  onClick={() => handleShopDelete(shop.shop_id)}
                >
                  <i className="bi bi-trash3"></i>
                </button>
              </div>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={columnData.length}>No data available</td>
        </tr>
      );
    } else if (activeTab === "services") {
      return data && data.length > 0 ? 
       (data.map((service, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{service.name}</td>
          <td>{service.line_1}</td>
          <td>
            <img
              src={
                service.featured_image_path
                  ? service.featured_image_path
                  : customer_service
              }
              style={{ width: "50px", height: "50px" }}
            />
          </td>
          <td>{service.is_active === true ? "Active" : "Inactive"}</td>

          <td>
            <div className="col-sm-12 d-flex justify-content-end">
              <Link
                to={{
                  pathname: `/service-edit/${service.service_id}`,
                  state: service,
                }}
              >
                <button className="btn btn-success me-1 mb-1">
                  <i className="bi bi-pencil-square"></i>
                </button>
              </Link>
              <button
                className="btn btn-danger me-1 mb-1"
                onClick={() => handleServiceDelete(service.service_id)}
              >
                <i className="bi bi-trash3"></i>
              </button>
            </div>
          </td>
        </tr>
      ))) : (
        <tr>
          <td colSpan={columnData.length}>No data available</td>
        </tr>
      );
    }
    //  else if (activeTab === "properties") {
    //   return data && data.length > 0 ?
    //    data.map((property, index) => (
    //     <tr key={index}>
    //       <td>{index + 1}</td>
    //       <td>{property.posted_by}</td>
    //       <td>{property.email}</td>
    //       <td>
    //         <img
    //           src={
    //             property.featured_image_path
    //               ? property.featured_image_path
    //               : property_icon
    //           }
    //           style={{ width: "50px", height: "50px" }}
    //         />
    //       </td>

    //       <td>{property.is_active === true ? "Active" : "Inactive"}</td>
    //       <td>
    //         <div className="col-sm-12 d-flex justify-content-end">
    //           <Link
    //             to={{
    //               pathname: `/property-edit/${property.property_id}`,
    //               state: property,
    //             }}
    //           >
    //             <button className="btn btn-success me-1 mb-1">
    //               <i className="bi bi-pencil-square"></i>
    //             </button>
    //           </Link>
    //           <button
    //             className="btn btn-danger me-1 mb-1"
    //             onClick={() => handlePropertyDelete(property.property_id)}
    //           >
    //             <i className="bi bi-trash3"></i>
    //           </button>
    //         </div>
    //       </td>
    //     </tr>
    //   ))) :
      
    // }
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  // handleChange for featuredImages
  const onChangeFeaturedImage = (newlySelectedImage) => {
    setFeaturedImage(newlySelectedImage.image_id);
    setFeaturedImage(newlySelectedImage);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const payload = {
      user_id: id,
      ...formValues,
      profile_image: featuredImage?.image_id,
    };

    console.log(payload);

    try {
      const userEditResponse = await callApi(
        `${baseUrl}/modules/users/update`,
        "POST",
        headers,
        payload
      );
      if (userEditResponse.success) {
        console.log("success", userEditResponse);
        Toast.fire({
          icon: "success",
          title: "user edited successfully",
        });
        navigate("/users");
      } else {
        console.error("Error: Response indicates failure", userEditResponse);
        Toast.fire({
          icon: "error",
          title: userEditResponse.message || "Failed to edit user",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.fire({
        icon: "error",
        title: "An error occurred while editing the user",
      });
    }
  };

  return (
    <div>
      <div className="page-heading">
        <div className="page-title">
          <section className="section">
            <div className="row match-height">
              <div className="col-md-10 col-lg-10">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">User Information</h4>
                  </div>
                  <div className="card-content">
                    <div className="card-body">
                      <form
                        className="form form-horizontal"
                        onSubmit={handleSubmit}
                      >
                        <div className="form-body">
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="first-name-horizontal">
                                First Name
                              </label>
                            </div>
                            <div className="col-md-8 form-group">
                              <input
                                type="text"
                                id="first_name"
                                name="first_name"
                                className="form-control"
                                value={
                                  formValues.first_name != undefined
                                    ? formValues?.first_name
                                    : userInfo?.first_name
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div className="col-md-4">
                              <label htmlFor="first-name-horizontal">
                                Last Name
                              </label>
                            </div>
                            <div className="col-md-8 form-group">
                              <input
                                type="text"
                                id="last_name"
                                name="last_name"
                                className="form-control"
                                value={
                                  formValues.last_name != undefined
                                    ? formValues?.last_name
                                    : userInfo?.last_name
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div className="col-md-4">
                              <label htmlFor="first-name-horizontal">
                                Phone
                              </label>
                            </div>
                            <div className="col-md-8 form-group">
                              <input
                                type="text"
                                id="phone"
                                name="phone"
                                className="form-control"
                                value={
                                  formValues.phone != undefined
                                    ? formValues?.phone
                                    : userInfo?.phone
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div className="col-md-4">
                              <label htmlFor="first-name-horizontal">
                                Email
                              </label>
                            </div>
                            <div className="col-md-8 form-group">
                              <input
                                type="text"
                                id="email"
                                name="email"
                                className="form-control"
                                value={
                                  formValues.email != undefined
                                    ? formValues?.email
                                    : userInfo?.email
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div className="col-md-4">
                              <label htmlFor="first-name-horizontal">
                                Gender
                              </label>
                            </div>
                            <div className="col-md-8 form-group">
                              <input
                                type="text"
                                id="gender"
                                name="gender"
                                className="form-control"
                                value={
                                  formValues.gender != undefined
                                    ? formValues?.gender
                                    : userInfo?.gender
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="coverImage">Upload Profile</label>
                            </div>
                            <div className="col-md-8 col-lg-8 col-xs-8 form-group">
                              <fieldset>
                                <SingleImageSelector
                                  imageToShow={featuredImage}
                                  onChangeImage={onChangeFeaturedImage}
                                />
                              </fieldset>
                            </div>
                            <div className="col-sm-12 d-flex justify-content-end">
                              <button
                                type="submit"
                                className="btn btn-primary me-1 mt-1"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="dataTable-top mb-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className={`nav-link ${
                          activeTab === "shops" ? "active" : ""
                        }`}
                        id="shops-tab"
                        data-bs-toggle="tab"
                        href="#shops"
                        role="tab"
                        onClick={() => setActiveTab("shops")}
                      >
                        Shops
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className={`nav-link ${
                          activeTab === "services" ? "active" : ""
                        }`}
                        id="services-tab"
                        data-bs-toggle="tab"
                        href="#services"
                        role="tab"
                        onClick={() => setActiveTab("services")}
                      >
                        Services
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className={`nav-link ${
                          activeTab === "properties" ? "active" : ""
                        }`}
                        id="properties-tab"
                        data-bs-toggle="tab"
                        href="#properties"
                        role="tab"
                        onClick={() => setActiveTab("properties")}
                      >
                        Properties
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="dataTable-container">
                  <table className="table table-striped user-table" id="table1">
                    <thead>
                      <tr>
                        {columnData[activeTab].map((col, i) => (
                          <th className="table_head" key={i}>
                            {col}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan={columnData[activeTab].length}>
                            <img
                              src={loading_gif}
                              className="loading_gif"
                              alt="Loading..."
                            />
                          </td>
                        </tr>
                      ) : userShops.length ||
                        userServices.length ||
                        userProperties.length ? (
                        renderTableRows(
                          activeTab === "shops"
                            ? userShops
                            : activeTab === "services"
                            ? userServices
                            : userProperties
                        )
                      ) : (
                        <tr>
                          <td colSpan={columnData[activeTab].length}>
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
