import React from "react";
import { useUser } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import "./Logout.css";
import Swal from "sweetalert2";

function Logout() {
  const { user, setUser } = useUser();
  const navigate = useNavigate();

  // SweetAlert Toast For handleShopDelete
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const handleLogout = async () => {
    const confirmation = await Swal.fire({
      title: "Are you sure to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
    });

    if (confirmation.isConfirmed) {
      try {
        if (user?.token) {
          // Step 1: Retrieve the object from local storage
          let userStorage = JSON.parse(localStorage.getItem("user"));

          // Step 2: Remove the user_id and token from the object
          if (userStorage) {
            delete userStorage.user_id;
            delete userStorage.token;

            // Step 3: Store the updated object back in local storage
            localStorage.setItem("user", JSON.stringify(userStorage));
          }

          // Update the context/state with the modified user object
          const userLogout = { ...user, token: null, user_id: null };
          setUser(userLogout);

          Toast.fire({
            title: "Logout successful",
            icon: "success",
          });
          navigate("/");
        } else {
          Toast.fire({
            title: "Failed to logout",
            icon: "error",
          });
        }
      } catch (error) {
        Toast.fire({
          title: "An error occurred while logging out",
          icon: "error",
        });
        console.error(error);
      }
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-end">
      <button
        className="btn btn-primary btn-md shadow-lg logout_button"
        onClick={handleLogout}
      >
        <div className="d-flex align-items-center justify-content-center">
          <i
            className="bi bi-box-arrow-left"
            style={{ fontSize: "20px" }}
            title="logout"
          ></i>
        </div>
      </button>
    </div>
  );
}

export default Logout;
