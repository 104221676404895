import React from "react";
import Message from "../../errors/Message";

const Dropdown = ({ label, name, value, options, onChange, isEditMode, fieldName, errors}) => {
  // console.log("options", options)
  return (
    <div>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <label htmlFor="first-name-horizontal" className="form-label">
            {label}
          </label>
        </div>
        <div className="col-md-12">
          <fieldset className="form-group">
            <select
              name={name}
              className="form-select"
              value={value || ""}
              onChange={onChange}
            >
              {!isEditMode && (
                <option value="" disabled selected>
                  Select
                </option>
              )}

              {options?.map((option, index) => (
                <option key={index} value={option?.value}>
                  {option?.label}
                </option>
              ))}
            </select>
            <Message type={"error"} errors={errors} fieldName={fieldName?.fieldName}/>
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
