import React from "react";
import ServiceDataTable from "../../table/serviceDataTable/ServiceDataTable";
import Logout from "../../auth/logout/Logout";

function ServiceListings() {
  return (
    <div>
     
      <div>
        <ServiceDataTable />
      </div>
    </div>
  );
}

export default ServiceListings;
