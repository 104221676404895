import React, { useEffect, useRef, useState } from 'react'
import Message from '../errors/Message'
import SingleImageSelector from '../singleImageSelector/SingleImageSelector';
import ListSelector from '../selector/ListTypeSelector';
import './AddOffers.css'
import AddTags from '../Tags/AddTags/Addtags';
import { useUser } from '../../utils/utils';
import { callApi } from '../api/Api';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import MultiImageSelector from '../multiImageSelector/MultiImageSelector';

const AddOffers = () => {
    const { user } = useUser()
    const navigate = useNavigate();
    const inputFile = useRef(null);
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const [formValues, setFormValues] = useState({});
    const [addedTags, setAddedTags] = useState([]);
    const [removedTags, setRemovedTags] = useState([]);
    const [featuredImage, setFeaturedImage] = useState("");
    const [errors, setErrors] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [getIdFromListings, setIdFromListings] = useState()
    const [listingsRecord, setListingsRecord] = useState([])
    const [isChecked, setIsChecked] = useState(false)
    const [isCheckedOptionValue, setIsCheckedOptionValue] = useState(false)
    const [mainOffer, setMainOffer] = useState(true)
    const image_id = selectedImages?.map((selectImage) => selectImage.image_id);


    const [addOtherOffers, setAddOtherOffers] = useState({
        offer_name: '',
        mrp: '',
        offer_price: '',
        hypso_price: '',
        image_id: "",
        image_path: ''
    })

    const [showOtherOptions, setShowOtherOptions] = useState([])
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);
    const [recordTitle, setRecordTitle] = useState([])
    const [percentageValue, setPercentageValue] = useState()
    const [percentageOptionsValue, setPercentageOptionsValue] = useState()



    const handlechangeforoptions = (e) => {
        setAddOtherOffers({ ...addOtherOffers, [e.target.name]: e.target.value })
    }

    console.log("percentageValue", percentageValue);
    console.log("actual_price", formValues?.mrp);

    useEffect(() => {
        percenatageCalculations(percentageValue, formValues?.mrp, mainOffer)
    }, [formValues?.mrp, percentageValue])

    useEffect(() => {
        console.log('working');

        percenatageCalculations(percentageOptionsValue, addOtherOffers?.mrp, !mainOffer)
    }, [addOtherOffers?.mrp, percentageOptionsValue])

    const percenatageCalculations = (percentageValue, mrp, mainOffer) => {
        if (mainOffer) {
            if (percentageValue && mrp) {
                var percentage = Math.round((mrp - (percentageValue / 100) * mrp))
                console.log("percentage", percentage);
                setFormValues(prevState => ({
                    ...prevState,
                    offer_price: percentage
                }))
            }
            // else {
            //     setFormValues(prevState => ({
            //         ...prevState,
            //         offer_price: null
            //     }))
            // }
        }
        else if (!mainOffer) {
            if (percentageValue && mrp) {
                var percentage = Math.round((mrp - (percentageValue / 100) * mrp))
                console.log("percentage", percentage);
                setAddOtherOffers(prevState => ({
                    ...prevState,
                    offer_price: percentage
                }))
            }
            // else {
            //     setAddOtherOffers(prevState => ({
            //         ...prevState,
            //         offer_price: null
            //     }))
            // }
        }
    }

    // console.log("recordTitle", formValues?.entity_details_id);


    const handleChange = (event) => {
        setFormValues({ ...formValues, [event.target.name]: event.target.value });
        const { name, value } = event.target;
        // If entity_type changes, reset entity_details_id
        if (name === "entity_type") {
            setFormValues({
                ...formValues,
                [name]: value,
                entity_details_id: "", // Reset entity_details_id
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
    };

    useEffect(() => {
        const selectedRecord = recordTitle.find(
            (record) => record.entity_details_id == formValues?.entity_details_id

        );
        setIdFromListings(selectedRecord ? selectedRecord?.service_id || selectedRecord?.shop_id : null)

    }, [formValues?.entity_details_id])

    useEffect(() => {
        getListingRecord()
    }, [getIdFromListings])

    console.log("servicerecords=>", listingsRecord);


    useEffect(() => {
        setFormValues(prevState => ({
            ...prevState,
            line_1: listingsRecord?.line_1 || listingsRecord?.address?.line_1 || "",
            line_2: listingsRecord?.line_2 || listingsRecord?.address?.line_2 || "",
            phone: listingsRecord?.phone || listingsRecord?.address?.phone || "",
            email: listingsRecord?.email || listingsRecord?.address?.email || "",
            zip: listingsRecord?.zip || listingsRecord?.address?.zip || "",
            state: listingsRecord?.state || listingsRecord?.address?.state || "",
            country: listingsRecord?.country || listingsRecord?.address?.country || "",
            city: listingsRecord?.city || listingsRecord?.address?.city || "",
        }))
    }, [listingsRecord])

    const getListingRecord = async () => {
        // e.preventDefault()   ;

        var headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token,
        };
        if (formValues?.entity_type === "ENT_SHOP") {
            const shop_record = await callApi(
                `${baseUrl}/modules/shops/get-shop/${getIdFromListings}`,
                "POST",
                headers,
            );
            setListingsRecord(shop_record?.record)
        }
        else if (formValues?.entity_type === "ENT_SERVICE") {
            const service_record = await callApi(
                `${baseUrl}/modules/services/get-service/${getIdFromListings}`,
                "POST",
                headers,
            );
            setListingsRecord(service_record?.record)
        }
    }
    // console.log("record", listingsRecord);

    // Toast for Shop Add
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        },
    });

    const handleoptionsubmit = (e) => {
        e.preventDefault()
        if (isEditing) {
            // Update the existing offer in showOtherOptions
            setShowOtherOptions((prevOffers) =>
                prevOffers.map((offer) =>
                    offer.offer_name === editId ? { ...addOtherOffers, offer_name: editId } : offer
                )
            );
            setIsEditing(false); // Exit editing mode
            setEditId(null); // Clear the editId
            setPercentageOptionsValue('')
        } else {
            // Add a new offer
            setShowOtherOptions((prevOffers) => [...prevOffers, addOtherOffers]);
            setPercentageOptionsValue('')
        }

        setAddOtherOffers({
            offer_name: '',
            mrp: '',
            offer_price: '',
            hypso_price: '',
            image_id: '',
            image_path: ''
        });
        setPercentageOptionsValue('')
        inputFile.current.value = "";
    }


    const handleDeleteOffers = (index) => {
        console.log("index", index);
        const updateShowOtherOffers = showOtherOptions.filter((_, i) => i !== index);
        // const updateShowOtherOffers = showOtherOptions.filter((showOtherOption) => showOtherOption.index != index)
        setShowOtherOptions(updateShowOtherOffers)
        // Reset form with incremented ID
        setAddOtherOffers({
            offer_name: '',
            mrp: '',
            offer_price: '',
            hypso_price: '',
            image_id: '',
            image_path: '',
        });
        setPercentageOptionsValue(null)
    }

    const handleEditOffers = (offer) => {
        setAddOtherOffers(offer); // Fill form with the offer being edited
        setIsEditing(true); // Enter editing mode
        setEditId(offer.offer_name); // Track the id of the offer being edited
    };

    const onchangeOptionImage = (id, path) => {
        console.log('optionImage', id);
        console.log('path', path);
        setAddOtherOffers(prevState => ({
            ...prevState,
            image_id: id,
            image_path: path
        }));
    };


    // Image upload for other option 
    const handleImageSelect = async (event) => {
        const imageToUpload = event.target.files[0];
        //upload image using api and receive image object
        if (imageToUpload) {
            await uploadImage(imageToUpload);
        }
    }

    const uploadImage = async (imageToUpload) => {
        //turn on loader
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("file", imageToUpload);
            formData.append('area_id', user?.area_id);

            const headers = {
                token: user?.token,
            };

            var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

            const featureImageResponse = await fetch(
                `${baseUrl}/modules/images/upload`,
                {
                    method: "POST",
                    headers: headers,
                    body: formData,
                }
            );

            if (featureImageResponse.ok) {
                const uploadedImage = await featureImageResponse.json();
                console.log("Upload Successful:" + JSON.stringify(uploadedImage));
                onchangeOptionImage(uploadedImage.image_id, uploadedImage.path);
                //call onChangeImage and pass uploaded image object
            } else {
                console.error("Upload Failed:", featureImageResponse.statusText);
                // Handle error
            }
        } catch (error) {
            console.error("Upload Failed:", error);
            // Handle error
        } finally {
            //turn on loader
            setIsLoading(false);
        }
    };

    // handleChange for featuredImages
    const onChangeFeaturedImage = (newlySelectedImage) => {
        // setFeaturedImage(newlySelectedImage.image_id)
        setFeaturedImage(newlySelectedImage);
    };

    // Get shop and service based on their entity_type 

    useEffect(() => {
        console.log(formValues?.entity_type);
        handleRecords()
    }, [formValues?.entity_type])

    // console.log("showOtherOptions", showOtherOptions);

    const handleRecords = async () => {
        var headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token,
        };
        var payload = {
            area_id: user?.area_id,
            filters: {
                conditions: [
                    {
                        field: "entity_type",
                        operator: "equals",
                        value: formValues?.entity_type,
                        dataType: "string",
                    },
                ],
            },
        };

        if (formValues?.entity_type === "ENT_SHOP") {


            const shopResponse = await callApi(
                `${baseUrl}/modules/shops/get-Shops`,
                "POST",
                headers,
                payload
            );
            setRecordTitle(
                shopResponse?.records.map((record) => record)
            )
            console.log("shopResponse", shopResponse);
        }

        else if (formValues?.entity_type === "ENT_SERVICE") {

            const serviceResponse = await callApi(
                `${baseUrl}/modules/services/get-services`,
                "POST",
                headers,
                payload
            );

            setRecordTitle(
                serviceResponse?.records.map((record) => record)
            )
            console.log("serviceResponse", serviceResponse);
        }
    };

    const handleSubmitOffers = async (e) => {
        e.preventDefault();

        var headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: user?.token,
        };

        var payload = {
            ...formValues,
            area_id: user?.area_id,
            tags: {
                added_tags: addedTags,
            },
            address: {
                line_1: listingsRecord?.line_1 || formValues?.line_1,
                line_2: listingsRecord?.line_2 || formValues?.line_2,
                phone: listingsRecord?.phone || formValues?.phone,
                email: listingsRecord?.email || formValues?.email,
                city: listingsRecord?.city || formValues?.city,
                state: listingsRecord?.state || formValues?.state,
                zip: listingsRecord?.zip || formValues?.zip,
                area: user?.area_id,
                country: listingsRecord?.country || formValues?.country,
            },
            featured_image: featuredImage.image_id,

            other_offers: {
                available_offers: showOtherOptions.map(({ image_path, ...offer }) => offer) // Exclude image_path
            }
        }

        if (selectedImages.length > 0) {
            payload = {
                ...payload,
                images: { images: image_id },
            }
        }

        console.log("payload", payload);
        // var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
        try {
            const offerResponse = await callApi(`${baseUrl}/modules/offers/create`,
                "POST",
                headers,
                payload)
            if (offerResponse.success) {
                console.log("success", offerResponse);
                Toast.fire({
                    icon: "success",
                    title: "Data saved successfully",
                });
                navigate("/offers");
            }
            else {
                setErrors(offerResponse.errors);
                console.error("Error: Response indicates failure", offerResponse);
                Toast.fire({
                    icon: "error",
                    title: offerResponse.message || "Failed to save data",
                });
            }
            console.log("response", offerResponse);
        } catch (err) {
            console.log(err);


        }
    }

    return (
        <>
            <div className="page-heading">
                <div className="page-title">
                    <div className="row">
                        <div className="col-12 col-md-6 order-md-1 order-last">
                            <h3>Add Offer</h3>
                        </div>
                        <div className="col-12 col-md-6 order-md-2 order-first">
                            <nav
                                aria-label="breadcrumb"
                                className="breadcrumb-header float-start float-lg-end"
                            ></nav>
                        </div>
                    </div>
                </div>
            </div>

            <section class="section">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link active" id="basicInformation-tab" data-bs-toggle="tab"
                                            href="#basicInformation" role="tab" aria-controls="basicInformation"
                                            aria-selected="true">Basic Information</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" id="duration-tab" data-bs-toggle="tab" href="#duration" role="tab"
                                            aria-controls="duration" aria-selected="false">Duration</a>
                                    </li>
                                </ul>

                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="basicInformation" role="tabpanel"
                                        aria-labelledby="basicInformation-tab">
                                        {/* Basic Information Section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-11 col-lg-11">
                                                    <div className="card mb-0">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Basic Information</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Listing type
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <ListSelector
                                                                                    selectedList={formValues?.entity_type}
                                                                                    onChange={handleChange}

                                                                                />
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Select Shop /
                                                                                    Service</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <fieldset className="form-group">
                                                                                    <select onChange={handleChange} className="form-select" value={formValues?.entity_details_id} name="entity_details_id" id="">
                                                                                        <option value="" disabled selected>
                                                                                            Select
                                                                                        </option>
                                                                                        {
                                                                                            recordTitle.map(record => (
                                                                                                <option value={record?.entity_details_id}>{record.name}</option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                    <div>

                                                                                    </div>
                                                                                </fieldset>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Title</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    value={formValues?.offer_name || ""}
                                                                                    onChange={handleChange}
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="offer_name"
                                                                                    placeholder="Enter the title of your offer"
                                                                                    required></input>
                                                                                <div>
                                                                                    <Message type={"error"} errors={errors}
                                                                                        fieldName={"offer_name"} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Product
                                                                                    Name</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    value={formValues?.product_name || ""}
                                                                                    onChange={handleChange}
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="product_name"
                                                                                    placeholder="Enter the product name of your offer"
                                                                                    required></input>
                                                                                <div>
                                                                                    <Message type={"error"} errors={errors}
                                                                                        fieldName={"product_name"} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Actual Price</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    value={formValues?.mrp || ""}
                                                                                    onChange={handleChange}
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="mrp"
                                                                                    placeholder="Enter the total price"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"mrp"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 pt-2 pb-3 ">
                                                                                <div class="form-check form-switch">
                                                                                    <input class="form-check-input" onChange={e => setIsChecked(e.target.checked)} value={isChecked} type="checkbox" id="flexSwitchCheckDefault" />
                                                                                    <label class="form-check-label" for="flexSwitchCheckDefault">Percentage Calculations</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                {
                                                                                    isChecked && (
                                                                                        <input
                                                                                            type="text"
                                                                                            value={percentageValue}
                                                                                            onChange={e => setPercentageValue(e.target.value)}
                                                                                            id="first-name-horizontal"
                                                                                            className="form-control"
                                                                                            name="percentage"
                                                                                            placeholder="Enter the percentage"
                                                                                            required
                                                                                        ></input>
                                                                                    )
                                                                                }
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Offer price</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    value={formValues?.offer_price || ""}
                                                                                    onChange={handleChange}
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="offer_price"
                                                                                    placeholder="Enter the offer price"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"offer_price"}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Hypso offer price</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="hypso_price"
                                                                                    value={formValues?.hypso_price || ""}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter the Hypso offer price"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"hypso_price"}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Offer Count</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="offer_count"
                                                                                    value={formValues?.offer_count || ""}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter the offer count"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"offer_count"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Description
                                                                                </label>
                                                                            </div>

                                                                            <div className="col-md-8 form-group">
                                                                                <textarea
                                                                                    value={formValues?.description || ""}
                                                                                    onChange={handleChange}
                                                                                    className="form-control"
                                                                                    id="exampleFormControlTextarea1"
                                                                                    rows="3"
                                                                                    name="description"
                                                                                    placeholder="Enter a description of your offer"
                                                                                    required>
                                                                                </textarea>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Phone Number
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    value={formValues?.phone}
                                                                                    onChange={handleChange}
                                                                                    type="tel"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="phone"
                                                                                    required></input>
                                                                                <div>
                                                                                    <Message type={"error"} errors={errors}
                                                                                        fieldName={"phone"} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Email</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="email"
                                                                                    value={formValues?.email}
                                                                                    onChange={handleChange}
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="email"
                                                                                    required></input>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Street</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    value={formValues?.line_1}
                                                                                    onChange={handleChange}
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="line_1"
                                                                                    required></input>
                                                                                <div className="">
                                                                                    <Message type={"error"} errors={errors}
                                                                                        fieldName={"line_1"} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Nearby Landmark</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    value={formValues.address?.line_2}
                                                                                    onChange={handleChange}
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="line_2"
                                                                                    required></input>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Terms & Conditions
                                                                                </label>
                                                                            </div>

                                                                            <div className="col-md-8 form-group">
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    id="exampleFormControlTextarea1"
                                                                                    rows="3"
                                                                                    name="terms_and_conditions"
                                                                                    value={formValues?.terms_and_conditions || ""}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter the terms & conditions of your offer"
                                                                                    required
                                                                                ></textarea>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Upload Featured Image
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 col-lg-8 col-xs-8 form-group">
                                                                                <fieldset>
                                                                                    <SingleImageSelector
                                                                                        imageToShow={featuredImage}
                                                                                        onChangeImage={onChangeFeaturedImage} />
                                                                                </fieldset>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Upload Cover Image
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 col-lg-8 col-xs-8 form-group">
                                                                                <fieldset>
                                                                                    <MultiImageSelector
                                                                                        selectedImages={selectedImages}
                                                                                        setSelectedImages={setSelectedImages}
                                                                                    />
                                                                                </fieldset>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Add Tags
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <AddTags
                                                                                    errors={errors}
                                                                                    addedTags={addedTags}
                                                                                    setAddedTags={setAddedTags}
                                                                                    removedTags={removedTags}
                                                                                    setRemovedTags={setRemovedTags} />
                                                                            </div>
                                                                            <h4 className="card-title mb-5">Add other offers</h4>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Title</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="offer_name"
                                                                                    value={addOtherOffers?.offer_name}
                                                                                    onChange={handlechangeforoptions}
                                                                                    placeholder="Enter the title"
                                                                                    required
                                                                                ></input>

                                                                                <div>

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Actual Price</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="mrp"
                                                                                    value={addOtherOffers?.mrp || ''}
                                                                                    onChange={handlechangeforoptions}
                                                                                    placeholder="Enter the maximum retail price"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={""}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4 pt-2 pb-3 ">
                                                                                <div class="form-check form-switch">
                                                                                    <input class="form-check-input" onChange={e => setIsCheckedOptionValue(e.target.checked)} value={isChecked} type="checkbox" id="flexSwitchCheckDefault" />
                                                                                    <label class="form-check-label" for="flexSwitchCheckDefault">Percentage Calculations</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                {
                                                                                    isCheckedOptionValue && (
                                                                                        <input
                                                                                            type="text"
                                                                                            value={percentageOptionsValue}
                                                                                            onChange={e => setPercentageOptionsValue(e.target.value)}
                                                                                            id="first-name-horizontal"
                                                                                            className="form-control"
                                                                                            name="percentage"
                                                                                            placeholder="Enter the percentage"
                                                                                            required
                                                                                        ></input>
                                                                                    )
                                                                                }
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Offer price</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="offer_price"
                                                                                    value={addOtherOffers?.offer_price || ''}
                                                                                    onChange={handlechangeforoptions}
                                                                                    placeholder="Enter the offer price"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={""}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Hypso offer price</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="hypso_price"
                                                                                    value={addOtherOffers?.hypso_price || ''}
                                                                                    onChange={handlechangeforoptions}
                                                                                    placeholder="Enter the hypso offer price"
                                                                                    required
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={""}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Add Featured Image</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="file"
                                                                                    className="form-control"
                                                                                    name="file"
                                                                                    ref={inputFile}
                                                                                    accept=".jpg , .png , .jpeg"
                                                                                    onChange={handleImageSelect}
                                                                                ></input>
                                                                            </div>
                                                                            <div className="col-sm-12 d-flex justify-content-end">
                                                                                <button
                                                                                    onClick={handleoptionsubmit}
                                                                                    className="btn btn-primary me-1 mb-1"
                                                                                >
                                                                                    Add Offer
                                                                                </button>
                                                                            </div>
                                                                            {
                                                                                showOtherOptions && showOtherOptions.length > 0 && (
                                                                                    <section class="section py-2">
                                                                                        <div class="row" id="table-hover-row">
                                                                                            <div class="col-12">
                                                                                                <div class="card">
                                                                                                    <div class="card-content">
                                                                                                        <div class="table-responsive">
                                                                                                            <table class="table table-hover mb-0">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th>S.no</th>
                                                                                                                        <th>Title</th>
                                                                                                                        <th>Image</th>
                                                                                                                        <th>Actual Price</th>
                                                                                                                        <th>Offer price</th>
                                                                                                                        <th>Hypso price</th>
                                                                                                                        <th>Action</th>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        showOtherOptions.map((showOtherOption, index) => (
                                                                                                                            <tr key={index}>
                                                                                                                                <td>{index + 1}</td>
                                                                                                                                <td class="text-bold-500">{showOtherOption?.offer_name}</td>
                                                                                                                                <td><img className='option-image' src={showOtherOption?.image_path} alt="" /></td>
                                                                                                                                <td class="text-bold-500">₹{showOtherOption?.mrp}</td>
                                                                                                                                <td>₹{showOtherOption?.offer_price}</td>
                                                                                                                                <td>₹{showOtherOption?.hypso_price}</td>
                                                                                                                                <td>
                                                                                                                                    <div className="">
                                                                                                                                        <svg onClick={() => handleEditOffers(showOtherOption)} className='me-4' xmlns="http://www.w3.org/2000/svg" width={'17px'} viewBox="0 0 512 512"><path fill="#334fa2" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160L0 416c0 53 43 96 96 96l256 0c53 0 96-43 96-96l0-96c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 96c0 17.7-14.3 32-32 32L96 448c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 64z" /></svg>
                                                                                                                                        <svg onClick={() => handleDeleteOffers(index)} xmlns="http://www.w3.org/2000/svg" width={'15px'} viewBox="0 0 448 512"><path fill="#334fa2" d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z" /></svg>
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        ))
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </section>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                    </div>


                                    <div class="tab-pane fade" id="duration" role="tabpanel" aria-labelledby="duration-tab">
                                        {/* timing and duration section */}
                                        <section id="basic-horizontal-layouts">
                                            <div className="row match-height">
                                                <div className="col-md-10 col-lg-10">
                                                    <div className="card mb-0">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Duration</h4>
                                                        </div>
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <form className="form form-horizontal">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">Start Date</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="date"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="offer_start_date"
                                                                                    placeholder="Enter the offer start date"
                                                                                    required
                                                                                    value={formValues?.offer_start_date || ""}
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"offer_start_date"}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">End Date</label>
                                                                            </div>
                                                                            <div className="col-md-8 form-group">
                                                                                <input
                                                                                    type="date"
                                                                                    id="first-name-horizontal"
                                                                                    className="form-control"
                                                                                    name="offer_end_date"
                                                                                    placeholder="Enter the offer price"
                                                                                    required
                                                                                    value={formValues?.offer_end_date || ""}
                                                                                    onChange={handleChange}
                                                                                ></input>
                                                                                <div>
                                                                                    <Message
                                                                                        type={"error"}
                                                                                        errors={errors}
                                                                                        fieldName={"offer_end_date"}
                                                                                    />
                                                                                </div>
                                                                            </div>


                                                                            <div className="col-md-4">
                                                                                <label htmlFor="first-name-horizontal">
                                                                                    Additional Information
                                                                                </label>
                                                                            </div>

                                                                            <div className="col-md-8 form-group">
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    id="exampleFormControlTextarea1"
                                                                                    rows="3"
                                                                                    name="additional_info"
                                                                                    value={formValues?.additional_info || ""}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Enter the additional information of your offer"
                                                                                    required
                                                                                ></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section >
                                    </div>

                                    <section id="basic-horizontal-layouts">
                                        <div className="row match-height">
                                            <div className="col-md-10 col-lg-11 d-flex justify-content-end">
                                                <div className="card mb-0">
                                                    <div className="card-body">
                                                        <button
                                                            onClick={handleSubmitOffers}
                                                            className="btn btn-primary me-1 mb-1"
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default AddOffers
