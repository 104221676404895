import React from "react";
import { useEffect } from "react";
import error from "../../assets/jpg/error_pic.jpg";
import "./PageNotFound.css"

function PageNotFound() {
  useEffect(() => {
    // Set the local storage key and value
    localStorage.setItem("is404Page", "true");
  }, []);

  return (
    <div className="container page-container">
      <div className="row page-row">
        <div className="col-12 text-center">
          <h1 className="display-1 text-primary mb-0">404</h1>
          <h2>Oh no, something went wrong!</h2>
          <p>Either something went wrong or this page doesn't exist anymore.</p>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
