import React from "react"

export const WorkingTime = ({ hours, updateHours }) => {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      updateHours({
        ...hours,
        status: checked,
        start: { time: checked ? "9:00 AM" : "" },
        end: { time: checked ? "7:00 PM" : "" },
      });
    } else {
      const [field, subField] = name.split("-");
      updateHours({
        ...hours,
        [field]: {
          ...hours[field],
          [subField]: value,
        },
      })
    }
  }

  const renderHourOptions = () => {
    const hoursOptions = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const displayHour = hour % 12 === 0 ? 12 : hour % 12;
        const period = hour < 12 ? "AM" : "PM";
        hoursOptions.push(
          <option key={`${hour}:${minute}`} value={`${displayHour}:${minute < 10 ? "0" + minute : minute} ${period}`}>
            {`${displayHour}:${minute < 10 ? "0" + minute : minute} ${period}`}
          </option>
        )
      }
    }
    return hoursOptions
  }

  const hoursOptions = renderHourOptions()

  return (
    <div className="container">
      <div className="row align-items-center mb-3">
        <div className="col-md-4 form-check form-switch">
          <input
            className="form-check-input working_input"
            type="checkbox"
            name="status"
            id="flexSwitchCheckChecked"
            checked={hours.status}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
            {hours.day}
          </label>
        </div>
        <div className="col-md-3 d-flex flex-column flex-md-row align-items-center">
          <select
            name="start-time"
            className="form-select mb-2 mb-md-0 working_input"
            value={hours.start.time}
            onChange={handleChange}
            disabled={!hours.status}
          >
            <option value="">Select</option>
            {hoursOptions}
          </select>
        </div>
        <div className="col-md-2 d-flex align-items-center justify-content-center pt-2">
          <p>to</p>
        </div>
        <div className="col-md-3 d-flex flex-column flex-md-row align-items-center">
          <select
            name="end-time"
            className="form-select mb-2 mb-md-0 working_input"
            value={hours.end.time}
            onChange={handleChange}
            disabled={!hours.status}
          >
            <option value="">Select</option>
            {hoursOptions}
          </select>
        </div>
      </div>
    </div>
  )
}

