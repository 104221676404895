import React from "react";
import Message from "../../errors/Message";


const Textarea = ({
  label,
  name,
  value,
  onChange,
  type,
  field,
  errors,
  rows,
}) => {
  return (
    <div className="row">
      <div className="col-lg-12 col-md-12">
        <div className="mb-3">
          <label htmlFor="first-name-horizontal" className="form-label">
            {label}
          </label>

          <textarea
            rows={rows}
            name={name}
            className="form-control"
            value={value || ""}
            onChange={onChange}
          ></textarea>
          <Message
            type={"error"}
            fieldName={field?.fieldName}
            errors={errors}
          />
        </div>
      </div>
    </div>
  );
};
export default Textarea;
