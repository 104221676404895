import { GetAreas } from "../../utils/getareas";
import { GetCities } from "../../utils/getCities";
import { GetCountries } from "../../utils/getCountries";
import { GetStates } from "../../utils/getStates";

var function_to_call_get_countries = await GetCountries();
var function_to_call_get_cities = await GetCities();
var function_to_call_get_states = await GetStates();
var function_to_call_get_areas = await GetAreas();


export const AllFieldsConfig = {
  posted_by: {
    id: "posted_by",
    field_name: "posted_by",
    label: "Posted By",
    type: "dropdown",
    values: [
      { label: "Owner", value: "POST_BY_OWNER" },
      { label: "Broker", value: "POST_BY_BROKER" },
    ],
  },

  for_gender: {
    id: "for_gender",
    field_name: "for_gender",
    label: "Gender",
    type: "dropdown",
    values: [
      { label: "Male", value: "GEN_MALE" },
      { label: "Female", value: "GEN_FEMALE" },
      { label: "Others", value: "GEN_OTHERS" },
    ],
  },
  name_of_community: {
    id: "name_of_community",
    field_name: "name_of_community",
    label: "Name of Community",
    type: "textbox",
    dataType: "string",
  },
  name_of_pg: {
    field_name: "name_of_pg",
    label: "Name of Pg",
    type: "textbox",
    dataType: "string",
  },
  carpet_area: {
    field_name: "carpet_area",
    label: "Carpet Area",
    type: "textbox",
    dataType: "string",
  },
  land_area: {
    field_name: "land_area",
    label: "Land Area",
    type: "textbox",
    dataType: "string",
  },
  built_up_area: {
    field_name: "built_up_area",
    label: "Built up Area",
    type: "textbox",
    dataType: "string",
  },
  direction_facing: {
    field_name: "direction_facing",
    label: "Direction Facing",
    type: "dropdown",
    dataType: "string",
    values: [
      { label: "West", value: "DIR_FACE_WEST" },
      { label: "North", value: "DIR_FACE_NORTH"},
      { label: "East", value: "DIR_FACE_EAST" },
      { label: "South", value: "DIR_FACE_SOUTH" },
    ],
  },
  price: {
    id: "price",
    field_name: "price",
    label: "Price",
    type: "textbox",
    dataType: "integer",
  },
  rent: {
    field_name: "rent",
    label: "Rent",
    type: "textbox",
    dataType: "integer",
  },
  available_from: {
    field_name: "available_from",
    label: "Available From",
    type: "date",
    dataType: "string", 
  },
  floor_number: {
    field_name: "floor_number",
    label: "Floor Number",
    type: "textbox",
    dataType: "integer",
  },
  total_floors: {
    field_name: "total_floors",
    label: "Total Floors",
    type: "textbox",
    dataType: "integer",
  },
  country: {
    field_name: "country",
    label: "Country",
    type: "dropdown",
    dataType: "string",
  },
  country: {
    field_name: "country",
    label: "Country",
    type: "dropdown",
    dataType: "string",
    values: function_to_call_get_countries?.map(country => ({
      label: country.name,
      value: country.country_code,
    })),
  },
  state: {
    field_name: "state",
    label: "State",
    type: "dropdown",
    dataType: "string",
    values: function_to_call_get_states?.map(state => ({
      label: state.name,
      value: state.state_code,
    })),
  },
  city: {
    field_name: "city",
    label: "City",
    type: "dropdown",
    dataType: "string",
    values: function_to_call_get_cities?.map(city => ({
      label: city.name,
      value: city.city_code,
    })),
  },
  line_1: {
    field_name: "line_1",
    label: "Street",
    type: "textbox",
    dataType: "string",
  },
  line_2: {
    field_name: "line_2",
    label: "Nearby Landmark",
    type: "textbox",
    dataType: "string",
  },
  area: {
    field_name: "area",
    label: "Area",
    type: "dropdown",
    dataType: "string",
    values: function_to_call_get_areas?.map(area => ({
      label: area.name,
      value: area.area_id,
    })),
  },
  zip: {
    field_name: "zip",
    label: "PIN Code",
    type: "textbox",
    dataType: "string",
  },
  latitude: {
    field_name: "latitude",
    label: "Latitude",
    type: "dropdown",
    dataType: "string",
  },
  longitude: {
    field_name: "longitude",
    label: "Longitude",
    type: "dropdown",
    dataType: "string",
  },
  furnish_level: {
    id: "furnish_level",
    field_name: "furnish_level",
    label: "Furnish Level",
    type: "dropdown",
    values: [
      { label: "Furnished", value: "FULLY_FURNISH" },
      { label: "Semi-furnished", value: "SEMI_FURNISH" },
      { label: "Not-furnished", value: "NO_FURNISH" },
    ],
  },
  number_of_bedrooms: {
    field_name: "number_of_bedrooms",
    label: "Number of Bedrooms",
    type: "textbox",
    dataType: "integer",
  },
  number_of_bathrooms: {
    field_name: "number_of_bathrooms",
    label: "Number of Bathrooms",
    type: "textbox",
    dataType: "integer",
  },
  property_age: {
    field_name: "property_age",
    label: "Age of Property",
    type: "textbox",
    dataType: "string",
  },
  email: {
    field_name: "email",
    label: "Email",
    type: "textbox",
    dataType: "string",
  },
  description: {
    id: 'description',
    field_name: 'description',
    label: 'Description',
    type: 'textarea',
    dataType: "string",
  },

  phone: {
    field_name: "phone",
    label: "Phone",
    type: "textbox",
    dataType: "integer",
  },
  car_parking: {
    field_name: "car_parking",
    label: "Car Parking",
    type: "dropdown",
    values: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  maintenance_charges: {
    field_name: "maintenance_charges",
    label: "Maintenance Charges",
    type: "textbox",
    dataType: "string",
  },
  pet_allowed: {
    field_name: "pet_allowed",
    label: "Pet Allowed",
    type: "dropdown",
    values: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  approving_agency: {
    field_name: "approving_agency",
    label: "Approving Agency",
    type: "dropdown",
    values: [
      { label: "DTCP", value: "APPR_AGY_DTCP" },
      { label: "LPA", value: "APPR_AGY_LPA"},
      { label: "CMDA", value: "APPR_AGY_CMDA"},
      { label: "Panchayat", value: "APPR_AGY_PANCHAYAT"}
    ],
  },
  suitable_for_business: {
    field_name: "suitable_for_business",
    label: "Suitable For Business",
    type: "textbox",
    dataType: "string",
  },
  persons_per_room: {
    field_name: "persons_per_room",
    label: "Persons Per Room",
    type: "dropdown",
    values: [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
    ],
  },
  food_available: {
    field_name: "food_available",
    label: "Food Provided",
    type: "dropdown",
    values: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  self_cooking: {
    field_name: "self_cooking",
    label: "Self Cooking",
    type: "dropdown",
    values: [
      { label: "Allowed", value: true },
      { label: "Not Allowed", value: false },
    ],
  },
  image: {
    id: "image",
    className: "mt-2",
    field_name: "image",
    label: "Upload Cover Image",
    type: "singleImage",
  },
  gallery_image: {
    id: "gallery_image",
    className: "mt-2",
    field_name: "gallery_image",
    label: "Upload Other Images",
    type: "multipleImages",
  },
  amenity: {
    id: "amenity",
    field_name: "amenity",
    label: "Amenity",
    type: "amenity",
    values: [
      { label: "Gym", value: "AMNT_FITNESS_CENTRE" },
      { label: "Swimming Pool", value: "AMNT_SWIMMING_POOL" },
      { label: "Club Houses", value: "AMNT_CLUB_HOUSES" },
      { label: "Playground", value: "AMNT_PLAYGROUND" },
      { label: "Business Center", value: "AMNT_BUSINESS_CENTER" },
      { label: "Common Area", value: "AMNT_COMMON_AREA" },
      { label: "Community Events", value: "AMNT_COMMUNITY_EVENTS" },
    ]
  },
  google_map: {
    id: "google_map",
    field_name: "google_map",
    label: "Location",
    type: "map",
    dataType: "string",
  }
};

