import React from "react";
import AreaModal from "../../Modal/AreaModal";
import OfferDataTable from "../../table/offerDataTable/OfferDataTable";

const OffersListings = () => {
    return (
        <div>
            <AreaModal />
            <div>
                <OfferDataTable />
            </div>
        </div>
    );
}

export default OffersListings;