import React from 'react'
import UserReviewDataTable from '../../table/userReviewDataTable/UserReviewDataTable'

const UserReviewListings = () => {
  return (
    <div>
      <UserReviewDataTable/>
    </div>
  )
}

export default UserReviewListings
