import React, { useState, useEffect } from "react";
import { callApi } from "../api/Api";
import { useUser } from "../../utils/utils";
import Message from "../errors/Message";

function StateSelector({ selectedState, onChange, errors}) {
  const [stateDetails, setStateDetails] = useState([]);
  const { user, setUser } = useUser();

  async function getState() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    try {
      const stateDataResponse = await callApi(
        `${baseUrl}/modules/states//get-states`,
        "POST",
        headers
      );
      if (stateDataResponse.success) {
        setStateDetails(stateDataResponse.records);
      }
    } catch (error) {
      console.error("There was an error fetching the state!", error);
    }
  }

  useEffect(() => {
    getState();
  }, []);

  return (
    <div>
      <fieldset className="form-group">
        <select
          name="state"
          className="form-select"
          onChange={onChange}
          value={selectedState}
        >
          <option value="" disabled selected>
            Select an state
          </option>
          {stateDetails?.map((state, i) => (
            <option key={i} value={state.state_code}>
              {state.name}
            </option>
          ))}
        </select>
        <Message type={"error"} errors={errors} fieldName={"state"} />
      </fieldset>
    </div>
  );
}

export default StateSelector;
