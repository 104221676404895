const convertDateToString = (date) => {
    if (typeof (date) != 'object') {
        date = new Date(date)
    }
    return (date?.getDate() + "-" + months[date?.getMonth()] + "-" + date?.getFullYear())
}

var months = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
}

export default { convertDateToString }
