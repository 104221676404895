import { useState } from "react";
import './AmenitySelector.css'

const AmenitySelector = ({ setAmenities, options, amenities, label }) => {
    console.log("am=>",amenities);
    
    const [defaultAmenities, setDefaultAmenities] = useState(options)


    const addorRemoveAmenities = (amenityValue) => {
        const exists = amenities.some(amenity => amenity == amenityValue)
        if (exists) {
            // If it exists, remove it
            setAmenities((prevAmenities) =>
                prevAmenities.filter((amenity) => amenity !== amenityValue)
            );
        } else {
            // If it doesn't exist, add it
            setAmenities((prevAmenities) => [...prevAmenities, amenityValue]);
        }
    }
    return (
        <>
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <label htmlFor="first-name-horizontal" className="form-label mb-3">
                        {label}
                    </label>
                    <ul className="amenities mb-3">
                        {defaultAmenities.map(amenity => (
                            <li
                                key={amenity.value}
                                className={`amenity_list ${amenities?.includes(amenity.value) ? 'highlight' : ''}`}
                                onClick={() => addorRemoveAmenities(amenity.value)}
                            >
                                {amenity.label}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default AmenitySelector;