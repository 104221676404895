import React, { useEffect, useRef, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader"; // Load the Google Maps API
import { defaultLocation } from "../../utils/locationUtils";
import { useUser } from "../../utils/utils";

const MapComponent = ({
  setLatitude,
  setLongitude,
  showLatitude,
  showLongitude,
  setShowLatitude,
  setShowLongitude,
}) => {
  const mapRef = useRef(null); // Reference to the map container
  const mapInstance = useRef(null); // Reference to the Google Maps instance
  const infoWindowRef = useRef(null); // Reference to the InfoWindow
  const markerRef = useRef(null); // Reference to the single marker
  const { user } = useUser();
  const [initialLocation, setInitalLocation] = useState(defaultLocation);

  // Function to check and update the location based on the area ID
  const checkLocation = (area_id) => {
    const findLocation = initialLocation.find(
      (area) => area.area_id == area_id
    );
    if (findLocation) {
      // Convert latitude and longitude to numbers before setting the state
      setShowLatitude(parseFloat(findLocation.latitude));
      setShowLongitude(parseFloat(findLocation.longitude));
    }
  };

  // Effect to handle change in user's area_id
  useEffect(() => {
    if (user?.area_id) {
      checkLocation(user.area_id);
    }
  }, [user?.area_id]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyBgYCpvmU3PT8s5HG9UMGy0S2vBkZtlbWk", // Your Google Maps API key
      version: "weekly",
      libraries: ["places", "geometry", "drawing"], // Load additional libraries if needed
    });

    loader.load().then(() => {
      const { Map, InfoWindow, Marker } = window.google.maps;

      // Initialize the map
      mapInstance.current = new Map(mapRef.current, {
        center: { lat: showLatitude, lng: showLongitude },
        zoom: 14,
        mapId: "4504f8b37365c3d0",
      });

      // Ensure showLatitude and showLongitude are valid numbers
      const lat = parseFloat(showLatitude);
      const lng = parseFloat(showLongitude);
      if (isNaN(lat) || isNaN(lng)) {
        console.error("Invalid latitude or longitude for map center");
        return; // Exit if lat or lng is not valid
      }

      // Initialize the InfoWindow
      infoWindowRef.current = new InfoWindow();

      // Add a default marker at the initial location
      markerRef.current = new Marker({
        position: { lat, lng },
        map: mapInstance.current,
        draggable: true, // Make the marker draggable
        title: "This marker is draggable.",
      });

      // Add an event listener for the marker's drag end
      markerRef.current.addListener("dragend", (event) => {
        const position = event.latLng;
        setLatitude(position.lat());
        setLongitude(position.lng());
        infoWindowRef.current.close();
        infoWindowRef.current.setContent(
          `Pin dropped at: ${position.lat()}, ${position.lng()}`
        );
        infoWindowRef.current.open(
          markerRef.current.getMap(),
          markerRef.current
        );
      });

      // Add event listener for map click to move the marker to the clicked location
      mapInstance.current.addListener("click", (event) => {
        addMarker(event.latLng);
      });

      // Function to move the existing marker to the clicked location
      const addMarker = (location) => {
        markerRef.current.setPosition(location);
        setLatitude(location.lat());
        setLongitude(location.lng());

        infoWindowRef.current.close();
        infoWindowRef.current.setContent(
          `Pin dropped at: ${location.lat()}, ${location.lng()}`
        );
        infoWindowRef.current.open(mapInstance.current, markerRef.current);
      };
    });

    // Clean up function to remove the map instance on component unmount
    return () => {
      if (mapInstance.current) {
        mapInstance.current.unbindAll();
      }
    };
  }, [showLatitude, showLongitude]); // Add showLatitude and showLongitude as dependencies

  return (
    <div>
      <div id="map" ref={mapRef} style={{ height: "60vh" }}></div>
    </div>
  );
};

export default MapComponent;
