import React from 'react'
import CategoryDataTable from '../../table/categoryDataTable/CategoryDataTable'

const CategoryListings = () => {
    return (
        <div>
            <CategoryDataTable />
        </div>
    )
}

export default CategoryListings
