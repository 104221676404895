import React from 'react'
import AreaModal from '../../Modal/AreaModal';
import EventDataTable from '../../table/eventDataTable/EventDataTable';

const EventListings = () => {
    return (
        <>
            <div>
                <AreaModal />
                <div>
                    <EventDataTable />
                </div>
            </div>
        </>
    )
}

export default EventListings
