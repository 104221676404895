import React, { useState, useEffect } from "react";
import { callApi } from "../api/Api";
import { useUser } from "../../utils/utils";
import Message from "../errors/Message";

function AreaSelector({ selectedArea, onChange, errors}) {
  const [areaDetails, setAreaDetails] = useState([]);
  const { user } = useUser();

  async function getArea() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

   
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    try {
      const areaData = await callApi(
        `${baseUrl}/modules/areas/get-areas`,
        "POST",
        headers
      );
      setAreaDetails(areaData.records);
    } catch (error) {
      console.error("There was an error fetching the area!", error);
    }
  }

  useEffect(() => {
    getArea();
  }, []);

  return (
    <div>
      <fieldset  className="form-group">
        <select
       
          name="area"
          className="form-select"
          onChange={onChange}
          value={selectedArea}
        >
          <option value="" disabled selected>
            Select an area
          </option>
          {areaDetails?.map((area, i) => (
            <option key={i} value={area.area_id}>
              {area.name}
            </option>
          ))}
        </select>
        <div>
          <Message type={"error"} errors={errors} fieldName={"area"} />
        </div>
      </fieldset>
    </div>
  );
}

export default AreaSelector;
