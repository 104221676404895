export const defaultLocation = [
    {
        "area_id": 2,
        "name": "Peelamedu",
        "city_code": "CBE",
        "latitude":"11.0346",
        "longitude":"77.0156",
    },{
        "area_id": 1,
        "name": "Saravanampatti",
        "city_code": "CBE",
        "latitude":"11.0797",
        "longitude":"76.9997",
    }
]