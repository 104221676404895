import React from "react";
import ShopDataTable from "../../table/shopDataTable/ShopDataTable";
import AreaModal from "../../Modal/AreaModal";

function ShopListings() {
  return (
    <div>
      <AreaModal />
      <div>
        <ShopDataTable />
      </div>
    </div>
  );
}

export default ShopListings;
