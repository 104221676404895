export const menuItemsData = [
  { title: "Dashboard", url: "/" },
  {
    title: "Shops",
    subMenu: [
      { title: "All", url: "/shops" },
      { title: "Add New", url: "/shop-new" },
    ],
  },
  {
    title: "Services",
    subMenu: [
      { title: "All", url: "/services" },
      { title: "Add New", url: "/service-new" },
    ],
  },

  {
    title: "Offers",
    subMenu: [
      { title: "All", url: "/offers" },
      { title: "Add New", url: "/offer-new" },
    ],
  },
  {
    title: "Events",
    subMenu: [
      { title: "All", url: "/events" },
      { title: "Add New", url: "/event-new" },
    ],
  },
  {
    title: "Reviews",url: "/reviews"
  },
  {
    title: "Property",
    subMenu: [
      { title: "All", url: "/properties" },
      { title: "Add New", url: "/property-new" },
    ],
  },
  {
    title: "Category",
    subMenu: [
      { title: "All", url: "/categories" },
      { title: "Add New", url: "/category-new" },
    ],
  },
  {
    title: "Banner",
    subMenu: [
      { title: "All", url: "/banner-list" },
      { title: "Add New", url: "/banner-new" }
    ],
  },

  {
    title: "Users",
    subMenu: [
      { title: "All", url: "/users" },
      { title: "Add New", url: "/user-new" },
    ],
  },


  // {
  //   title: "Settings",
  //   url: "/settings",
  //   subMenu: [
  //     { title: "Info", url: "/info" },

  //   ],
  // },
];
