import { callApi } from "../components/api/Api";


export async function GetCountries () {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    try {
        const countryData = await callApi(
            `${baseUrl}/modules/countries/get-countries`,
            "POST",
            headers
          );
          return countryData?.records;
        } catch (error) {
          console.error("There was an error fetching the country!", error);
    }
}