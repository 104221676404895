import { React, useState, useEffect } from "react";
import { useUser } from "../../../utils/utils";
import Swal from "sweetalert2";
import { callApi } from "../../api/Api";
import MultiImageSelector from "../../multiImageSelector/MultiImageSelector";
import { useNavigate } from "react-router-dom";

function EditBanner() {
  const [selectedImages, setSelectedImages] = useState([]);
  const { user, setUser } = useUser();
  const navigate = useNavigate();

  async function BannerImagesList() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      area_id: user.area_id,
    };

    try {
      const bannerImagesList = await callApi(
        `${baseUrl}/modules/areas/get-slider-images`,
        "POST",
        headers,
        payload
      );
      setSelectedImages(bannerImagesList.record.slider_images);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }

  useEffect(() => {
    BannerImagesList();
  }, []);

  // Toast for BannerImages Edit
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

  const handleBannerImageSubmit = async (e) => {
    e.preventDefault();
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    var payload = {
      area_id: user.area_id,
      slider_images: selectedImages,
    };

    console.log("payload =>", payload);

    try {
      const BannerImageResponse = await callApi(
        `${baseUrl}/modules/areas/set-slider-images`,
        "POST",
        headers,
        payload
      );

      console.log("BannerImageResponse", BannerImageResponse);

      if (BannerImageResponse.success) {
        console.log("success", BannerImageResponse);
        Toast.fire({
          icon: "success",
          title: "Banner Image Edited successfully",
        });

        navigate("/banner-list");   
      } else {
        Toast.fire({
          icon: "error",
          title: "Failed to edit the Banner Images",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.fire({
        icon: "error",
        title: "An error occurred while edit the Banner Images",
      });
    }

    console.log("BannerImages =>", selectedImages);
  };

  return (
    <div>
      <section id="basic-horizontal-layouts">
        <div className="row match-height">
          <div className="col-md-10 col-lg-10">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Banner Images</h4>
              </div>
              <div className="card-content">
                <div className="card-body">
                  <form className="form form-horizontal">
                    <div className="form-body">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="first-name-horizontal">
                            Upload Banner Images
                          </label>
                        </div>
                        <div className="col-md-8 col-lg-8 col-xs-8 form-group">
                          <fieldset>
                            <MultiImageSelector
                              selectedImages={selectedImages}
                              setSelectedImages={setSelectedImages}
                            />
                          </fieldset>
                        </div>
                        <div className="col-sm-12 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary me-1 mb-1"
                            onClick={handleBannerImageSubmit}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EditBanner;
