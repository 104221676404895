import React, { useEffect, useState, useContext } from "react";
import { callApi } from "../../api/Api";
import "./AddTags.css";
import { UserContext } from "../../../contexts/UserContext";
import Message from "../../errors/Message";

//received a props from AddShop and EditShop
function AddTags({ addedTags, setAddedTags, removedTags, setRemovedTags ,errors }) {
  const [tagsAttachedToShop, setTagsAttachedToShop] = useState([]);
  const [inputTag, setInputTag] = useState("");
  const [filteredTags, setFilteredTags] = useState([]);
  const { user, setUser } = useContext(UserContext);
  const getTags = async () => {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    // const tag_url = `${baseUrl}/modules/tags/get-all-tags`

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      filters: {
        conditions: [
          {
            field: "name",
            operator: "like",
            value: inputTag,
            dataType: "string",
          },
        ],
      },
    };

    try {
      const getTagsResponse = await callApi(
        `${baseUrl}/modules/tags/get-all-tags`,
        "POST",
        headers,
        payload
      );
      const tags = getTagsResponse.records;
      setFilteredTags(tags);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    if (inputTag.trim()) {
      getTags();
    }
  }, [inputTag]);

  const processAndSetToAddedTags = (tag) => {
    // Check if the tag already exists in addedTags
    const exists = addedTags.some((addedTag) => addedTag.name === tag.name);
    // If the tag doesn't exist, add it to addedTags
    if (!exists) {
      setAddedTags((prevTags) => [...prevTags, tag]);
    }
  };

  const processAndSetToRemovedTags = (tag) => {
    // Check if the tag already exists in removedTags
    const exists = removedTags.some(
      (removedTag) => removedTag.name === tag.name
      // If the tag doesn't exist, add it to removedTags
    );
    if (!exists) {
      setRemovedTags((prevTags) => [...prevTags, tag]);
      setAddedTags((prevTags) => [...prevTags, tag]);
    }
  };

  const onkeydown = (e) => {
    const { key } = e;

    const trimmedInput = inputTag.trim();
    // Check if the Enter key is pressed and the input is not empty
    if (key === "Enter" && trimmedInput.length > 0) {
      // Create a new tag object
      const newTagToBeAdded = { tag_id: 0, name: trimmedInput };
      // Update the tags attached to the shop
      setTagsAttachedToShop((prevTags) => [...prevTags, newTagToBeAdded]);
      // Clear the input field
      setInputTag("");
      // Process and set the new tag to addedTags
      processAndSetToAddedTags(newTagToBeAdded);
      e.preventDefault();
    }
  };

  const deleteTag = (tag) => {
    // Find the index of the tag to be removed
    const indexOfTagToBeRemoved = tagsAttachedToShop.findIndex(
      (tagAttachedToShop) => tagAttachedToShop.name === tag.name
    );
    // If the tag is found, remove it
    if (indexOfTagToBeRemoved !== -1) {
      // Create a copy of the tagsAttachedToShop array
      const updatedTagsAttachedToShop = [...tagsAttachedToShop];
      // Remove the tag at the found index
      updatedTagsAttachedToShop.splice(indexOfTagToBeRemoved, 1);
      // Update the tagsAttachedToShop state with the modified array
      setTagsAttachedToShop(updatedTagsAttachedToShop);
      // Process and set the removed tag to removedTags state
      processAndSetToRemovedTags(tag);
    }
  };

  const addTag = (tag) => {
    // Check if the tag is already present in tagsAttachedToShop
    const tagAlreadyPresent = tagsAttachedToShop.some(
      (tagsAttachedToShop) => tagsAttachedToShop.name === tag.name
    );
    // If the tag is not already present, add it
    if (!tagAlreadyPresent) {
      // Update the tagsAttachedToShop state by adding the new tag
      setTagsAttachedToShop((prevTags) => [...prevTags, tag]);
      // Ensure this tag is added to addedTags
      processAndSetToAddedTags(tag);
      setInputTag("");
    }
  };

  return (
    <div>
      <div className="input-tag">
        <div className="input-wrapper">
          <input
            type="text"
            id="first-name-horizontal"
            className="form-control"
            name="tags"
            value={inputTag}
            onChange={(e) => setInputTag(e.target.value)}
            onKeyDown={onkeydown}
            placeholder="Add tags relevant to your offer"
            required
          />
          <Message type={'error'}  errors={errors} fieldName={'tag_id'}/>
          <ul className="tagUl">
            {inputTag.length > 0 &&
              filteredTags.map((tag) => (
                <li
                  key={tag.tag_id}
                  className="dropdownTag"
                  onClick={() => addTag(tag)}
                >
                  {tag.name}
                </li>
              ))}
          </ul>
        </div>
        <ul className="input-tag__tags">
          {tagsAttachedToShop.map((tagAttachedToShop, index) => (
            <li className="" key={index}>
              {tagAttachedToShop.name}
              <button
                type="button"
                onClick={() => deleteTag(tagAttachedToShop)}
              >
                <i className="bi bi-x"></i>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AddTags;
