import React from "react";
import { callApi } from "../components/api/Api";
export async function GetCities() {
  var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  try {
    const cityData = await callApi(
      `${baseUrl}/modules/cities/get-cities`,
      "POST",
      headers
    );
    return cityData?.records;
  } catch (error) {
    console.error("There was an error fetching the city!", error);
  }
}
