import React, { useState, useEffect } from "react";
import dateUtils from "../../../utils/dateUtils";
import { Link } from "react-router-dom";
import { callApi } from "../../api/Api";
import Swal from "sweetalert2";
import "./ShopDataTable.css";
import { useCount, useUser } from "../../../utils/utils";
import Pagination from "../../pagination/Pagination";
import loading_gif from "../../../assets/gif/loading_gif.gif";
import shop_image from "../../../assets/jpg/shop_image.jpg"

const ShopDataTable = () => {
  const [shopListData, setShopListData] = useState([]);
  const { pendingCount, setPendingCount } = useCount()
  const [status, setStatus] = useState("STS_LIVE");
  const { user, setUser } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const columnData = [
    "S.No",
    "Name",
    "Landmark",
    "Shops",
    "Created Date",
  ];

  useEffect(() => {
    ShopList();
  }, [currentPage, status]);

  async function ShopList() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      area_id: user.area_id,
      current_page: currentPage,
      filters: {
        conditions: [
          {
            field: "ed.status",
            operator: "like",
            value: status,
            dataType: "string",
          },
        ],
      },
    };
    const pending_payload = {
      area_id: user.area_id,
      current_page: currentPage,
      filters: {
        conditions: [
          {
            field: "ed.status",
            operator: "like",
            value: "STS_PENDING",
            dataType: "string",
          },
        ],
      },
    };
    setIsLoading(true);
    try {
      const shopListData = await callApi(
        `${baseUrl}/modules/shops/get-Shops`,
        "POST",
        headers,
        payload
      );

      const pendingshopListData = await callApi(
        `${baseUrl}/modules/shops/get-Shops`,
        "POST",
        headers,
        pending_payload
      );

      setShopListData(shopListData.records);
      setPendingCount({ ...pendingCount, Shops: pendingshopListData?.total_rows })
      const pageTotal = shopListData.total_pages;
      setTotalPage(Array.from({ length: pageTotal }, (_, i) => i + 1));
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    } finally {
      setIsLoading(false);
    }
  }


  // SweetAlert Toast For handleShopDelete
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  //soft delete the particular shop
  const handleShopDelete = async (shop_id) => {
    const confirmation = await Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmation.isConfirmed) {
      var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
      const headers = {
        "Content-Type": "application/json",
        token: user?.token,
      };
      try {
        const ShopDeleteResponse = await callApi(
          `${baseUrl}/modules/shops/delete/${shop_id}`,
          "POST",
          headers
        );
        if (ShopDeleteResponse.success) {
          console.log("success", ShopDeleteResponse);
          Toast.fire({
            title: "Deleted",
            icon: "success",
          });
          ShopList();
        } else {
          console.log("failed", ShopDeleteResponse);
          Toast.fire({
            title: "Failed to delete shop",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        Toast.fire({
          title: "An error occurred while delete the shop",
          icon: "error",
        });
      }
    }
  };

  return (
    <>
      <div className="row d-flex justify-content-between mb-3">
        <div className="col-md-6 col-6">
          <h3>Shops</h3>
        </div>
      </div>

      <div class="page-heading">
        <div class="page-title">
          <section className="section">
            <div className="card">
              <div className="card-body ">
                <div className="dataTable-top mb-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        onClick={() => setStatus("STS_LIVE")}
                      >
                        Live
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={() => setStatus("STS_PENDING")}
                      >
                        Pending {`${pendingCount?.Shops>0 ? '(' + pendingCount?.Shops + ')' : ''}`}
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        href="#contact"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        onClick={() => setStatus("STS_REJECTED")}
                      >
                        Rejected
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        href="#contact"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        onClick={() => setStatus("STS_EXPIRED")}
                      >
                        Expired
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="dataTable-container">
                  <table className="table table-striped" id="table1">
                    <thead>
                      <tr>
                        {columnData.map((col, i) => (
                          <th className="shop_table" key={i}>
                            {col}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {isLoading && (
                        <img
                          src={loading_gif}
                          className="loading_gif"
                          alt="Loading..."
                        />
                      )}

                      {shopListData && shopListData.length > 0 ? (
                        shopListData.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="shop_table">{data.name}</td>
                            <td>{data.line_1}</td>
                            <td>
                              <div>
                                <img
                                  src={data.featured_image_path ? data.featured_image_path : shop_image}
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                            </td>
                            <td>
                              {dateUtils.convertDateToString(
                                data.created_date_time
                              )}
                            </td>
                            <td>
                              <div className="col-sm-12 d-flex justify-content-end">
                                <Link
                                  to={{
                                    pathname: `/shop-edit/${data.shop_id}`,
                                    state: data,
                                  }}
                                >
                                  <button className="btn btn-success me-1 mb-1">
                                    <i className="bi bi-pencil-square"></i>
                                  </button>
                                </Link>
                                <button
                                  className="btn btn-danger me-1 mb-1"
                                  onClick={() => handleShopDelete(data.shop_id)}
                                >
                                  <i className="bi bi-trash3"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={columnData.length}>No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="dataTable-bottom d-flex justify-content-end mt-2">
                  <Pagination
                    totalPage={totalPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ShopDataTable;
