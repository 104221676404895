import React from 'react'
import { errorMessages } from './errorMessages'

const Message = ({ type, text, errors, fieldName }) => {
    const error = errors && errors.find(item => item.field_name === fieldName)
    const errorMessage = error ? errorMessages[error.message] : null

    return (
        <span className={`message ${type}`}>
            {errorMessage || text}
        </span>
    )
}

export default Message
