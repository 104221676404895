import React, { useState, useEffect } from "react";
import { useUser } from "../../../utils/utils";
import { callApi } from "../../api/Api";
import { Link } from "react-router-dom";
import loading_gif from "../../../assets/gif/loading_gif.gif";
import profile_img from "../../../assets/png/profile_img.png";
import Swal from "sweetalert2";
import Pagination from "../../pagination/Pagination";

function UserList() {
  const [userList, setUserList] = useState([]);
  const { user, setUser } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();

  const columnData = [
    "S.No",
    "Profile",
    "Firstname",
    "Lastname",
    "Email",
    "Phone",
  ];

  useEffect(() => {
    FetchUserList();
  }, [currentPage]);

  async function FetchUserList() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    var payload = {
      current_page: currentPage,
    }

    setIsLoading(true);
    try {
      const userListData = await callApi(
        `${baseUrl}/modules/users/get-users`,
        "POST",
        headers,
        payload
      );

      setUserList(userListData.records);
      const pageTotal = userListData.total_pages;
      setTotalPage(Array.from({ length: pageTotal }, (_, i) => i + 1));
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    } finally {
      setIsLoading(false);
    }
  }

    // SweetAlert Toast For handleUserDelete
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });

    //delete the particular user
    const handleUserDelete = async (user_id) => {
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
  
      if (confirmation.isConfirmed) {
        var baseUrl = process.env.REACT_APP_DEV_BASE_URL;
        const headers = {
          "Content-Type": "application/json",
          token: user?.token,
        };
        try {
          const UserDeleteResponse = await callApi(
            `${baseUrl}/modules/users/soft-delete/${user_id}`,
            "POST",
            headers
          );
          if (UserDeleteResponse.success) {
            console.log("success", UserDeleteResponse);
            Toast.fire({
              title: "Deleted",
              icon: "success",
            });
            
            FetchUserList()
          } else {
            console.log("failed", UserDeleteResponse);
            Toast.fire({
              title: "Failed to delete user",
              icon: "error",
            });
          }
        } catch (error) {
          console.error("Error:", error);
          Toast.fire({
            title: "An error occurred while delete the user",
            icon: "error",
          });
        }
      }
    };

  return (
    <>
      <div className="row d-flex justify-content-between mb-3">
        <div className="col-md-6 col-6">
          <h3>Users</h3>
        </div>
      </div>

      <div class="page-heading">
        <div class="page-title">
          <section className="section">
            <div className="card">
              <div className="card-body ">
                <div className="dataTable-container">
                  <table className="table table-striped" id="table1">
                    <thead>
                      <tr>
                        {columnData.map((col, i) => (
                          <th className="table_head" key={i}>
                            {col}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {isLoading && (
                        <img
                          src={loading_gif}
                          className="loading_gif"
                          alt="Loading..."
                        />
                      )}

                      {userList && userList.length > 0 ? (
                        userList.map((user, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <div>
                                <img
                                   src={user.path ? user.path : profile_img}
                                  style={{ width: "50px", height: "50px",objectFit:"cover" }}
                                />
                              </div>
                            </td>
                            <td>{user.first_name}</td>
                            <td>{user.last_name}</td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td> 

                            <td>
                              <div className="col-sm-12 d-flex justify-content-end">
                                <Link
                                  to={{
                                    pathname: `/user-edit/${user.user_id}`,
                                    state: user,
                                  }}
                                >
                                  <button className="btn btn-success me-1 mb-1">
                                    <i className="bi bi-pencil-square"></i>
                                  </button>
                                </Link>
                                <button
                                  className="btn btn-danger me-1 mb-1"
                                  onClick={() => handleUserDelete(user.user_id)}
                                >
                                  <i className="bi bi-trash3"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={columnData.length}>No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="dataTable-bottom d-flex justify-content-end mt-2">
                  <Pagination
                    totalPage={totalPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default UserList;
