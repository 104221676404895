import React, { useState } from "react";
import { WorkingTime } from "./WorkingTime";

function WorkingHours({ workingHours, setWorkingHours }) {
  const updateWorkingHours = (index, updatedHours) => {
    setWorkingHours((prevState) => {
      const newState = [...prevState];
      newState[index] = { ...newState[index], ...updatedHours };
      return newState;
    });
  };

  return (
    <>
      {workingHours.map((hours, index) => (
        <WorkingTime
          key={index}
          hours={hours}
          updateHours={(updateHours) => updateWorkingHours(index, updateHours)}
        />
      ))}
    </>
  );
}

export default WorkingHours;
