import React, { useState, useRef, useEffect } from "react";
import { useUser } from "../../utils/utils";
import { callApi } from "../api/Api";
import Message from "../errors/Message";
import Swal from "sweetalert2";

const MobileNumberVerified = ({
  setPhoneVerifyModal,
  phoneVerifyModal,
  setPhoneNumber,
  setUserInfo,
  newPhoneNumber,
  isEditMode,
  phoneNumber,
  setFormValues,
  setNewPhoneNumber,
}) => {
  const { user, setUser } = useUser();
  const [otpVerifyModal, setOtpVerifyModal] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);
  const [errors, setErrors] = useState([]);

  // console.log("check mobile number: " + phoneNumber)

  //Otp functions start

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChangeOtp = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);
    if (value && index < 5 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1].focus();
    }
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  // end

  // SweetAlert Toast For handleShopDelete
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

  const closeModal = () => {
    setPhoneVerifyModal(false);
  };

  const handleMobileNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setNewPhoneNumber(e.target.value);
  };

  // Function to handle mobile number submission
  const handleMobileNumberSubmit = async (e) => {
    // Regular expression to match exactly 10 digits
    const regex = /^\d{10}$/;

    e.preventDefault();

    const phoneNumberSubmit = newPhoneNumber || phoneNumber;

    // Check if phone number is present
    if (!phoneNumberSubmit) {
      Toast.fire({
        icon: "error",
        title: "Kindly enter the mobile number",
      });
      return;
    }

    // Check if phone number is valid (exactly 10 digits)
    if (!regex.test(phoneNumberSubmit)) {
      Toast.fire({
        icon: "error",
        title: "Mobile number must be exactly 10 digits.",
      });
      return;
    }

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    var payload = {
      phone: newPhoneNumber || phoneNumber,
    };

    try {
      const VerifyMobileNumberResponse = await callApi(
        `${baseUrl}/modules/users/send-otp-to-mobile`,
        "POST",
        headers,
        payload
      );
      if (VerifyMobileNumberResponse.success) {
        console.log("success", VerifyMobileNumberResponse);

        setPhoneVerifyModal(false);
        setOtpVerifyModal(true);
      } else {
        console.error(
          "Error: Response indicates failure",
          VerifyMobileNumberResponse
        );
        setErrors(VerifyMobileNumberResponse.errors);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Handle close Otp modal

  const handleCloseOtp = () => {
    setOtpVerifyModal(false);
  };

  // Function to handle OTP submission

  const handleOtpCodeSubmit = async (e) => {
    e.preventDefault();

    const joinedOtp = otp.join("");

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    var payload = {
      otp_code: joinedOtp,
      phone: newPhoneNumber || phoneNumber,
    };

    try {
      const VerifyOtpResponse = await callApi(
        `${baseUrl}/modules/users/verify-otp-to-mobile`,
        "POST",
        headers,
        payload
      );

      if (VerifyOtpResponse.success) {
        console.log("success", VerifyOtpResponse);

        if (isEditMode) {
          setUserInfo((prev) => ({
            ...prev,
            phone_verified: true,
            phone: newPhoneNumber || phoneNumber,
          }));
          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            phone: newPhoneNumber || phoneNumber,
          }));
        } else {
          setUserInfo((prev) => ({
            ...prev,
            phone_verified: true,
            phone: newPhoneNumber,
          }));
        }
        Toast.fire({
          icon: "success",
          title: "Mobile number verified successfully.",
        });
        setOtpVerifyModal(false);
      } else {
        console.error("Error: Response indicates failure", VerifyOtpResponse);
        setErrors(VerifyOtpResponse.errors);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {/* Modal for check phone number */}
      {phoneVerifyModal && (
        <div
          className={`modal fade show`}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">
                  Enter Your Mobile Number
                </h5>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="first-name-horizontal"
                        className="form-label"
                      >
                        Mobile Number
                      </label>

                      {isEditMode ? (
                        <input
                          type="number"
                          name="mobile_number"
                          className="form-control"
                          value={newPhoneNumber || phoneNumber}
                          onChange={handleMobileNumberChange}
                        />
                      ) : (
                        <input
                          type="number"
                          name="mobile_number"
                          className="form-control"
                          value={
                            phoneNumber !== undefined
                              ? phoneNumber
                              : newPhoneNumber
                          }
                          onChange={handleMobileNumberChange}
                        />
                      )}

                      <div>
                        <Message
                          type={"error"}
                          errors={errors}
                          fieldName={"phone"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={closeModal}>
                  Close
                </button>

                <button
                  className="btn btn-primary"
                  onClick={handleMobileNumberSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal for check phone number */}
      {otpVerifyModal && (
        <div
          className={`modal fade show`}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">
                  Enter Your Otp Code
                </h5>
              </div>
              <div className="modal-body">
                <div className="form-group position-relative  otp-container">
                  {otp.map((value, index) => (
                    <input
                      key={index}
                      type="text"
                      ref={(input) => (inputRefs.current[index] = input)}
                      value={value}
                      onChange={(e) => handleChangeOtp(index, e)}
                      onClick={() => handleClick(index)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      className="form-control form-control-xl otp-input"
                    />
                  ))}
                </div>
                <div className="mb-2">
                  <Message
                    type={"error"}
                    errors={errors}
                    fieldName={"otp_code"}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleCloseOtp}>
                  close
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleOtpCodeSubmit}
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileNumberVerified;
