import React, { useState, useEffect } from "react";
import { callApi } from "../api/Api";
import { useUser } from "../../utils/utils";

function ShopCategorySelector({ selectedCategory, onChange }) {
  const [shopCategory, setShopCategory] = useState([]);
  const { user, setUser } = useUser();

  async function getShopCategory() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    var payload = {
      entity_type: "ENT_SHOP",
    };
    try {
      const shopCategoryData = await callApi(
        `${baseUrl}/modules/categories/by-entity`,
        "POST",
        headers,
        payload
      );
      setShopCategory(shopCategoryData.records);
    } catch (error) {
      console.error("There was an error fetching the categories!", error);
    }
  }

  useEffect(() => {
    getShopCategory();
  }, []);

  return (
    <div>
      <fieldset className="form-group">
        <select
          name="category_id"
          className="form-select"
          onChange={onChange}
          value={selectedCategory}
        >
          <option value="" disabled selected>
            Select an shop category
          </option>
          {shopCategory?.map((shoptype, i) => (
            <option key={shoptype.category_id} value={shoptype.category_id}>
              {shoptype.name}
            </option>
          ))}
        </select>
      </fieldset>
    </div>
  );
}

export default ShopCategorySelector;
