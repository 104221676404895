import { useContext } from "react";
import { UserContext, PendingCountContext } from "../contexts/UserContext";

export const useUser = () => {
  var { user, setUser } = useContext(UserContext);

  if (!user) {
    user =
      localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  }
  return { user, setUser };

};

export const useCount = () => {
  var { pendingCount, setPendingCount } = useContext(PendingCountContext)
  return { pendingCount, setPendingCount };
}



















// Property Utils --------------------------------

const propertyTypeMapping = {
  PROPERTY_TYPE_LAND: "Land",
  PROPERTY_TYPE_VILLA: "Villa",
  PROPERTY_TYPE_APARTMENT: "Apartment",
  PROPERTY_TYPE_HOUSE: "Individual House",
  PROPERTY_TYPE_SHOP: "Shop",
  PROPERTY_TYPE_PG: "PG",
  PROPERTY_TYPE_ROOM_SHARE: "Room Sharing",
};
export const getPropertyTypeDisplayName = (propertyType) => {
  return propertyTypeMapping[propertyType] || "Unknown Property Type";
};
const propertyTypeLabel = {
  PROPERTY_TYPE_LAND: "Land",
  PROPERTY_TYPE_VILLA: "Villa",
  PROPERTY_TYPE_APARTMENT: "Apartment",
  PROPERTY_TYPE_HOUSE: "Individual House",
  PROPERTY_TYPE_SHOP: "Shop",
  Pg: "PG",
  Room_sharing: "Room Sharing",
};
export const propertyTypeLabelToshow = (propertyType) => {
  return propertyTypeLabel[propertyType] || "Unknown Property Type";
};

const propertyTypeForSale = {
  PROPERTY_TYPE_LAND: "For Sale",
  PROPERTY_TYPE_VILLA: "For Sale",
  PROPERTY_TYPE_APARTMENT: "For Sale",
  PROPERTY_TYPE_HOUSE: "For Rent",
  PROPERTY_TYPE_SHOP: "For Sale",
  Pg: "PG",
  PROPERTY_TYPE_ROOM_SHARING: "Room Sharing",
};

export const getProperty = (property) => {
  return propertyTypeForSale[property] || "For Boys/Girls";
};

const postedByType = {
  POST_BY_BROKER: "Posted by broker",
  POST_BY_OWNER: "Posted by owner",
};

export const getPostedBy = (postedby) => {
  return postedByType[postedby];
};

const categoryName = {
  Pg: "PROPERTY_TYPE_PG",
  Room_sharing: "PROPERTY_TYPE_ROOM_SHARE",
};

export const changePropertyType = (type) => {
  return categoryName[type] || type;
};
const getcategoryName = {
  PROPERTY_TYPE_PG: "Pg",
  PROPERTY_TYPE_ROOM_SHARE: "Room_sharing",
};

export const tochangePropertyType = (type) => {
  return getcategoryName[type] || type;
};
