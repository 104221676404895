const findObjectPositionInArray = (objectArray, objectToRemove, keyField) => {
    var return_value = -1
    objectArray.map((currentObject, index)=>{
      if(currentObject[keyField] == objectToRemove[keyField]){
        return_value = index
      }
    })
    return return_value
  }
  
const removeObjectFromArray = (objectArray, objectToRemove, keyField) => {
    var objectPosition = findObjectPositionInArray(objectArray, objectToRemove, keyField)
    var removedObject = objectArray.splice(objectPosition, 1)
    return JSON.parse(JSON.stringify(objectArray))
}

export default {
    findObjectPositionInArray,
    removeObjectFromArray
}

