import { callApi } from "../components/api/Api";

export async function GetAreas () {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    try {
        const areaData = await callApi(
            `${baseUrl}/modules/areas/get-areas`,
            "POST",
            headers
          );
          return areaData?.records;
        } catch (error) {
          console.error("There was an error fetching the area!", error);
    }
}